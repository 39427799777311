/* eslint-env browser */
import 'AWS-UI-Components-React/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import store from './store';
import routes from './routes';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      {routes}
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);
