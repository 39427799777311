/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import _ from 'lodash';

export const _PENDING = '_PENDING';
export const _REJECTED = '_REJECTED';
export const _FULFILLED = '_FULFILLED';

// eslint-disable-next-line no-unused-vars
export const promiseActionBaseType = store => next => (action) => {
  const payload = action.payload;
  if (payload && (payload instanceof Promise || payload.promise instanceof Promise)) {
    if (!action.meta) action.meta = {};
    action.meta.baseActionType = action.type;
  }
  return next(action);
};

export const isWrongQueryId = (queryId, action, actionTypes) => {
  const actionQueryId = _.get(action, 'meta.queryId');
  if (actionQueryId && actionQueryId !== queryId) {
    if (!Array.isArray(actionTypes)) return true;
    if (actionTypes.indexOf(action.meta.baseActionType) > -1) return true;
  }
  return false;
};
