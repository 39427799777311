import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Well, Alert } from 'react-bootstrap';
import { previewBlurbSelector } from '../../redux/newJob/previewSender/email/selectors';

export class BlurbPreview extends Component {
  render() {
    return (
      <div>
        <h4>Blurb Preview</h4>
        <Well style={{ whiteSpace: 'pre-wrap' }}>
          {this.props.blurb.instructions && <Alert bsStyle="info">
            <strong>Special Instructions</strong>
            <br />
            {this.props.blurb.instructions}
          </Alert>}
          {this.props.blurb.content}
        </Well>
      </div>
    );
  }
}

BlurbPreview.defaultProps = {
  blurb: '',
};

function mapStateToProps(state) {
  return {
    blurb: previewBlurbSelector(state),
  };
}

export default connect(mapStateToProps)(BlurbPreview);
