/* eslint-disable import/prefer-default-export */
import { makeHulkProvokerServiceRequest } from '../../helpers/requestHelpers';

export function getUserProfile() {
  const requestOptions = {
    url: '/user/get_user_profile',
  };

  return {
    type: 'GET_USER_PROFILE',
    payload: {
      promise: makeHulkProvokerServiceRequest(requestOptions),
    },
  };
}
