import _ from 'lodash';
import { currentRegion, deploymentRegions } from '../../../deploymentStages';

/* eslint-disable import/prefer-default-export */
export const isFileExtensionCSV = (file) => {
  if (!file) return false;
  const filename = file.name;
  const fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
  if (fileExtension === 'csv') return true;
  return false;
};

// Papaparse stores the attributes of a record which do not have any header in __parsed_extra.
export const doesFileContainNoHeaderColumn = headers => _.includes(headers, '__parsed_extra');

export const doesFileContainEmptyHeaders = headers => _.some(headers, _.isEmpty);

export const areNumberOfRowsOverLimit = (isCommunicationPlanSelected, rows)  => {
  return isCommunicationPlanSelected ? rows > 70000 : rows > 50000;
}

export const doesFileContainDuplicateHeaders = headers =>
  _.some(headers, (element, index) =>
    headers.indexOf(element) !== index,
  );

const S3_FILE_URL_REGEX_STRING = {
  [deploymentRegions.NA]: /(heartbeat-hulk-job-files-(devo|prod)-na\/.*\/job-input-file)/g,
  [deploymentRegions.EU]: /(heartbeat-hulk-job-files-(devo|prod)-eu\/.*\/job-input-file)/g,
  [deploymentRegions.FE]: /(heartbeat-hulk-job-files-(devo|prod)-fe\/.*\/job-input-file)/g,
  [deploymentRegions.CN]: /(s3.*\/heartbeat-hulk-job-files-(devo|prod)-cn\/.*\/job-input-file)/g,
};

const S3_PREFIX = {
  [deploymentRegions.NA]: 's3:///',
  [deploymentRegions.EU]: 's3:///',
  [deploymentRegions.FE]: 's3:///',
  [deploymentRegions.CN]: 's3://',
};

export const formatPreSignedUrlForJobSubmission = (preSignedUrl) => {
  // this is the part we care about from the preSignedUrl that will always be present:
  // bucket-name/file-path
  // heartbeat-hulk-job-files-devo/e6b85ef6-8171-46a8-9af5-c53fb16c73f8/job-input-file
  // in other words, everything from "heartbeat-hulk-job-files-devo" to "job-input-file"
  const bucketToKeyString = preSignedUrl.match(S3_FILE_URL_REGEX_STRING[currentRegion]);
  const formatedURL = S3_PREFIX[currentRegion] + bucketToKeyString;
  return `${formatedURL}`;
};
