import Papa from 'papaparse';
import _ from 'lodash';
import axios from 'axios';
import { isFileExtensionCSV } from './helpers';
import { makeHulkProvokerServiceRequest } from '../../../helpers/requestHelpers';

export const FILE_SELECTED = 'FILE_SELECTED';
export const FILE_PARSED = 'FILE_PARSED';
export const FILE_UPLOAD_PENDING = 'FILE_UPLOAD_PENDING';
export const FILE_UPLOAD_FULFILLED = 'FILE_UPLOAD_FULFILLED';
export const FILE_UPLOAD_REJECTED = 'FILE_UPLOAD_REJECTED';

export function selectFile(file) {
  return {
    type: FILE_SELECTED,
    file,
  };
}

export function parseFile(file, content) {
  return {
    type: FILE_PARSED,
    file,
    content,
  };
}

export function selectAndParseFile(file) {
  return (dispatch) => {
    dispatch(selectFile(file));
    if (isFileExtensionCSV(file)) {
      Papa.parse(file, {
        delimiter: ',',
        header: true,
        preview: 0,
        complete: (results) => {
          const size = _.size(results.data) - 1;

          // Do not read AsinTitle from the file as it will be fetched in the backend.
          const firstRecord = _.omit(_.get(results, 'data[0]', []), 'AsinTitle');

          // Do not read Name from the file as it will be replaced with customer's name
          // in the backend. Replace it with a static string to make the user aware.
          if (_.has(firstRecord, 'Name')) {
            _.set(firstRecord, 'Name', 'CUSTOMER_NAME')
          }

          let concessionValue = null;
          if (_.has(firstRecord, 'GCAmount')) {
            concessionValue = Number(_.sumBy(results.data, function(o) {
              if(o.GCAmount != null) {
                return Number(o.GCAmount);
              }
              return 0;
            }));
          } else if (_.has(firstRecord, 'PromoAmount')) {
            concessionValue = Number(_.sumBy(results.data, function (o) {
              if (o.PromoAmount != null) {
                return Number(o.PromoAmount);
              }
              return 0;
            }));
          }

          // Do not read EmailAddress from the file as it will be replaced with customer's email
          // address in the backend. Replace it with a static string to make the user aware.
          if (_.has(firstRecord, 'EmailAddress')) {
            _.set(firstRecord, 'EmailAddress', 'CUSTOMER_EMAIL_ADDRESS')
          }

          const headers = _.keys(firstRecord);
          let metadata = results.meta;

          metadata = {
            ...metadata,
            size,
            concessionValue
          }
          dispatch(parseFile(file, { headers, firstRecord, metadata }));
        },
      });
    }
    return Promise.resolve();
  };
}

export const generateFileUploadUrl = () => makeHulkProvokerServiceRequest({
  url: '/presentation/job-file-upload-url',
  method: 'post',
});

export const uploadFileToS3 = (signedUrl, file) => {
  const options = {
    headers: {
      'Content-Type': 'text/csv',
      'x-amz-server-side-encryption': 'AES256',
    },
  };

  return axios.put(signedUrl, file, options);
};

const fileUploadPending = () => ({
  type: FILE_UPLOAD_PENDING,
});

const fileUploadFulfilled = url => ({
  type: FILE_UPLOAD_FULFILLED,
  url,
});

export const fileUploadRejected = error => ({
  type: FILE_UPLOAD_REJECTED,
  error,
});

const fileUploadRejectedWithDefaultErrorMessage = () => fileUploadRejected('File upload failed.');

export function uploadFile() {
  return (dispatch, getState) => {
    dispatch(fileUploadPending());
    generateFileUploadUrl().then((payload) => {
      const url = payload.data.uploadUrl;
      // TODO: when replacing the mock API call for the real one,
      // verify if we need to check for empty urls in case of failures
      // or if that will be handled by the outermost catch.
      if (_.isEmpty(url)) {
        dispatch(fileUploadRejectedWithDefaultErrorMessage());
      } else {
        uploadFileToS3(url, getState().newJob.file.value).then(
          () => dispatch(fileUploadFulfilled(url)),
        ).catch(
          () => dispatch(fileUploadRejectedWithDefaultErrorMessage()),
        );
      }
    }).catch(
      () => dispatch(fileUploadRejectedWithDefaultErrorMessage()),
    );
  };
}
