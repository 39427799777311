import React, { Component } from 'react';
import _ from 'lodash';
import { FormGroup, ControlLabel, FormControl, Button, HelpBlock, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

export const loadStatus = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

// For return value details see https://react-bootstrap.github.io/components.html#forms-props-form-group
const getValidationState = (status, hasErrors) => {
  if (status === loadStatus.LOADED) return 'success';
  if (hasErrors) return 'error';
  return null;
};

export default class LoaderInputField extends Component {
  constructor(props) {
    super(props);
    this.isLoadEnabled = this.isLoadEnabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  isLoadEnabled() {
    return !_.isEmpty(this.props.value) && this.props.status === loadStatus.NOT_LOADED;
  }

  handleKeyPress(event) {
    const ENTER_KEY_CHAR_CODE = 13;
    if (event.charCode === ENTER_KEY_CHAR_CODE) {
      if (this.isLoadEnabled()) {
        event.preventDefault();
        this.handleSubmit();
      }
    }
  }

  handleSubmit() {
    this.props.onSubmit(this.props.value);
  }

  render() {
    const { name, status, error, required, helpBlock } = this.props;
    const hasErrors = !_.isEmpty(error);
    const validationState = getValidationState(status, hasErrors);
    const bsClass = classNames({
      'form-group': true,
      required,
    });
    
    return (
      <FormGroup
        bsClass={bsClass}
        controlId={`loader-input-field-${name}`}
        validationState={validationState}
      >
        <ControlLabel>{this.props.label}</ControlLabel>
        <InputGroup>
          <FormControl
            disabled={status === loadStatus.LOADING}
            type="input"
            name={name}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={e => this.props.onChange(e.target.value)}
            onKeyPress={this.handleKeyPress}
            required={required}
          />
          <InputGroup.Button>
            <Button
              disabled={!this.isLoadEnabled()}
              onClick={this.handleSubmit}
            >
              {this.props.buttonLabel}
            </Button>
          </InputGroup.Button>
        </InputGroup>
        { hasErrors &&
          <HelpBlock className="loader-input-field-error">{error}</HelpBlock>
        }
        { status === loadStatus.LOADING &&
          <HelpBlock className="loader-input-field-loading">Loading</HelpBlock>
        }
        {
          helpBlock &&
          <HelpBlock className="loader-input-field-custom-help-block">
            {helpBlock}
          </HelpBlock>
        }
      </FormGroup>
    );
  }
}
