import React from 'react';
import { CONCESSIONS_JOB_APPROVAL_POLICY_WIKI_URL } from '../src/externalLinks';

export const WARNING_MESSAGE_FOR_CHANGE_ORDER_CONDITION_PLANS = 'Changing orders to Condition 18 ' +
  '(Gift Click Hold) will result in these orders being sidelined for an unlimited time. No ' +
  'automated Ordering software moves orders in or out of condition 18. This means that you ' +
  'personally own all the steps following your actions taken, you need to un-sideline those ' +
  'orders again once all required actions have been performed within a reasonable amount of ' +
  'time (24hrs).';
export const WARNING_MESSAGE_FOR_BLURB_DEPENDENT_PLANS = 'Email/SMS may fail if the language of ' +
    'the blurb content does not match with the language shown in the dropdown. In order to send ' +
    'MobilePushText for a multi-lingual blurb, add it to the blurb.';

export const AlertContentForConcessionPlans = () => (
  <div>
    On 20 May 2019, WW Concessions introduced a new policy specifying the required approval for HULK
    Concession jobs. From 22 July 2019, users who violate this policy will immediately lose access
    to HULK and have their level 8 leader messaged about non-compliance. Regaining access to Hulk
    post removal will require approval from the individual’s level 8 leader.
    <a href={CONCESSIONS_JOB_APPROVAL_POLICY_WIKI_URL} target="_blank" rel="noopener noreferrer">
      Read more.</a>
  </div>
);
