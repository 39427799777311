import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { UPLOADED } from '../../../redux/newJob/file/uploadStatus';
import { selectedFileErrorMessageSelector } from '../../../redux/newJob/file/selectors';
import { fileUploadRejected } from '../../../redux/newJob/file/actions';
import FileChooser from './FileChooser';
import SelectedFile from './SelectedFile';

// For return value details see https://react-bootstrap.github.io/components.html#forms-props-form-group
const getValidationState = (touched, hasErrors, uploadStatus) => {
  if (uploadStatus === UPLOADED) { return 'success'; }
  if (touched && hasErrors) { return 'error'; }
  return null;
};

export class FileUpload extends Component {
  render() {
    const file = this.props.file;
    const hasErrors = !!this.props.selectedFileErrorMessage || file.upload.error;
    const validationState = getValidationState(file.touched, hasErrors, file.upload.status);

    // If we change blurb after successfully uploading an input file and new file doesn't
    // satisfy the file requirements, we will change our state to file NOT_UPLOADED.
    if (this.props.fileUploadStatus === UPLOADED
      && !_.isEmpty(this.props.selectedFileErrorMessage)) {
      this.props.forgetUploadedFile();
    }

    return (
      <FormGroup
        bsClass="form-group required"
        controlId="jobFile"
        validationState={validationState}
      >
        <ControlLabel>File</ControlLabel>
        {_.isEmpty(this.props.selectedFileErrorMessage)
          ? <SelectedFile />
          : <FileChooser />
        }
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFileErrorMessage: selectedFileErrorMessageSelector(state),
    file: state.newJob.file,
    fileUploadStatus: state.newJob.file.upload.status,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    forgetUploadedFile: fileUploadRejected,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
