import { makeHulkProvokerServiceRequest, makeHulkProvokerServiceRequestForPost, getParameterByName } from '../../helpers/requestHelpers';

const VALIDATE_TICKET = 'VALIDATE_TICKET';
const GET_TOTAL_CONCESSION = 'GET_TOTAL_CONCESSION';
const SUCCESSFUL = 'SUCCESSFUL';
const INVALID_JOB_ID = 'INVALID_JOB_ID';
const IN_PROGRESS = 'IN_PROGRESS';
const ACTION_NAME_MISMATCH = 'ACTION_NAME_MISMATCH';
const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
const VALIDATED_JOB_ID = 'VALIDATED_JOB_ID';
const FETCH_API = 'FETCH_API';
const FETCH_API_SUCCESSS = 'FETCH_API_SUCCESSS';
const VALIDATE_APPROVER_ALIAS = 'VALIDATE_APPROVER_ALIAS';

const detailsFormAsyncValidation = (values, dispatch) => (
  new Promise((resolve, reject) => {
    dispatch({
      type: VALIDATE_TICKET,
      payload: makeHulkProvokerServiceRequest({
        url: `/presentation/tickets/${values.simttID}/validation`,
      }),
    }).then((result) => {
      if (result.action.payload.status === 200) {
        const errorMessage = result.action.payload.data.errorMessage;

        if (errorMessage === '') {
          resolve();
        } else {
          reject({ simttID: errorMessage });
        }
      } else {
        reject({ simttID: 'Ticket could not be validated. Re-enter the ticket ID.' });
      }
    });
  })
);

export const totalConcessionAsyncValidation = (values, dispatch, props) => (


    new Promise((resolve, reject) => {
        console.log('props = ')
        console.log(props.totalConcessionSuccessMessage)
        const planName = props.planName;
        const currencyCode = props.currencyCode;
        const jobId = getParameterByName('jobId');
        const successMessage = props.totalConcessionSuccessMessage;

        if (successMessage !== '')
            resolve();

        dispatch({
            type: FETCH_API,
        });
        dispatch({
            type: GET_TOTAL_CONCESSION,
            payload: makeHulkProvokerServiceRequest({
                url: `/reporting/total-concession/${(jobId || values.JobID)}?actionName=${planName}`,
            }),
        }).then((result) => {
             dispatch({
                type: FETCH_API_SUCCESSS,
            });

            if (result.action.payload.status === 200) {
                const totalConcession = result.action.payload.data.totalConcession;
                const statusCode = result.action.payload.data.statusCode;
                const totalRecords = result.action.payload.data.totalRecords;
                const failureCount = result.action.payload.data.failureCount;

                if (statusCode === SUCCESSFUL) {
                    dispatch({
                        type: SUCCESS_MESSAGE,
                        totalConcessionSuccessMessage: `Total Concession Amount: ${totalConcession} ${currencyCode}, Total Records: ${totalRecords}, Number of failed records: ${failureCount}`,
                        totalConcessionAmount: totalConcession,
                        failureCount: failureCount,
                    });
                    dispatch({
                        type: VALIDATED_JOB_ID,
                        jobId: (jobId || values.JobID),
                    });
                    resolve();
                }
                else if (statusCode === INVALID_JOB_ID) {
                    dispatch({
                        type: SUCCESS_MESSAGE,
                        totalConcessionSuccessMessage: '',
                    });
                    reject({JobID: `JobID populated is invalid. Retry after some time.`});
                }
                else if (statusCode === ACTION_NAME_MISMATCH){
                    dispatch({
                        type: SUCCESS_MESSAGE,
                        totalConcessionSuccessMessage: '',
                    });
                    reject({JobID: `The selected plan does not match the validation job type.`});
                }
                else if (statusCode === IN_PROGRESS) {
                    dispatch({
                        type: SUCCESS_MESSAGE,
                        totalConcessionSuccessMessage: '',
                    });
                    reject({JobID: `The Validation Job is in progress. Retry after some time.`});
                }
                else {
                    dispatch({
                        type: SUCCESS_MESSAGE,
                        totalConcessionSuccessMessage: '',
                    });
                    reject({JobID: `Something went wrong. Retry after some time.`});
                }
            } else {
                dispatch({
                    type: SUCCESS_MESSAGE,
                    totalConcessionSuccessMessage: '',
                });
                reject({ JobID: 'Total Concession could not be calculated. Retry after some time.' });
            }
        }).catch((error) => {
            console.error(error);
            dispatch({
                type: FETCH_API_SUCCESSS,
            });
            dispatch({
                type: SUCCESS_MESSAGE,
                totalConcessionSuccessMessage: '',
            });
            reject({ JobID: 'Failed to retrieve Total Concession. Retry after some time.' });
        });
    })
);


export const shouldAsyncValidateRequirementsForm = function (params) {
    const jobId = getParameterByName('jobId');
    if (jobId) return true;
    if (!jobId && params.blurredField !== 'JobID') return false;
    switch (params.trigger) {
        case 'blur':
        case 'change':
            // blurring or changing
            return !params.pristine
        case 'submit':
            return !params.pristine || params.initialized
        default:
            return false
    }
};

export const approverAliasAsyncValidation = (values, dispatch, props) =>
new Promise((resolve, reject) => {
    const asyncErrors = (props && props.asyncErrors) || null;
    const inputData = {
      UserList: values[props.currentApprovalName],
      TotalConcessionValueInUSD: props.totalConcessionValueinUSD,
      MarketplaceID: props.selectedMarketplaceInfo.id,
      FieldName: props.currentApprovalName,
      login: props.login,
    };
    const requestOptions = {
      url: '/presentation/approver/validation',
      data: inputData,
    };

    dispatch({
      type: VALIDATE_APPROVER_ALIAS,
      payload: {
        promise: makeHulkProvokerServiceRequestForPost(requestOptions),
      },
    })
      .then((result) => {
        if (result.action.payload.status === 200 && result.action.payload.data.isValid) {
          if (!asyncErrors) {
            resolve();
          } else {
            reject({
              ...asyncErrors,
            });
          }
        } else {
          reject({
            ...asyncErrors,
            [props.currentApprovalName]: result.action.payload.data.errorMessage,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        reject({
          ...asyncErrors,
          [props.currentApprovalName]: error.response.data || "Failed to validate user alias. Retry after some time.",
        });
      });
  });

export const shouldAsyncValidateApproverAlias = function (params) {
  const jobId = getParameterByName('jobId');
  if (!jobId) return false;
  switch (params.trigger) {
    case 'blur':
    case 'change':
      // blurring or changing
      return !params.pristine;
    case 'submit':
      return !params.pristine || params.initialized;
    default:
      return false;
  }
};

export default detailsFormAsyncValidation;
