import { createSelector } from 'reselect';

export const totalConcessionSuccessMessageSelector = createSelector(
  (state) => state.totalConcessionSuccessMessageReducer,
  ({ totalConcessionSuccessMessage }) => totalConcessionSuccessMessage,
);

export const totalConcessionAmountSelector = createSelector(
  (state) => state.totalConcessionSuccessMessageReducer,
  ({ totalConcessionAmount }) => totalConcessionAmount,
);

export const failureCountSelector = createSelector(
  (state) => state.totalConcessionSuccessMessageReducer,
  ({ failureCount }) => failureCount,
);

export const getCurrentApprovalName = createSelector(
  (state) => state.totalConcessionSuccessMessageReducer,
  ({ currentApprovalName }) => currentApprovalName,
);
