import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { selectAndParseFile, uploadFile } from '../../../redux/newJob/file/actions';
import { NOT_UPLOADED } from '../../../redux/newJob/file/uploadStatus';

export class SelectedFileButtonToolbar extends Component {
  constructor(props) {
    super(props);
    this.replaceFile = this.replaceFile.bind(this);
  }

  replaceFile() {
    this.props.selectAndParseFile(null);
  }

  render() {
    return (
      <ButtonToolbar>
        <Button
          className="selected-file-replace"
          bsSize="small"
          onClick={this.replaceFile}
        >
          <i className="fa fa-files-o" aria-hidden="true" /> Replace
        </Button>

        <Button
          className="selected-file-upload"
          bsSize="small"
          bsStyle="primary"
          disabled={this.props.file.upload.status !== NOT_UPLOADED}
          onClick={this.props.uploadFile}
        >
          <i className="fa fa-upload" aria-hidden="true" /> Upload
        </Button>
      </ButtonToolbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    file: state.newJob.file,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selectAndParseFile, uploadFile }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFileButtonToolbar);
