import React from 'react';
import { Well } from 'react-bootstrap';

export const blurbTemplateString = '<blurb>\n' +
'\t<FromName>Amazon.com</FromName>\n' +
'\t<FromAddress>order-update@amazon.com</FromAddress>\n' +
'\t<Subject>Test Subject</Subject>\n' +
'\t<Content>Test Content</Content>\n' +
'</blurb>';

const BlurbTemplate = () => (
  <div>
    <h4>Expected Blurb Template</h4>
    <Well style={{ whiteSpace: 'pre-wrap' }}>
      {blurbTemplateString}
    </Well>
  </div>
);

export default BlurbTemplate;
