import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import PlanActions from './PlanActions';

export default class PlanRadioField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded || props.checked,
      hovering: false,
    };
    this.expandPanel = this.expandPanel.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.renderPanelContent = this.renderPanelContent.bind(this);
  }

  onMouseEnter() {
    this.setState({ hovering: true });
  }

  onMouseLeave() {
    this.setState({ hovering: false });
  }

  expandPanel() {
    this.setState({ expanded: true });
  }

  togglePanel() {
    this.setState({ expanded: !this.state.expanded });
  }

  renderPanelContent() {
    return (
      <div>
        <div>{this.props.description}</div>
        <h5>ACTIONS</h5>
        <PlanActions actions={this.props.actions} />
      </div>
    );
  }

  render() {
    const toggleText = this.state.expanded ? 'Hide details' : 'Show details';
    const showToggleText = this.state.expanded || this.state.hovering;
    const header = (
      <Panel.Title>
        {
          showToggleText &&
          <a className="pull-right" onClick={this.togglePanel} role="presentation">
            {toggleText}
          </a>
        }

        <label htmlFor={this.props.input.value} >
          <input
            id={this.props.input.value}
            type="radio"
            value={this.props.input.value}
            name={this.props.input.name}
            onFocus={this.expandPanel}
            onChange={this.props.input.onChange}
            checked={this.props.checked}
          />
          {' '}
          {this.props.label}
        </label>
      </Panel.Title>
    );
    return (
      <Panel
        expanded={this.state.expanded}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Panel.Heading>{header}</Panel.Heading>
        {this.state.expanded && <Panel.Body>{this.renderPanelContent()}</Panel.Body>}
      </Panel>
    );
  }
}

PlanRadioField.propTypes = {
  label: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  description: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
};

PlanRadioField.defaultProps = {
  expanded: false,
};
