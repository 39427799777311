import { makeHulkProvokerServiceRequest } from '../../helpers/requestHelpers';
import {
  getJarvisAPIRequestOptionsForJobDetails,
  getJarvisAPIRequestOptionsForJobExecutionSummary,
  getJarvisAPIRequestOptionsForListOfJobs,
  getJarvisAPIRequestOptionsForRecordProcessingWorkflowsStatus,
  getApprovalAPIRequestForApprovalDetails,
  INITIAL_CURSOR,
  getApprovalAPIRequestOptionsForListOfJobsSubmittedForApproval,
} from '../jobs/jarvisConstants';

const SEARCH_JOBS = 'SEARCH_JOBS';
export const SEARCH_JOBS_PENDING = 'SEARCH_JOBS_PENDING';
export const SEARCH_JOBS_FULFILLED = 'SEARCH_JOBS_FULFILLED';

const LOAD_MORE_JOBS = 'LOAD_MORE_JOBS';
export const LOAD_MORE_JOBS_PENDING = 'LOAD_MORE_JOBS_PENDING';
export const LOAD_MORE_JOBS_FULFILLED = 'LOAD_MORE_JOBS_FULFILLED';

export const SEARCH_QUERY_CHANGED = 'SEARCH_QUERY_CHANGED';

const GET_JOB_DETAILS = 'GET_JOB_DETAILS';
export const GET_JOB_DETAILS_PENDING = 'GET_JOB_DETAILS_PENDING';
export const GET_JOB_DETAILS_FULFILLED = 'GET_JOB_DETAILS_FULFILLED';

const GET_APPROVALS = 'GET_APPROVALS';
export const GET_APPROVALS_PENDING = 'GET_APPROVALS_PENDING';
export const GET_APPROVALS_FULFILLED = 'GET_APPROVALS_FULFILLED';

const GET_JOB_EXECUTION_SUMMARY = 'GET_JOB_EXECUTION_SUMMARY';
export const GET_JOB_EXECUTION_SUMMARY_PENDING = 'GET_JOB_EXECUTION_SUMMARY_PENDING';
export const GET_JOB_EXECUTION_SUMMARY_FULFILLED = 'GET_JOB_EXECUTION_SUMMARY_FULFILLED';

const GET_CHUNK_PROCESSING_WORKFLOWS_STATUS = 'GET_CHUNK_PROCESSING_WORKFLOWS_STATUS';
export const GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_PENDING = 'GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_PENDING';
export const GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_FULFILLED = 'GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_FULFILLED';

const GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS = 'GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS';
export const GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_PENDING = 'GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_PENDING';
export const GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_FULFILLED = 'GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_FULFILLED';

const GET_JOB_APPROVAL_SUMMARY = 'GET_JOB_APPROVAL_SUMMARY';
export const GET_JOB_APPROVAL_SUMMARY_PENDING = 'GET_JOB_APPROVAL_SUMMARY_PENDING';
export const GET_JOB_APPROVAL_SUMMARY_FULFILLED = 'GET_JOB_APPROVAL_SUMMARY_FULFILLED';

export const searchJobs = () => ((dispatch, getState) => {
  const searchCursor = getState().jobs.searchParams.cursor;
  const searchQuery = getState().jobs.searchParams.search_query;
  const actionType = (searchCursor === INITIAL_CURSOR) ? SEARCH_JOBS : LOAD_MORE_JOBS;

  dispatch({
    type: actionType,
    payload: {
      promise: makeHulkProvokerServiceRequest(getJarvisAPIRequestOptionsForListOfJobs(searchCursor, searchQuery)),
    },
  });
});

export const getApprovalsSubmittedByUser = () => ((dispatch) => {
  dispatch({
    type: GET_APPROVALS,
    payload: {
      promise: makeHulkProvokerServiceRequest(getApprovalAPIRequestOptionsForListOfJobsSubmittedForApproval()),
    },
  });
});

export const changeSearchQuery = newSearchQuery => ((dispatch, getState) => {
  dispatch({
    type: SEARCH_QUERY_CHANGED,
    newSearchQuery,
  });

  if (newSearchQuery === '') {
    const searchCursor = getState().jobs.searchParams.cursor;
    dispatch({
      type: SEARCH_JOBS,
      payload: {
        promise: makeHulkProvokerServiceRequest(getJarvisAPIRequestOptionsForListOfJobs(searchCursor, newSearchQuery)),
      },
    });
  }
});

export const getJobDetails = jobID => ((dispatch) => {
  dispatch({
    type: GET_JOB_DETAILS,
    payload: {
      promise: makeHulkProvokerServiceRequest(getJarvisAPIRequestOptionsForJobDetails(jobID)),
    },
  });
});


export const getJobExecutionSummary = jobID => ((dispatch) => {
  dispatch({
    type: GET_JOB_EXECUTION_SUMMARY,
    payload: {
      promise: makeHulkProvokerServiceRequest(
        getJarvisAPIRequestOptionsForJobExecutionSummary(jobID, 'HULKRecordProcessingWorkflow')),
    },
  });
});

export const getChunkProcessingWorkflowStatus = jobID => ((dispatch) => {
  dispatch({
    type: GET_CHUNK_PROCESSING_WORKFLOWS_STATUS,
    payload: {
      promise: makeHulkProvokerServiceRequest(
        getJarvisAPIRequestOptionsForJobExecutionSummary(jobID, 'HULKChunkProcessingWorkflowEP')),
    },
  });
});

export const getRecordProcessingWorkflowsStatus = () => ((dispatch) => {
  dispatch({
    type: GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS,
    payload: {
      promise: makeHulkProvokerServiceRequest(
        getJarvisAPIRequestOptionsForRecordProcessingWorkflowsStatus()),
    },
  });
});

export const getJobApprovalSummary = jobID => ((dispatch) => {
    dispatch({
        type: GET_JOB_APPROVAL_SUMMARY,
        payload: {
          promise: makeHulkProvokerServiceRequest(
            getApprovalAPIRequestForApprovalDetails(jobID)),
        },
      });
});
