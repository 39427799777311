/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export function parseBlurbXml(xml) {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser();
  return parser.parseFromString(xml, 'text/xml');
}

export function isValidXml(blurb) {
  const parsedBlurb = parseBlurbXml(blurb.content);
  if (parsedBlurb.getElementsByTagName('parsererror').length === 0) {
    return true;
  }
  return false;
}

export function containsAllMandatoryFields(blurb) {
  const parsedBlurb = parseBlurbXml(blurb.content);
  if (parsedBlurb.getElementsByTagName('FromName').length === 1
  && parsedBlurb.getElementsByTagName('FromAddress').length === 1
  && parsedBlurb.getElementsByTagName('Subject').length === 1
  && parsedBlurb.getElementsByTagName('Content').length === 1) {
    return true;
  }
  return false;
}
