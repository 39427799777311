import {GET_CONCESSION_REASONS_PENDING} from "./actions";

export const CONVERT_CURRENCY_FULFILLED = 'CONVERT_CURRENCY_FULFILLED';
export const initialState = { targetCurrencyValue: '' };
export default function convertCurrency(state = initialState, action) {
    switch (action.type) {
        case GET_CONCESSION_REASONS_PENDING:
            return initialState;
        case CONVERT_CURRENCY_FULFILLED:
            return {
                ...state,
        targetCurrencyValue: action.payload.data.convertedValues[0].targetCurrencyValue,
            };
        default:
            return state;
    }
}
