import { NOT_UPLOADED, UPLOADING, UPLOADED } from './uploadStatus';
import { FILE_SELECTED, FILE_PARSED, FILE_UPLOAD_PENDING, FILE_UPLOAD_FULFILLED, FILE_UPLOAD_REJECTED } from './actions';
import { RESET_REQUIREMENTS_FORM } from '../actions';

const emptyContent = {
  headers: [],
  firstRecord: [],
};

export const initialState = {
  value: {
    name : ''
  },
  touched: false,
  parsedContent: emptyContent,
  upload: {
    url: '',
    status: NOT_UPLOADED,
    error: '',
  },
};

export default function file(state = initialState, action) {
  switch (action.type) {
    case FILE_SELECTED:
      return {
        ...state,
        value: action.file,
        touched: true,
        parsedContent: emptyContent,
        upload: {
          ...state.upload,
          status: NOT_UPLOADED,
          error: '',
        },
      };
    case FILE_PARSED:
      return {
        ...state,
        parsedContent: action.content,
      };
    case FILE_UPLOAD_PENDING:
      return {
        ...state,
        upload: {
          url: '',
          status: UPLOADING,
          error: '',
        },
      };
    case FILE_UPLOAD_FULFILLED:
      return {
        ...state,
        upload: {
          url: action.url,
          status: UPLOADED,
          error: '',
        },
      };
    case FILE_UPLOAD_REJECTED:
      return {
        ...state,
        upload: {
          url: '',
          status: NOT_UPLOADED,
          error: action.error,
        },
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
