export const workflowStatusType = {
  IN_PROGRESS: 'IN_PROGRESS',
  CREATED: 'CREATED',
  SUCCESS_TYPE: ['SUCCEEDED', 'SUCCESS'],
  TERMINATED: 'TERMINATED',
  FAILED_TYPE: ['FAILED_WITH_EXCEPTION', 'FAILED'],
  TIMED_OUT: 'TIMED_OUT',
  SCHEDULED: ['UNKNOWN', 'SCHEDULED'],
  // There might be other status type in the backend.
  // these are the relevant ones for now.
};

export const jobStatusType = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN PROGRESS',
  IN_QUEUE: 'IN QUEUE',
  IN_PROGRESS_WITH_ERRORS: 'IN PROGRESS - with errors',
};
