import {
    SUBMIT_APPROVAL_FULFILLED,
    SUBMIT_APPROVAL_PENDING,
    SUBMIT_APPROVAL_REJECTED,
    SUBMIT_JOB_FULFILLED
} from "../../newJob/actions";

export const initialState = {
    isLoadingApprovalApi: false,
    exception: null,
    jobID: null,
    approvalID: null
};

export default function approvalJobDetails(state = initialState, action) {
    switch (action.type) {
        case SUBMIT_APPROVAL_PENDING:
            return {
                ...state,
                isLoadingApprovalApi: true,
            };
        case SUBMIT_APPROVAL_FULFILLED:
            return {
                ...state,
                exception: null,
                isLoadingApprovalApi: false,
                jobID: action.payload.data.jobID,
                approvalID: action.payload.data.approvalID
            };
        case SUBMIT_APPROVAL_REJECTED:
            return {
                ...state,
                isLoadingApprovalApi: false,
                exception: action.payload.response.data,
            };
        case SUBMIT_JOB_FULFILLED:
            return {
                ...state,
                isLoadingApprovalApi: false,
                exception: null,
                jobID: null,
                approvalID: null
            }
        default:
            return state;
    }
}