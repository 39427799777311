import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fileRequirementNamesSelector } from '../../redux/newJob/file/selectors';
import { isSelectedPlanBlurbDependentSelector } from '../../redux/newJob/plans/selectors';
import { loadStatus } from '../../components/NewJob/LoaderInputField';

export class FileRequirementsTemplate extends Component {
  renderTemplateLink() {
    const csvRequirements = this.props.requirements.join(',');
    return (
      <div>
        <a
          download="template.csv"
          href={`data:text/csv;charset=utf-8,${encodeURI(csvRequirements)}`}
        >Download template file to add required values for this job</a>
      </div>
    );
  }

  render() {
    const shouldRenderLoadBlurbMessage =
      this.props.isSelectedPlanBlurbDependent
      && !this.props.isBlurbLoaded;

    const loadBlurbMessage = (<div>Load blurb to generate template file.</div>);
    return shouldRenderLoadBlurbMessage ? loadBlurbMessage : this.renderTemplateLink();
  }
}

FileRequirementsTemplate.defaultProps = {
  requirements: [],
  isSelectedPlanBlurbDependent: false,
};

function mapStateToProps(state) {
  return {
    requirements: fileRequirementNamesSelector(state),
    isSelectedPlanBlurbDependent: isSelectedPlanBlurbDependentSelector(state),
    isBlurbLoaded: state.newJob.blurb.loader.status === loadStatus.LOADED,
  };
}


export default connect(mapStateToProps)(FileRequirementsTemplate);
