import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getHumanFriendlyDateTime } from '../helpers/dateHelpers';
import './JobsTable.scss';
import { getCommaSeparatedApprovers } from '../helpers/approverListHelpers';
import { INTEGRATED_APPROVALS_LINK } from '../endpoints';

export default class ApprovalsTable extends Component {
  constructor(props) {
    super(props);
    this.renderApprovals = this.renderApprovals.bind(this);
  }

  renderApprovals({ jobID, approvalID, jobSubmitter, startTime, approvers, approvalStatus }) {
    const jobDetailsUrl = `/jobs/${jobID}`;

    return (
      <tr key={jobID} onClick={() => (this.props.router.navigate(jobDetailsUrl))}>
        <td><Link to={jobDetailsUrl}>{jobID}</Link></td>
        <td>{approvalID}</td>
        <td>{jobSubmitter}</td>
        <td>{getHumanFriendlyDateTime(startTime)}</td>
        <td className="wrapped-text">{getCommaSeparatedApprovers(approvers)}</td>
        <td>{approvalStatus}</td>
      </tr>
    );
  }

  render() {
    if (_.isEmpty(this.props.approvals)) {
      return (
        <div>No approvals found in last 7 days. In order to see older approvals, go to
            <a href={INTEGRATED_APPROVALS_LINK} target="_blank" rel="noopener noreferrer">  approvals Page</a>
        </div>);
    }

    return (
      <div>
        <Table responsive striped bordered condensed hover>
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Approval ID</th>
              <th>Submitted By</th>
              <th>Submission time</th>
              <th>approvers</th>
              <th>Approval Status</th>
            </tr>
          </thead>
          <tbody>
            {this.props.approvals.map(approval => this.renderApprovals(approval))}
          </tbody>
        </Table>
        <div>
            In order to see approvals older than 7 days, go to
            <a href={INTEGRATED_APPROVALS_LINK} target="_blank" rel="noopener noreferrer">  approvals Page</a>
        </div>
      </div>
    );
  }
}

ApprovalsTable.defaultProps = {
  approvals: [],
};
