import React, { Component } from 'react';
import { ButtonDropdown } from 'AWS-UI-Components-React';
import { openInNewTab } from '../../helpers/requestHelpers';
import {
  REPORT_ISSUE_URL,
  USER_MANUAL_WIKI_URL,
  REPORT_INTERPRETATION_WIKI_LINK,
  REQUEST_ACCESS_URL,
  REQUEST_FEATURE_URL,
  HULK_SAGE_LINK,
  CONCESSIONS_REASONS_WIKI_URL,
  HULK_PLANS_SOP_WIKI_URL,
  HULK_STUCK_JOB_TT_QUICK_LINK,
  INTEGRATED_APPROVALS_WIKI_URL
} from '../../externalLinks';
import './Header.scss';

export default class HelpMenu extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id) {
    switch (id) {
      case 'UserManual':
        openInNewTab(USER_MANUAL_WIKI_URL);
        break;
      case 'ReportInterpretation':
        openInNewTab(REPORT_INTERPRETATION_WIKI_LINK);
        break;
      case 'ReasonCodes':
        openInNewTab(CONCESSIONS_REASONS_WIKI_URL);
        break;
      case 'ReportIssue':
        openInNewTab(REPORT_ISSUE_URL);
        break;
      case 'RequestAccess':
        openInNewTab(REQUEST_ACCESS_URL);
        break;
      case 'RequestFeature':
        openInNewTab(REQUEST_FEATURE_URL);
        break;
      case 'AskQuery':
        openInNewTab(HULK_SAGE_LINK);
        break;
      case 'PlansSOP':
        openInNewTab(HULK_PLANS_SOP_WIKI_URL);
        break;
      case 'HulkStuckJob':
        openInNewTab(HULK_STUCK_JOB_TT_QUICK_LINK);
        break;
      case 'IntegratedApprovals':
        openInNewTab(INTEGRATED_APPROVALS_WIKI_URL);
        break;
      default:
        break;
    }
  }

  render() {
    const helpMenuItems = [
      {
        text: 'Documentation',
        items: [
          {
            text: 'Hulk user manual',
            id: 'UserManual',
          },
          {
            text: 'HULK Integrated Approvals user Manual',
            id: 'IntegratedApprovals',
          },
          {
            text: 'Job plans guidance',
            id: 'PlansSOP',
          },
          {
            text: 'Interpreting Hulk report',
            id: 'ReportInterpretation',
          },
          {
            text: 'Reason codes and Currency codes',
            id: 'ReasonCodes',
          },
        ],
      },
      {
        text: 'Contact us',
        items: [
          {
            text: 'Report a HULK Job Stuck issue',
            id: 'HulkStuckJob',
          },
          {
            text: 'Report an issue',
            id: 'ReportIssue',
          },
          {
            text: 'Request Hulk access',
            id: 'RequestAccess',
          },
          {
            text: 'Request a new feature in Hulk',
            id: 'RequestFeature',
          },
          {
            text: 'Ask about Hulk on Sage',
            id: 'AskQuery',
          },
        ],
      },
    ];

    return (
      <ButtonDropdown
        className="help-dropdown"
        items={helpMenuItems}
        text="Help"
        onItemClick={e => this.handleClick(e.detail.id)}
      />
    );
  }
}
