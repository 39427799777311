import { makeHulkProvokerServiceRequest } from '../../../helpers/requestHelpers';

const GET_PLANS = 'GET_PLANS'; // will produce the following actions:
export const GET_PLANS_FULFILLED = 'GET_PLANS_FULFILLED';

export function mapApiResponse(response) {
  return ({
    plans: response.ActionRecipes.map(
      ({ actionRecipe, authorized }) => ({
        id: actionRecipe.actionRecipeId.val,
        name: actionRecipe.actionRecipeMetadata.name,
        description: actionRecipe.actionRecipeMetadata.description,
        actions: actionRecipe.actions.map(({ actionId, actionMetadata }) => ({
          id: actionId.val,
          name: actionMetadata.name,
          description: actionMetadata.description,
        })),
        authorized,
        requirements: actionRecipe.actionRecipeParameters.parameters.map(
          ({ parameterName, parameterType, isOptional }) => ({
            name: parameterName,
            type: parameterType,
            isOptional,
          }),
        ),
      }),
    ),
  });
}

export function getPlans() {
  const requestOptions = {
    url: '/presentation/action-recipes',
  };

  return {
    type: GET_PLANS,
    payload: {
      promise: makeHulkProvokerServiceRequest(requestOptions),
    },
  };
}

