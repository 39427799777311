import { deploymentStages, currentStage, deploymentRegions, currentRegion } from './deploymentStages';

let HULK_PROVOKER_SERVICE_ENDPOINT;

const HULK_PROVOKER_SERVICE_ALPHA = 'https://hulk-uz1-pdx-pd-tcp.integ.amazon.com';
const HULK_PROVOKER_SERVICE_BETA = 'https://hulk-uz1-pdx-d-tcp.integ.amazon.com';
const HULK_PROVOKER_SERVICE_GAMMA = {
  [deploymentRegions.NA]: 'https://hulk-uz1-backend-na-pp-tcp-iad.iad.proxy.amazon.com',
  [deploymentRegions.EU]: 'https://hulk-uz1-backend-eu-pp-tcp-dub.dub.proxy.amazon.com',
};
const HULK_PROVOKER_SERVICE_PROD = {
  [deploymentRegions.NA]: 'https://hulk-uz1-backend-na-p-tcp-iad.iad.proxy.amazon.com',
  [deploymentRegions.EU]: 'https://hulk-uz1-backend-eu-p-tcp-dub.dub.proxy.amazon.com',
  [deploymentRegions.FE]: 'https://hulk-uz1-backend-jp-p-tcp-pdx.pdx.proxy.amazon.com',
  [deploymentRegions.CN]: 'https://hulk-uz1-backend-cn-p-tcp-pek.pek.proxy.amazon.com',
};

const JARVIS_EXPORT_ENDPOINT = 'https://jarvis-console.amazon.com/api/process/export';
const JARVIS_SERVICE_ENDPOINT = 'http://jarvis-process-service.corp.amazon.com';

let JARVIS_X_AMZN_ACCOUNT_NAME;
const JARVIS_X_AMZN_ACCOUNT_NAME_NA_PROD = 'hulk_na';
const JARVIS_X_AMZN_ACCOUNT_NAME_NA_DEVO = 'hulk_beta_na';
const JARVIS_X_AMZN_ACCOUNT_NAME_EU_PROD = 'hulk_eu';
const JARVIS_X_AMZN_ACCOUNT_NAME_EU_DEVO = 'hulk_beta_eu';
const JARVIS_X_AMZN_ACCOUNT_NAME_FE_PROD = 'hulk_fe';
const JARVIS_X_AMZN_ACCOUNT_NAME_FE_DEVO = 'hulk_beta_fe';
const JARVIS_X_AMZN_ACCOUNT_NAME_CN_PROD = 'hulk_cn';

const JARVIS_X_AMZN_ACCOUNT_NAME_DEVO = {
  [deploymentRegions.NA]: JARVIS_X_AMZN_ACCOUNT_NAME_NA_DEVO,
  [deploymentRegions.EU]: JARVIS_X_AMZN_ACCOUNT_NAME_EU_DEVO,
  [deploymentRegions.FE]: JARVIS_X_AMZN_ACCOUNT_NAME_FE_DEVO,
  [deploymentRegions.CN]: JARVIS_X_AMZN_ACCOUNT_NAME_FE_DEVO, // There's no account in CN Beta.
};

const JARVIS_X_AMZN_ACCOUNT_NAME_PROD = {
  [deploymentRegions.NA]: JARVIS_X_AMZN_ACCOUNT_NAME_NA_PROD,
  [deploymentRegions.EU]: JARVIS_X_AMZN_ACCOUNT_NAME_EU_PROD,
  [deploymentRegions.FE]: JARVIS_X_AMZN_ACCOUNT_NAME_FE_PROD,
  [deploymentRegions.CN]: JARVIS_X_AMZN_ACCOUNT_NAME_CN_PROD,
};

let INTEGRATED_APPROVALS_LINK;
const INTEGRATED_APPROVALS_LINK_NA_PROD = 'https://approvals.amazon.com/Approval/Details/';
const INTEGRATED_APPROVALS_LINK_NA_DEVO = 'https://approvalstage.amazon.com/Approval/Details/';

switch (currentStage) {
  case deploymentStages.ALPHA:
    HULK_PROVOKER_SERVICE_ENDPOINT = HULK_PROVOKER_SERVICE_ALPHA;
    JARVIS_X_AMZN_ACCOUNT_NAME = JARVIS_X_AMZN_ACCOUNT_NAME_DEVO[currentRegion];
    INTEGRATED_APPROVALS_LINK = INTEGRATED_APPROVALS_LINK_NA_DEVO;
    break;
  case deploymentStages.BETA:
    HULK_PROVOKER_SERVICE_ENDPOINT = HULK_PROVOKER_SERVICE_BETA;
    JARVIS_X_AMZN_ACCOUNT_NAME = JARVIS_X_AMZN_ACCOUNT_NAME_DEVO[currentRegion];
    INTEGRATED_APPROVALS_LINK = INTEGRATED_APPROVALS_LINK_NA_DEVO;
    break;
  case deploymentStages.GAMMA:
    HULK_PROVOKER_SERVICE_ENDPOINT = HULK_PROVOKER_SERVICE_GAMMA[currentRegion];
    JARVIS_X_AMZN_ACCOUNT_NAME = JARVIS_X_AMZN_ACCOUNT_NAME_PROD[currentRegion];
    INTEGRATED_APPROVALS_LINK = INTEGRATED_APPROVALS_LINK_NA_PROD;
    break;
  case deploymentStages.PROD:
    HULK_PROVOKER_SERVICE_ENDPOINT = HULK_PROVOKER_SERVICE_PROD[currentRegion];
    JARVIS_X_AMZN_ACCOUNT_NAME = JARVIS_X_AMZN_ACCOUNT_NAME_PROD[currentRegion];
    INTEGRATED_APPROVALS_LINK = INTEGRATED_APPROVALS_LINK_NA_PROD;
    break;
  default:
    HULK_PROVOKER_SERVICE_ENDPOINT = HULK_PROVOKER_SERVICE_BETA;
    JARVIS_X_AMZN_ACCOUNT_NAME = JARVIS_X_AMZN_ACCOUNT_NAME_DEVO[currentRegion];
    INTEGRATED_APPROVALS_LINK = INTEGRATED_APPROVALS_LINK_NA_PROD;
}

export {
  HULK_PROVOKER_SERVICE_ENDPOINT,
  JARVIS_EXPORT_ENDPOINT,
  JARVIS_SERVICE_ENDPOINT,
  JARVIS_X_AMZN_ACCOUNT_NAME,
  INTEGRATED_APPROVALS_LINK,
};
