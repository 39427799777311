/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export const getMarketplaceName = (marketplaceID, availableMarketplaces) => {
  if (marketplaceID == null) {
    return null;
  }
  const marketplace = _.find(availableMarketplaces, { id: marketplaceID });
  // If marketplaceID is not in the list of available marketplaces,
  // marketplace will be null. Eg. marketplaceID '1' for jobs
  // submitted from backend.
  if (marketplace == null) {
    return null;
  }
  return marketplace.name;
};
