import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userProfile from './userProfile/reducer';
import marketplace from './marketplace/reducer';
import jobs from './jobs/reducer';
import newJob from './newJob/reducer';
import approvalJobDetails from "./jobs/reducer/approvalJobDetails";
import submitJobDetails from "./jobs/reducer/submitJobDetails";
import totalConcessionSuccessMessageReducer from "./newJob/totalConcession/reducer";
import planNameReducer from "./newJob/planName/reducer";

const rootReducer = combineReducers({
  form: formReducer,
  userProfile,
  marketplace,
  newJob,
  jobs,
  approvalJobDetails,
  submitJobDetails,
  totalConcessionSuccessMessageReducer,
  planNameReducer,
});

export default rootReducer;
