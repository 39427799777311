import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Button, HelpBlock } from 'react-bootstrap';
import _ from 'lodash';
import { validate as isEmailValid } from 'email-validator';
import InputField from '../../components/InputField';
import { haveFileRequirementsBeenSatisfiedSelector } from '../../redux/newJob/file/selectors';
import { changeEmailRecipientsInputValue, touchEmailRecipientsInput, sendTestEmail } from '../../redux/newJob/previewSender/email/actions';
import { invalidEmailRecipientsSelector, previewBlurbSelector } from '../../redux/newJob/previewSender/email/selectors';
import { SENDING, SENT } from '../../redux/newJob/previewSender/deliveryStatus';
import { RTN_SONAR_V2_MIGRATION } from '../../externalLinks';
import { ADDITIONAL_INFO, FOLLOW_NOOGLE_LINKS } from '../../StringConstants';

export const getBsClassForDeliveryResponseStatus = (isSuccessful) => (isSuccessful ? 'text-success' : 'text-danger');

export const getInvalidEmailsError = ({
  recipientsInputValue,
  invalidEmailRecipients,
}) => {
  if (_.isEmpty(_.trim(recipientsInputValue))) return 'Required';
  if (!_.isEmpty(invalidEmailRecipients)) return `Invalid email(s): ${invalidEmailRecipients.join(', ')}`;
  return '';
};

export function NoogleLinks({ links }) {
  return (
    <p>
      {links.map((link, index) => {
        if (link.trim() !== '') {
          return (
            <span>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {` noogle link ${index + 1} `}
              </a>
              ,
            </span>
          );
        }
      })}
    </p>
  );
}

/**
 * The function returns Delivery Status message with prefix message
 * (If all/few/none emails are successfully sent) and debugLinks for the same.
 * @param statusMessage.
 * @returns Delivery Status message.
 */
export function DeliveryMessage({ statusMessage }) {
  if (statusMessage !== null && statusMessage !== undefined && statusMessage.trim() !== '') {

    const parts = statusMessage.split(FOLLOW_NOOGLE_LINKS);
    let prefixMessage = parts[0].trim();
    if (!parts[1]) {
      return (
        <div>
          { prefixMessage }
        </div>
      );
    }
    prefixMessage = `${prefixMessage} ${FOLLOW_NOOGLE_LINKS}`;
    const noogleLinkStrings = parts[1].split(',').map((link) => link.trim());
    return (
      <div>
        {prefixMessage}
        <NoogleLinks links={noogleLinkStrings} />
        {ADDITIONAL_INFO}
        <a href={RTN_SONAR_V2_MIGRATION} target="_blank" rel="noopener noreferrer">
          here
        </a>
      </div>
    );
  }
}

export class EmailTestSender extends Component {
  constructor(props) {
    super(props);
    this.shouldDisableSendTestEmail = this.shouldDisableSendTestEmail.bind(this);
  }

  shouldDisableSendTestEmail() {
    let blurbContent;

    const blurbParameters = this.props.blurb.parameters;
    if (blurbParameters) {
      blurbContent = blurbParameters.Content;
    } else {
      blurbContent = this.props.blurb.content;
    }

    const { fromAddress } = this.props;
    const hasInvalidRecipientEmails = !_.isEmpty(this.props.invalidEmailRecipients);
    return !this.props.haveFileRequirementsBeenSatisfied
      || this.props.deliveryStatus === SENDING
      || _.isEmpty(this.props.recipientsInputValue)
      || _.isEmpty(this.props.subject)
      || _.isEmpty(blurbContent)
      || _.isEmpty(this.props.fromName)
      || _.isEmpty(fromAddress) || !isEmailValid(fromAddress)
      || hasInvalidRecipientEmails;
  }

  render() {
    const { deliveryStatus } = this.props;
    const invalidEmailsError = getInvalidEmailsError({
      recipientsInputValue: this.props.recipientsInputValue,
      invalidEmailRecipients: this.props.invalidEmailRecipients,
    });
    return (
      <div className="email-preview-sender">
        <InputField
          componentClass="textarea"
          placeholder="johndoe@amazon.com, janedoe@amazon.com"
          label="Recipients:"
          input={{
            name: 'emailRecipients',
            value: this.props.recipientsInputValue,
            onChange: (e) => this.props.changeEmailRecipientsInputValue(e.target.value),
            onBlur: () => this.props.touchEmailRecipientsInput(),
          }}
          meta={{
            touched: this.props.hasRecipientsInputBeenTouched,
            error: invalidEmailsError,
          }}
          disabled={deliveryStatus === SENDING}
        />

        {deliveryStatus === SENDING && <HelpBlock>Sending</HelpBlock>}
        {
          deliveryStatus === SENT
          && (
          <HelpBlock bsClass={getBsClassForDeliveryResponseStatus(this.props.deliveryResponse.isSuccessful)}>
            <DeliveryMessage statusMessage={this.props.deliveryResponse.statusMessage} />
          </HelpBlock>
          )
        }
        <div>
          <Button
            disabled={this.shouldDisableSendTestEmail()}
            onClick={() => this.props.sendTestEmail()}
          >
            Send test email
          </Button>
        </div>
      </div>
    );
  }
}

const requirementsFormSelector = formValueSelector('newJobRequirements');

function mapStateToProps(state) {
  return {
    ...state.newJob.previewSender.email,
    invalidEmailRecipients: invalidEmailRecipientsSelector(state),
    fromName: requirementsFormSelector(state, 'FromName'),
    fromAddress: requirementsFormSelector(state, 'FromAddress'),
    subject: requirementsFormSelector(state, 'Subject'),
    blurb: previewBlurbSelector(state),
    haveFileRequirementsBeenSatisfied: haveFileRequirementsBeenSatisfiedSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeEmailRecipientsInputValue,
    touchEmailRecipientsInput,
    sendTestEmail,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTestSender);
