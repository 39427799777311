import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DropdownInputField from '../../components/DropdownInputField';
import { selectConcessionReason } from '../../redux/newJob/concessionReasons/actions';
import {
  selectedConcessionReasonSelector,
  availableConcessionReasonsSelector,
} from '../../redux/newJob/concessionReasons/selectors';

function createConcessionReasonsDropdownOptions(concessionReason) {
  return {
    value: concessionReason.reasonCode,
    label: concessionReason.reasonDescription,
  };
}

export class ConcessionReasonsDropdown extends Component {
  render() {
    let reasonCode;
    if (this.props.selectedConcessionReason === undefined) {
      reasonCode = '';
    } else {
      reasonCode = this.props.selectedConcessionReason.reasonCode;
    }
    return (
      <DropdownInputField
        {...this.props}
        name="concessionReason"
        label="ConcessionReason"
        placeholder="Select a concession reason"
        options={this.props.availableConcessionReasons.map(createConcessionReasonsDropdownOptions)}
        value={reasonCode}
        required
        searchable
        clearable={false}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    availableConcessionReasons: availableConcessionReasonsSelector(state),
    selectedConcessionReason: selectedConcessionReasonSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onChange: selectConcessionReason }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionReasonsDropdown);
