import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';
import ApprovalsForm from './ApprovalsForm';
import DetailsForm from './DetailsForm';
import PlanForm from './PlanForm';
import RequirementsForm from './RequirementsForm';
import ReviewForm from './ReviewForm';
import { formValueSelector } from 'redux-form';
import { getPlans } from '../../redux/newJob/plans/actions';
import {submitJob, resetNewJobForms, submitApproval, updateAutoSelectedPlanId, updateCurrentlySelectedApproval } from '../../redux/newJob/actions';
import LoadingSpinner from "./LoadingSpinner";
import {totalConcessionSuccessMessageSelector, totalConcessionAmountSelector, getCurrentApprovalName } from "../../redux/newJob/totalConcession/selectors";
import {planNameSelector} from "../../redux/newJob/planName/selectors";
import {isSelectedPlanValidation} from "../../redux/newJob/plans/selectors";
import { defaultFieldsForSelectedMarketplaceSelector } from '../../redux/marketplace/selectors';
import { selectValuesFromJobReport } from '../../redux/jobs/selectors';
import { concession2_0RecipeIDS } from '../../helpers/constants';
import withRouter from '../../helpers/withRouter';
import { currencyConvertorSelector } from '../../redux/newJob/estimatedTotalConcessionInUSD/selectors';

export class NewJob extends Component {
  constructor(props) {
    super(props);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.submitApprovalJob = this.submitApprovalJob.bind(this);
    this.reviewFormNextStep = this.reviewFormNextStep.bind(this);
    this.loadingSpinnerPreviousStep = this.loadingSpinnerPreviousStep.bind(this);
    this.actionPlanName = (this.props.router && this.props.router.location && this.props.router.location.state && this.props.router.location.state.planName) || '';
    this.actionRecipeId = (this.props.router && this.props.router.location && this.props.router.location.state && this.props.router.location.state.actionRecipeId) || '';
    this.jobId = (this.props.router && this.props.router.location && this.props.router.location.state && this.props.router.location.state.jobId) || '';
    this.concessionRecipeSelected = concession2_0RecipeIDS.some(recipeId => recipeId === this.actionRecipeId);

    let step = 0;
    if (this.concessionRecipeSelected && this.props.jobReportValues) {
      step = 2;
      props.updateAutoSelectedPlanId(this.actionRecipeId);
    }
    this.state = {
      ...this.state,
      step,
    };
  }

  // componentWillMount is deprecated
  componentDidMount() {
    this.props.getPlans();
  }

  componentWillUnmount() {
    this.props.resetNewJobForms();
  }

  nextStep() {
    this.setState({ step: this.state.step + 1 });
  }

  reviewFormNextStep() {
    if(this.props.isValidationPlan && !this.props.skipValidation) {
      this.props.submitJob();
      this.setState({ step: this.state.step + 2 });
    } else {
      this.setState({step: this.state.step + 1});
    }
  }

  loadingSpinnerPreviousStep() {
    if(this.props.isValidationPlan) {
      this.setState({ step: this.state.step - 2 });
    } else {
      this.setState({step: this.state.step - 1});
    }
  }

  previousStep() {
    if (this.concessionRecipeSelected && this.state.step === 2) {
      this.props.router.navigate(-1);
    } else {
      this.setState({ step: this.state.step - 1 });
    }
  }

  submitApprovalJob() {
    this.props.submitApproval({ concessionRecipeSelected: this.concessionRecipeSelected });
    this.setState({ step: this.state.step + 1 });
  }

  render() {
    const { step } = this.state;
    const forms = [
      <DetailsForm onSubmit={this.nextStep} />,
      <PlanForm previousStep={this.previousStep} onSubmit={this.nextStep} />,
      <RequirementsForm
        previousStep={this.previousStep}
        onSubmit={this.nextStep}
        totalConcessionSuccessMessage={this.props.totalConcessionSuccessMessage}
        validatedJobId={this.props.validatedJobId}
        planName={
          this.concessionRecipeSelected
            ? this.actionPlanName
            : this.props.planName
        }
        currencyCode={this.props.currencyCode}
        concessionRecipeSelected={!!this.concessionRecipeSelected}
        validationJobId={this.jobId}
      />,
      <ReviewForm
        previousStep={this.previousStep}
        onSubmit={this.reviewFormNextStep}
        concessionRecipeSelected={!!this.concessionRecipeSelected}
      />,
      <ApprovalsForm
        previousStep={this.previousStep}
        onSubmit={this.submitApprovalJob}
        concessionRecipeSelected={!!this.concessionRecipeSelected}
        selectedMarketplaceInfo={this.props.selectedMarketplaceInfo}
        totalConcessionAmount={this.props.totalConcessionAmount !== undefined && this.props.totalConcessionAmount !== '' ? this.props.totalConcessionAmount : this.props.totalConcessionFromRequirements}
        jobReportValues={this.props.jobReportValues}
        updateCurrentlySelectedApproval={this.props.updateCurrentlySelectedApproval}
        currentApprovalName={this.props.currentApprovalName}
        totalConcessionValueinUSD={this.props.totalConcessionValueinUSD}
        login={this.props.login}
        router={this.props.router}
        jobID={this.jobId}
      />,
      <LoadingSpinner previousStep={this.loadingSpinnerPreviousStep} />,
    ];
    return (
      <div>
        <h1>New Job</h1>
        {forms[step]}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getPlans, submitJob, resetNewJobForms, submitApproval, updateAutoSelectedPlanId, updateCurrentlySelectedApproval }, dispatch);
}

function mapStateToProps(state) {
  const reviewFormSelector = formValueSelector('newJobReview');
  const requirementsFormSelector = formValueSelector('newJobRequirements');
  return {
    isValidationPlan : isSelectedPlanValidation(state),
    totalConcessionSuccessMessage : totalConcessionSuccessMessageSelector(state),
    totalConcessionAmount : totalConcessionAmountSelector(state),
    planName : planNameSelector(state),
    currencyCode: defaultFieldsForSelectedMarketplaceSelector(state).CurrencyCode,
    selectedMarketplaceInfo: defaultFieldsForSelectedMarketplaceSelector(state),
    jobReportValues: selectValuesFromJobReport(state),
    skipValidation: reviewFormSelector(state, 'skipValidation'),
    totalConcessionFromRequirements: requirementsFormSelector(state, 'EstimatedTotalConcession'),
    currentApprovalName: getCurrentApprovalName(state),
    totalConcessionValueinUSD: currencyConvertorSelector(state),
    login: state.userProfile.login,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewJob));
