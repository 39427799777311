import {
  EMAIL_RECIPIENTS_INPUT_VALUE_CHANGED,
  EMAIL_RECIPIENTS_INPUT_TOUCHED,
  SEND_TEST_EMAIL_PENDING,
  SEND_TEST_EMAIL_FULFILLED,
  SEND_TEST_EMAIL_REJECTED,
} from './actions';
import { BLURB_PREVIEW_LANGUAGE_SELECTED } from '../../blurb/loaded/actions';
import { RESET_REQUIREMENTS_FORM } from '../../actions';
import { NOT_SENT, SENDING, SENT } from '../deliveryStatus';

export const initialState = {
  recipientsInputValue: '',
  hasRecipientsInputBeenTouched: false,
  deliveryStatus: NOT_SENT,
  deliveryResponse: {},
};

export default function email(state = initialState, action) {
  switch (action.type) {
    case EMAIL_RECIPIENTS_INPUT_VALUE_CHANGED:
      return {
        ...state,
        recipientsInputValue: action.value,
        deliveryStatus: NOT_SENT,
        deliveryResponse: {},
      };
    case EMAIL_RECIPIENTS_INPUT_TOUCHED:
      return {
        ...state,
        hasRecipientsInputBeenTouched: true,
      };
    case SEND_TEST_EMAIL_PENDING:
      return {
        ...state,
        deliveryStatus: SENDING,
        deliveryResponse: {},
      };
    case SEND_TEST_EMAIL_FULFILLED:
      return {
        ...state,
        deliveryStatus: SENT,
        deliveryResponse: action.payload.data,
      };
    case SEND_TEST_EMAIL_REJECTED:
      return {
        ...state,
        deliveryStatus: NOT_SENT,
        deliveryResponse: {},
      };
    case BLURB_PREVIEW_LANGUAGE_SELECTED:
      return {
        ...state,
        deliveryStatus: NOT_SENT,
        deliveryResponse: {},
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
