import moment from 'moment';
/* eslint-disable import/prefer-default-export */

export const getHumanFriendlyDateTime = (inputDate) => {
  const dateTime = new Date(inputDate);
  if(!dateTime) {
    return "";
  }

  if (moment(dateTime) > moment().startOf('day')) {
    return moment(dateTime).fromNow();
  }

  if (moment(dateTime) > moment().startOf('week')) {
    return moment(dateTime).calendar();
  }

  return moment(dateTime).format('LLL');
};
