import { GET_PLANS_FULFILLED, mapApiResponse } from './actions';

export default function plans(state = [], action) {
  switch (action.type) {
    case GET_PLANS_FULFILLED:
      return mapApiResponse(action.payload.data).plans;
    default:
      return state;
  }
}
