import React, { useState } from 'react';
import {
  Alert,
} from 'AWS-UI-Components-React';

export function Modal(props) {
  const { modalMessage, modalTitle, buttonString, router, mainJobId } = props;
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => {
    setIsOpen(false);
    const jobDetailsUrl = `/jobs/${mainJobId}`;
    router.navigate(jobDetailsUrl);
  };
  return (
    <div>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <Alert type="warning">
              <h2>{modalTitle}</h2>
              <p>{modalMessage}</p>
              <button onClick={handleClick}>{buttonString}</button>
            </Alert>
          </div>
        </div>
      )}
    </div>
  );
}
