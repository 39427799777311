import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormControl, HelpBlock, Panel } from 'react-bootstrap';
import { selectAndParseFile } from '../../../redux/newJob/file/actions';
import { selectedFileErrorMessageSelector } from '../../../redux/newJob/file/selectors';

export const LibreOfficeInstallationHyperlink = (<a href="https://w.amazon.com/bin/view/CSTech/Heartbeat/Resolution/HULK/ProjectTracker/HULK_User_Manual/SpecialCharactersinMassMail/#HAlternativeSolution-LibreOffice" target="_blank">LibreOffice</a>);

export class FileChooser extends Component {
  constructor(props) {
    super(props);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleFileChange(event) {
    const file = _.get(event, 'target.files[0]', null);
    this.props.selectAndParseFile(file);
  }

  render() {
    const errors = this.props.selectedFileErrorMessage;
    const { file } = this.props;
    let size = null;
    if (file.parsedContent != null && file.parsedContent.metadata != null) {
      size = file.parsedContent.metadata.size;
    }
    return (
      <Panel>
        <Panel.Body>
          <HelpBlock>Excel doesn't support non-English characters well. Please use {LibreOfficeInstallationHyperlink} to create job files with any such characters.</HelpBlock>
          <HelpBlock>Please do not add any Unique Customer Information (UCI) including but not limited to Name, Email addresses,
            Phone numbers and physical address as part of CSV File Record. This information will potentially be seen by HULK Users,
            sellers, vendors or anyone pulling data.</HelpBlock>
          <FormControl
            type="file"
            name="jobFile"
            onChange={this.handleFileChange}
          />
          {!!size && (
              <b>
                <br />
                Number of Records in the File :
                {' '}
                {size}
              </b>
          )}
          {this.props.file.touched && !!errors && <HelpBlock>{errors}</HelpBlock>}
        </Panel.Body>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFileErrorMessage: selectedFileErrorMessageSelector(state),
    file: state.newJob.file,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selectAndParseFile }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FileChooser);
