import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

import './RequiredFieldIndicator.scss';

export default class InputField extends Component {
  render() {
    const {
      input,
      placeholder,
      label,
      type,
      autoFocus,
      totalConcessionSuccessMessage,
      meta: { touched, error, asyncValidating },
      required,
      disabled,
      componentClass,
      maxLength,
      validatedJobId,
      isFetching,
    } = this.props;

    const bsClass = classNames({
      'form-group': true,
      required,
    });

    const shouldShowValidationState = (touched && !asyncValidating && !isFetching);
    const validationStateName = error ? 'error' : 'success';
    const validationState = shouldShowValidationState ? validationStateName : null;
    return (
      <FormGroup bsClass={bsClass} controlId={input.name} validationState={validationState}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          componentClass={componentClass}
          type={type}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
        <FormControl.Feedback />
        {touched && error && !asyncValidating && <HelpBlock>{error}</HelpBlock>}
        {touched && asyncValidating && label !== 'JobID' && <HelpBlock>Validating...</HelpBlock>}
        {touched && (isFetching || asyncValidating) && label === 'JobID' && <HelpBlock>Retrieving Concession amount and job details, this may take up to a minute...</HelpBlock>}
        {touched
            && !error
            && !asyncValidating
            && !isFetching
            && label === 'JobID'
            && validatedJobId === input.value
            && <HelpBlock>{totalConcessionSuccessMessage}</HelpBlock>}
      </FormGroup>
    );
  }
}

InputField.propTypes = {
  totalConcessionSuccessMessage: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  componentClass: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    asyncValidating: PropTypes.bool,
  }),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
};

InputField.defaultProps = {
  disabled: false,
  required: false,
  componentClass: 'input',
  placeholder: '',
  label: '',
  type: 'text',
  autoFocus: false,
  meta: {
    touched: false,
    error: null,
    asyncValidating: false,
  },
  isFetching: false,
};
