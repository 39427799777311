import { makeHulkProvokerServiceRequest } from '../../../../helpers/requestHelpers';
import { isSelectedPlanEmailDependentSelector } from '../../plans/selectors';
import { selectedMarketplaceSelector } from '../../../marketplace/selectors';

export const BLURB_NAME_CHANGED = 'BLURB_NAME_CHANGED';

export const GET_BLURBS = 'GET_BLURBS'; // will produce the following actions:
export const GET_BLURBS_PENDING = 'GET_BLURBS_PENDING';
export const GET_BLURBS_FULFILLED = 'GET_BLURBS_FULFILLED';
export const GET_BLURBS_REJECTED = 'GET_BLURBS_REJECTED';

export function changeBlurbName(value) {
  return {
    type: BLURB_NAME_CHANGED,
    value,
  };
}

export function generateGetBlurbsURL(name, marketplaceId) {
  return `/presentation/blurbs/${encodeURIComponent(encodeURIComponent(name))}?marketplace-id=${marketplaceId}`;
}

export function getBlurbs(name) {
  return (dispatch, getState) => {
    const state = getState();
    const isPlanEmailDependent = isSelectedPlanEmailDependentSelector(state);
    const marketplaceId = selectedMarketplaceSelector(state).id;
    const requestOptions = {
      url: generateGetBlurbsURL(name, marketplaceId),
    };

    dispatch({
      type: GET_BLURBS,
      payload: {
        promise: makeHulkProvokerServiceRequest(requestOptions),
      },
      meta: {
        isPlanEmailDependent,
      },
    });
  };
}
