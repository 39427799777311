export const HTML_BLURB_NAME_CHANGED = 'HTML_BLURB_NAME_CHANGED';
export const LOAD_HTML_BLURB_PENDING = 'LOAD_HTML_BLURB_PENDING';
export const LOAD_HTML_BLURB_FULFILLED = 'LOAD_HTML_BLURB_FULFILLED';
export const LOAD_HTML_BLURB_REJECTED = 'LOAD_HTML_BLURB_REJECTED';

export function changeHtmlBlurbName(value) {
  return {
    type: HTML_BLURB_NAME_CHANGED,
    value,
  };
}

// TODO: replace with call to backend once it's available.
// The following actions will be tested once the backend is ready.
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const mockedBlurbContent = `Hello,

We're contacting you about order #%{OrderID} for the following item(s):

%{AsinTitle}

We recently learned about a problem that prevented us from processing your order properly. Unfortunately, we had to cancel it. You have not been charged. 

We're sorry for any disappointment or inconvenience this may have caused. 

We appreciate your business and hope to see you again soon.

Sincerely,

Customer Service
Amazon.com

Please note: this e-mail was sent from a notification-only address that cannot accept incoming e-mail. Please do not reply to this message.`;

const mockHtmlLoadBlurbByName = name => new Promise((resolve, reject) => {
  sleep(1000).then(() => {
    if (name === 'bp_cancel_order_problem') {
      resolve({
        data: {
          id: 'AG7AJXR8S7YQA',
          name: 'bp_cancel_order_problem',
          contents: mockedBlurbContent,
        },
      });
    } else {
      reject({
        error: 'HTML template not found',
      });
    }
  });
});

export function loadHtmlBlurbByNamePending() {
  return {
    type: LOAD_HTML_BLURB_PENDING,
  };
}

export function loadHtmlBlurbByNameFulfilled(payload) {
  return {
    type: LOAD_HTML_BLURB_FULFILLED,
    payload,
  };
}

export function loadHtmlBlurbByNameRejected(error) {
  return {
    type: LOAD_HTML_BLURB_REJECTED,
    error,
  };
}

export function loadHtmlBlurbByName(name) {
  return (dispatch) => {
    dispatch(loadHtmlBlurbByNamePending());
    mockHtmlLoadBlurbByName(name).then(
      payload => dispatch(loadHtmlBlurbByNameFulfilled(payload)),
    ).catch(
      result => dispatch(loadHtmlBlurbByNameRejected(result.error)),
    );
  };
}
