import React from 'react';

import './RequiredFieldIndicator.scss';

const RequiredFieldMessage = () => (
  <div>
    <i className="fa fa-asterisk required-field-indicator" aria-hidden="true" /> Required field
  </div>
);

export default RequiredFieldMessage;
