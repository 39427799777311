/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectedMarketplaceSelector = createSelector(
  state => state.marketplace,
  ({ availableMarketplaces, selectedMarketplaceId }) =>
    _.find(availableMarketplaces, { id: selectedMarketplaceId }),
);

export const defaultFieldsForSelectedMarketplaceSelector = createSelector(
  state => state.marketplace,
  ({ defaultFieldsForMarketplaces, selectedMarketplaceId }) =>
    _.find(defaultFieldsForMarketplaces, { id: selectedMarketplaceId }),
);

export const availableMarketplacesSelector = createSelector(
  state => state.marketplace,
  ({ availableMarketplaces }) => availableMarketplaces,
);
