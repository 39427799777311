import { formValueSelector, reset } from 'redux-form';
import _ from 'lodash';
import { makeHulkProvokerServiceRequest } from '../../helpers/requestHelpers';
import { splitByCommaTrimAndCompact } from '../../helpers/stringHelpers';
import { formatPreSignedUrlForJobSubmission } from './file/helpers';
import {
  selectedPlanSelector,
  planJobRequirementsSelector,
  isSelectedPlanBlurbDependentSelector,
  isSelectedPlanHtmlBlurbDependentSelector,
  isSelectedPlanMobilePushDependentSelector,
  isSelectedPlanConcessionReasonDependentSelector,
  isSelectedPlanValidation,
} from '../../redux/newJob/plans/selectors';
import { selectValuesFromJobReport } from '../../redux/jobs/selectors';
import { planIdForSkipValidation } from '../../helpers/constants';
import { currencyConvertorSelector } from './estimatedTotalConcessionInUSD/selectors';

const SUBMIT_APPROVAL = 'SUBMIT_APPROVAL';
export const SUBMIT_APPROVAL_PENDING = 'SUBMIT_APPROVAL_PENDING';
export const SUBMIT_APPROVAL_FULFILLED = 'SUBMIT_APPROVAL_FULFILLED';
export const SUBMIT_APPROVAL_REJECTED = 'SUBMIT_APPROVAL_REJECTED';

const SUBMIT_JOB = 'SUBMIT_JOB';
export const SUBMIT_JOB_PENDING = 'SUBMIT_JOB_PENDING';
export const SUBMIT_JOB_FULFILLED = 'SUBMIT_JOB_FULFILLED';
export const SUBMIT_JOB_REJECTED = 'SUBMIT_JOB_REJECTED';

export const RESET_REQUIREMENTS_FORM = 'RESET_REQUIREMENTS_FORM';
export const RESET_TOTAL_CONCESSION = 'RESET_TOTAL_CONCESSION';
export const UPDATE_SELECT_PLAN_ID = 'UPDATE_SELECT_PLAN_ID';
export const UPDATE_CURRENTLY_SELECTED_APPROVAL = 'UPDATE_CURRENTLY_SELECTED_APPROVAL';

/**
 * Creates an object that may contain one or more properties
 * where the value is the logged in user alias
 * and the key name depending on the plan.
 * This is necessary until Backend implements the following SIMs:
 * https://issues.amazon.com/issues/HB-Hulk-467
 * https://issues.amazon.com/issues/HB-Hulk-453
 */
const getUserNameParameterObject = (state) => {
  const userNameParameter = {};

  const login = state.userProfile.login;
  const requirementNames = planJobRequirementsSelector(state).map(r => r.name);

  if (_.includes(requirementNames, 'UserName')) {
    userNameParameter.UserName = login;
  }

  if (_.includes(requirementNames, 'User')) {
    userNameParameter.User = login;
  }

  return userNameParameter;
};

export const getConcessionReasonParameterObject = (state) => {
  const concessionReasonParameter = {};

  const concessionReasonCode = state.newJob.concessionReasons.selectedConcessionReasonCode;
  const requirementNames = planJobRequirementsSelector(state).map(r => r.name);

  if (isSelectedPlanConcessionReasonDependentSelector(state)) {
    if (_.includes(requirementNames, 'Reason')) {
      concessionReasonParameter.Reason = concessionReasonCode;
    }

    if (_.includes(requirementNames, 'ReversalReason')) {
      concessionReasonParameter.ReversalReason = concessionReasonCode;
    }

    if (_.includes(requirementNames, 'ReduceShipChargeReason')) {
      concessionReasonParameter.ReduceShipChargeReason = concessionReasonCode;
    }
  }

  return concessionReasonParameter;
};

export const getBlurbParameterObject = (state) => {
  const blurbParameter = {};

  if (isSelectedPlanBlurbDependentSelector(state)) {
    blurbParameter.BlurbID = state.newJob.blurb.loaded.blurbs[0].blurbID;
    blurbParameter.BlurbName = state.newJob.blurb.loaded.blurbs[0].name;
  }

  return blurbParameter;
};

export const getHtmlTemplateIDParameterObject = (state) => {
  const htmlTemplateIdParameter = {};

  if (isSelectedPlanHtmlBlurbDependentSelector(state)) {
    htmlTemplateIdParameter.HtmlTemplateID = state.newJob.htmlBlurb.loaded.id;
  }

  return htmlTemplateIdParameter;
};

export const getMobilePushTextParameterObject = (state) => {
  const mobilePushTextParameter = {};

  const requirementsFormSelector = formValueSelector('newJobRequirements');
  const shouldSendMobilePushText = isSelectedPlanMobilePushDependentSelector(state)
    && requirementsFormSelector(state, 'mobilePush');

  if (shouldSendMobilePushText) {
    mobilePushTextParameter.MobilePushText = requirementsFormSelector(state, 'MobilePushText');
  }

  return mobilePushTextParameter;
};

export const mapSubmitJobPayload = (state, concessionRecipeSelected) => {
  const detailsFormSelector = formValueSelector('newJobDetails');
  const detailsFormValues = concessionRecipeSelected ? selectValuesFromJobReport(state) : detailsFormSelector(state, 'jobName', 'jobDescription', 'simttID', 'tags');
  const isValidationPlan = isSelectedPlanValidation(state);
  const plan = selectedPlanSelector(state);
  const inputTags = (detailsFormValues && detailsFormValues.tags) || '';
  const newJobReviewValues = state.form && state.form.newJobReview && state.form.newJobReview.values;
  const skipValidation = (newJobReviewValues && newJobReviewValues.skipValidation) || false;
  const skipValidationDescription = (newJobReviewValues && newJobReviewValues.skipValidationDescription) || '';

  return {
    jobSubmitter: {
      login: state.userProfile.login,
    },
    jobResourceIdentifier: {
      uri: formatPreSignedUrlForJobSubmission(state.newJob.file.upload.url),
    },
    jobMetadata: {
      name: isValidationPlan && !skipValidation ? `${detailsFormValues.jobName}_validation` : detailsFormValues.jobName,
      description: detailsFormValues.jobDescription,
      simttId: detailsFormValues.simttID,
    },
    jobParameters: {
      parameters: {
        ...state.form.newJobRequirements.values,
        ...getUserNameParameterObject(state),
        ...getBlurbParameterObject(state),
        ...getHtmlTemplateIDParameterObject(state),
        ...getMobilePushTextParameterObject(state),
        ...getConcessionReasonParameterObject(state),
        skipValidation,
        skipValidationDescription,
        MarketplaceID: state.marketplace.selectedMarketplaceId,
      },
    },
    tags: splitByCommaTrimAndCompact(inputTags).map(tag => ({val: tag})),
    actionRecipeId: {
      val: skipValidation && planIdForSkipValidation[plan.id] ? planIdForSkipValidation[plan.id] : plan.id,
    },
  };
}

export const mapApprovalJobPayload = (state, concessionRecipeSelected) => {
  const approvalsFormSelector = formValueSelector('newJobApprovals');
  const approvalsFormValues = approvalsFormSelector(state, 'userAlias', 'approverAlias', 'requestorAlias', 'businessUserAlias', 'financeUserAlias', 'estimatedTotalConcessions');
  const estimatedConcessionsValue = currencyConvertorSelector(state);

  return {
    startJobParameters: mapSubmitJobPayload(state, concessionRecipeSelected),
    approvalParameters: {
      alias: approvalsFormValues.userAlias,
      approverAlias: approvalsFormValues.approverAlias,
      requestorAlias: approvalsFormValues.requestorAlias,
      businessUserAlias: approvalsFormValues.businessUserAlias,
      financeUserAlias: approvalsFormValues.financeUserAlias,
      estimatedConcessionsValue,
    },
  };
};

export function submitApproval({ concessionRecipeSelected }) {
  return (dispatch, getState) => {
    const requestOption = {
      url: '/provoker/approval',
      method: 'post',
      data: mapApprovalJobPayload(getState(), concessionRecipeSelected),
    };

    dispatch({
      type: SUBMIT_APPROVAL,
      payload: {
        promise: makeHulkProvokerServiceRequest(requestOption)
      },
    });
  };
}

export function submitJob() {
  return (dispatch, getState) => {
    const requestOption = {
      url: '/provoker/job',
      method: 'post',
      data: mapSubmitJobPayload(getState()),
    };

    dispatch({
      type: SUBMIT_JOB,
      payload: {
        promise: makeHulkProvokerServiceRequest(requestOption)
      },
    });
  };
}

export const resetRequirementsForm = () => ((dispatch) => {
  dispatch(reset('newJobRequirements'));
  dispatch({
    type: RESET_REQUIREMENTS_FORM,
  });
});

export const resetNewJobForms = () => ((dispatch) => {
  dispatch(reset('newJobDetails'));
  dispatch(reset('newJobPlan'));
  dispatch(resetRequirementsForm());
  dispatch(reset('newJobReview'));
  dispatch(reset('newJobApprovals'));
  // Resetting the total concession amount to prevent seeing the same value populated for different plans
  dispatch({
    type: RESET_TOTAL_CONCESSION,
  });
});


export const updateAutoSelectedPlanId = (planId) => {
  return {
    type: UPDATE_SELECT_PLAN_ID,
    selectedPlanId: planId,
  };
};


export const updateCurrentlySelectedApproval = (approvalName) => {
  return {
    type: UPDATE_CURRENTLY_SELECTED_APPROVAL,
    currentApprovalName: approvalName,
  };
};