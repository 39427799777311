import { SEARCH_JOBS_FULFILLED, SEARCH_JOBS_PENDING, SEARCH_QUERY_CHANGED } from '../actions';
import { loadStatus } from '../../../components/NewJob/LoaderInputField';
import { INITIAL_CURSOR } from '../jarvisConstants';

export const initialState = {
  cursor: INITIAL_CURSOR,
  search_query: '',
  loading_status: loadStatus.NOT_LOADED,
};

export default function searchParams(state = initialState, action) {
  switch (action.type) {
    case SEARCH_JOBS_PENDING:
      return {
        ...state,
        loading_status: loadStatus.LOADING,
      };
    case SEARCH_JOBS_FULFILLED:
      return {
        ...state,
        cursor: action.payload.data.response._scroll_id,
        loading_status: loadStatus.LOADED,
      };
    case SEARCH_QUERY_CHANGED:
      return {
        ...state,
        cursor: INITIAL_CURSOR,
        search_query: action.newSearchQuery,
        loading_status: loadStatus.NOT_LOADED,
      };
    default:
      return state;
  }
}
