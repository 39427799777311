import React, { Component } from 'react';
import HorizontalDescriptionListGroup from '../../../components/HorizontalDescriptionListGroup';

const currencyStyle = {
  padding: '0 12px 6px',
};
export class AdditionalJobDetails extends Component {
  render() {
    const {estimatedTotalConcession, isEstimatedTotalConcessionValue, currencyCode } = this.props;
    return (
      <HorizontalDescriptionListGroup header="Additional Job Details">
          <div style={currencyStyle}>

              {isEstimatedTotalConcessionValue &&
                  <b>Estimated Total Concession: </b>
              }
              {!isEstimatedTotalConcessionValue &&
              <b>Validated Total Concession: </b>
              }

              {Number(estimatedTotalConcession).toFixed(2)}
          </div>
        <div style={currencyStyle}>
          <b>Currency: </b>
          {currencyCode}
        </div>
      </HorizontalDescriptionListGroup>
    );
  }
}
