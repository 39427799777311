import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

class PreviousButton extends Component {
  render() {
    return (
      <Button onClick={this.props.onClick} disabled={this.props.disabled}>Previous</Button>
    );
  }
}

PreviousButton.defaultProps = {
  disabled: false,
};

PreviousButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PreviousButton;
