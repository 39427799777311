import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserProfile } from '../redux/userProfile/actions';
import Header from '../components/Header';
import HelpMenu from '../components/Header/HelpMenu';
import UserProfileAvatarContainer from './UserProfileAvatarContainer';
import MarketplaceMenu from './MarketplaceMenu';

export class AppContainer extends Component {
  // componentWillMount is deprecated
  componentDidMount() {
    this.props.getUserProfile();
  }

  render() {
    return (
      <div className="awsui">
        <Header>
          <MarketplaceMenu />
          <UserProfileAvatarContainer />
          <HelpMenu />
        </Header>
        <div className="container" style={{ marginBottom: '15px' }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

AppContainer.propTypes = {
  children: PropTypes.node,
  getUserProfile: PropTypes.func.isRequired,
};

AppContainer.defaultProps = {
  children: [],
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUserProfile }, dispatch);
}

export default connect(null, mapDispatchToProps)(AppContainer);
