import _ from 'lodash';
import { createSelector } from 'reselect';
import { loadStatus } from '../../../../components/NewJob/LoaderInputField';
import { blurbLoadingStatusSelector } from '../loader/selectors';

export const blurbsWithoutMandatoryParametersSelector = createSelector(
  state => state.newJob.blurb.loaded,
  ({ blurbs }) => {
    const blurbsWithoutMandatoryParameters = [];
    _.forEach(blurbs, (blurb) => {
      // Either all or no parameters will be present.
      if (!blurb.parameters) {
        blurbsWithoutMandatoryParameters.push(blurb);
      }
    });
    return blurbsWithoutMandatoryParameters;
  },
);

export const loadedBlurbNameSelector = createSelector(
  blurbLoadingStatusSelector,
  state => state.newJob.blurb.loaded,
  (blurbLoadStatus, { blurbs }) => {
    if (blurbLoadStatus === loadStatus.LOADED) {
      return blurbs[0].name;
    }
    return '';
  },
);

export const availableBlurbsSelector = createSelector(
  state => state.newJob.blurb.loaded,
  ({ blurbs }) => blurbs,
);

export const availableBlurbLanguagesSelector = createSelector(
  state => state.newJob.blurb.loaded,
  ({ blurbs }) => blurbs.map(blurb => blurb.language),
);

export const selectedBlurbPreviewLanguageSelector = createSelector(
  state => state.newJob.blurb.loaded,
  ({ selectedBlurbPreviewLanguage }) => selectedBlurbPreviewLanguage,
);
