import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import HorizontalDescriptionListGroup from '../../../components/HorizontalDescriptionListGroup';
import DescriptionListGroupItem from '../../../components/DescriptionListGroupItem';
import { selectValuesFromJobReport } from '../../../redux/jobs/selectors.js';

export class DetailsReview extends Component {
  render() {
    const { jobName, jobDescription, simttID, tags } = this.props.detailsFormValues;
    return (
      <HorizontalDescriptionListGroup header="Details">
        <DescriptionListGroupItem dt="Job Name" dd={this.props.isValidationPlan ? `${jobName}_validation` : jobName} />
        <DescriptionListGroupItem dt="Job Description" dd={jobDescription} />
        <DescriptionListGroupItem dt="SIM/TT ID" dd={simttID} />
        <DescriptionListGroupItem dt="Tags" dd={tags} />
      </HorizontalDescriptionListGroup>
    );
  }
}

const detailsFormSelector = formValueSelector('newJobDetails');

function mapStateToProps(state, props) {
  return {
    detailsFormValues: props.concessionRecipeSelected ? selectValuesFromJobReport(state) : detailsFormSelector(state, 'jobName', 'jobDescription', 'simttID', 'tags'),
  };
}

export default connect(mapStateToProps)(DetailsReview);
