/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppContainer from './containers/AppContainer';
import Jobs from './containers/Jobs/';
import JobDetails from './containers/JobDetails';
import NewJob from './containers/NewJob';

const jobDetailsPath = 'jobs/:jobId';

const routes = (
  <AppContainer>
    <Routes>
      <Route
        path="jobs"
        element={<Jobs />}
      />
      <Route
        path="jobs/new"
        element={<NewJob />}
      />
      <Route
        path={jobDetailsPath}
        element={<JobDetails />}
      />
      <Route
        index
        element={<Jobs />}
      />
    </Routes>
  </AppContainer>
);

export default routes;

export const getJobApprovalDetailsPathForJob = (jobId) => {
  const jobApprovalDetailsPath = `/${jobDetailsPath}`;
  return jobApprovalDetailsPath.replace(':jobId', jobId);
};

export const getSkipValidationPath = ({ jobId, planName, actionRecipeId }) => `/jobs/new?actionPlanName=${planName}&jobId=${jobId}&actionRecipeId=${actionRecipeId}`;
