import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose, defaultProps } from 'recompose';
import { Form, Popover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import NextButton from '../../components/NextButton';
import InputField from '../../components/InputField';
import RequiredFieldMessage from '../../components/RequiredFieldMessage';
import { requiredFieldValidation } from './fieldValidations';
import detailsFormAsyncValidation from './asyncValidate';
import { Alert } from 'AWS-UI-Components-React';

const TAGS_FIELD_MAX_LENGTH = "240";

const tagsPopover = (
  <Popover id="tags-popover">
    Relevant meta keywords to associate your job with.
    Can be utilized for searching, filtering, and metrics.
    (maximum {TAGS_FIELD_MAX_LENGTH} characters allowed for all the tags together)
  </Popover>
);

const tagsLabel = (
  <OverlayTrigger rootClose overlay={tagsPopover}>
    <span>Tags <i className="fa fa-question-circle" aria-hidden="true" /></span>
  </OverlayTrigger>
);

const ticketPopover = (
  <Popover id="ticket-popover">
    To validate an e-ticket, add access to the ticket for <strong> flx-hulk-ui </strong> user.
    <a href="https://w.amazon.com/bin/view/WWDE/HULK/UserManual/#HAddingpermissionine-ticketforflx-hulk-ui" target="_blank"> Read more. </a> 
  </Popover>
);

const ticketLabel = (
  <OverlayTrigger delayHide={ 4000 } rootClose overlay={ticketPopover}>
    <span>SIM/TT ID <i className="fa fa-question-circle" aria-hidden="true" /> </span>
  </OverlayTrigger>
);

export const DetailsForm = (props) => {
  const { handleSubmit, pristine, submitting, invalid, asyncValidating } = props;
  const isNextButtonDisabled = pristine || submitting || invalid || !!asyncValidating;
  return (
    <Form>
      {/* TODO: remove Details heading when non linear wizard stepper is implemented.
          https://issues.amazon.com/issues/HEARTBEAT-2697
      */}
      <h2>Details</h2>
      <Field
        autoFocus
        name="jobName"
        label="Job Name"
        placeholder="My Hulk Job"
        component={InputField}
        validate={requiredFieldValidation}
        required
      />
      <Field
        name="jobDescription"
        label="Job Description"
        placeholder="This job does X to Y for all Z"
        component={InputField}
        componentClass="textarea"
      />
      <Field
        name="simttID"
        label={ticketLabel}
        placeholder="0123456789"
        component={InputField}
        validate={requiredFieldValidation}
        required
      />
      <Field
        name="tags"
        label={tagsLabel}
        placeholder="kindle, echo"
        component={InputField}
        componentClass="textarea"
        maxLength={TAGS_FIELD_MAX_LENGTH}
      />
      <div>
        <RequiredFieldMessage />
        <NextButton disabled={isNextButtonDisabled} onClick={handleSubmit}/>
      </div>
    </Form>
  );
};

DetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  asyncValidating: PropTypes.boolean,
  invalid: PropTypes.boolean,
  submitting: PropTypes.bool.isRequired,
};

DetailsForm.defaultProps = {
  asyncValidating: true,
  invalid: false,
};

// Using compose and defaultProps to make react-hot-loader 1.3.0 work with redux-form.
// And adding displayName for unit testing.
const connectedComponent = compose(
  defaultProps({}),
  reduxForm({
    asyncValidate: detailsFormAsyncValidation,
    form: 'newJobDetails',
    destroyOnUnmount: false,
    asyncBlurFields: ['simttID'],
  }),
)(DetailsForm);

connectedComponent.displayName = 'Connect(Form(DetailsForm))';

export default connectedComponent;
