import React, { Component , useState } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, defaultProps } from 'recompose';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Alert } from 'AWS-UI-Components-React';
import ApproverDetails from './ApproverDetails';
import { AdditionalJobDetails } from './AdditionalJobDetails';
import NextButton from '../../../components/NextButton';
import PreviousButton from '../../../components/PreviousButton';
import { HULK_ON_CALL_URL } from '../../../externalLinks';
import { getEstimatedTotalConcessionInUSD } from '../../../redux/newJob/estimatedTotalConcessionInUSD/actions';
import { currencyConvertorSelector } from '../../../redux/newJob/estimatedTotalConcessionInUSD/selectors';
import { infoMessages, jobApproverFields } from '../../../helpers/constants';
import { approverAliasAsyncValidation, shouldAsyncValidateApproverAlias } from '../asyncValidate';
import {
  isMainJobAlreadyCreated,
  getMainJobId,
  getMainJobApprovalSummary
} from '../../../helpers/mainJobHelpers';
import { Modal } from './Modal';
import './ApprovalForm.scss';
import {selectValuesFromJobReport} from "../../../redux/jobs/selectors";
import {totalConcessionAmountSelector} from "../../../redux/newJob/totalConcession/selectors";
import { selectedMarketplaceSelector, defaultFieldsForSelectedMarketplaceSelector } from '../../../redux/marketplace/selectors';

export function AlertContent() {
  return (
    <div>
      HULK jobs now require an approval form a secondary user. Please follow the
      steps below. Hulk will not submit any job without an approval. For more
      information see the Hulk wiki. In case of emergency, please
      {' '}
      <a href={HULK_ON_CALL_URL} target="_blank" rel="noopener noreferrer">
        page HULK oncall
      </a>
      .
    </div>
  );
}

export class ApprovalsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      mainJobId: null,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.props.getEstimatedTotalConcessionInUSD();
    const mainJobId = getMainJobId(this.props.jobID);
    this.setState({mainJobId: mainJobId});
  }

  handleClick() {
    getMainJobApprovalSummary(this.state.mainJobId).then((response) => {
      const mainJobApprovalStatus = response.data.approvalStatus;
      if (mainJobApprovalStatus
        && isMainJobAlreadyCreated(mainJobApprovalStatus)) {
        this.setState({ showModal: true });
      } else {
        this.props.handleSubmit();
      }
    });
  }

    getApproverDetails() {
      const { concessionRecipeSelected, updateCurrentlySelectedApproval } = this.props;

      let approverDetails = (
        <ApproverDetails
          header="Approver Details"
          infoMessage={infoMessages.userAlias}
          name="userAlias"
          updateCurrentlySelectedApproval={updateCurrentlySelectedApproval}
        />
      );

      if (concessionRecipeSelected) {
        approverDetails = jobApproverFields.map((approver) => {
          const {
            title, infoMessage, name,
          } = approver;
          return (
            <ApproverDetails
              header={title}
              infoMessage={infoMessage}
              name={name}
              updateCurrentlySelectedApproval={updateCurrentlySelectedApproval}
            />
          );
        });
      }
      return approverDetails;
    }

    render() {
        const { pristine, submitSucceeded, previousStep, invalid, estimatedTotalConcessionInUSD,
            concessionRecipeSelected, estimatedTotalConcessionValue, validatedTotalConcessionValue,
            defaultFieldsForSelectedMarketplace, marketplace } = this.props;
        const isEstimatedTotalConcessionValue = (this.props.skipValidation) || (estimatedTotalConcessionValue > validatedTotalConcessionValue);
        const estimatedTotalConcessionValueForApproval = (this.props.skipValidation) ? this.props.estimatedTotalConcessionFromUser : estimatedTotalConcessionInUSD;
        const currencyCodeForEstimatedTotalConcessionsValueApproval = (this.props.skipValidation) ? this.props.defaultFieldsForSelectedMarketplace.CurrencyCode : 'USD';
        const isInvalidMarketplace = (marketplace == null || marketplace.id == 'default');
        return (
            <Form>
                <Alert
                    content={<AlertContent />}
                    type="warning"
                />
                {
                  this.state.showModal && (
                    <Modal
                      showModal={this.state.showModal}
                      router={this.props.router}
                      modalTitle="Job Already Submitted"
                      modalMessage="The job you are trying to submit has already been submitted. You can view the job details of already submitted job."
                      buttonString="View job details"
                      mainJobId={this.state.mainJobId}
                    />
                  )
                }
                <h2>Approvals</h2>
                {this.getApproverDetails()}
                <AdditionalJobDetails
                  estimatedTotalConcession = {estimatedTotalConcessionValueForApproval}
                  isEstimatedTotalConcessionValue = {isEstimatedTotalConcessionValue}
                  currencyCode = {currencyCodeForEstimatedTotalConcessionsValueApproval}
                />
                <div>
                    <PreviousButton onClick={previousStep} />
                    <NextButton
                        disabled={concessionRecipeSelected ? invalid : (pristine || submitSucceeded || isInvalidMarketplace || invalid)}
                        label="Submit Job"
                        onClick={this.handleClick}
                      />
                </div>
            </Form>
        );
    }
}
function mapStateToProps(state) {
  const reviewFormSelector = formValueSelector('newJobReview');
  const requirementsFormSelector = formValueSelector('newJobRequirements');
  return {
    estimatedTotalConcessionInUSD: currencyConvertorSelector(state),
    estimatedTotalConcessionValue: selectValuesFromJobReport(state).estimatedTotalConcession,
    validatedTotalConcessionValue: totalConcessionAmountSelector(state),
    marketplace: selectedMarketplaceSelector(state),
    skipValidation: reviewFormSelector(state, 'skipValidation'),
    estimatedTotalConcessionFromUser: requirementsFormSelector(state, 'EstimatedTotalConcession'),
    defaultFieldsForSelectedMarketplace: defaultFieldsForSelectedMarketplaceSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getEstimatedTotalConcessionInUSD,
  }, dispatch);
}

ApprovalsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  previousStep: PropTypes.func.isRequired,
};

// Using compose and defaultProps to make react-hot-loader 1.3.0 work with redux-form.
// And adding displayName for unit testing.

const connectedComponent = compose(
  defaultProps({}),
  reduxForm({
    form: 'newJobApprovals',
    destroyOnUnmount: true,
    asyncBlurFields: ['approverAlias', 'requestorAlias', 'businessUserAlias', 'financeUserAlias'],
    asyncValidate: approverAliasAsyncValidation,
    shouldAsyncValidate: shouldAsyncValidateApproverAlias,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ApprovalsForm);

connectedComponent.displayName = 'Connect(Form(ApprovalsForm))';

export default connectedComponent;
