import { createSelector } from 'reselect';
import _ from 'lodash';
import { formValueSelector } from 'redux-form';
import { validate as isEmailValid } from 'email-validator';
import { splitByCommaTrimAndCompact } from '../../../../helpers/stringHelpers';
import { wrapLTRParam } from '../../../../helpers/localeHelpers';

export const parsedEmailRecipientsSelector = createSelector(
  state => state.newJob.previewSender.email.recipientsInputValue,
  input => splitByCommaTrimAndCompact(input),
);

export const invalidEmailRecipientsSelector = createSelector(
  parsedEmailRecipientsSelector,
  emails => _.filter(emails, email => !isEmailValid(email)),
);

export const selectedBlurbForPreviewSelector = createSelector(
  state => state.newJob.blurb.loaded,
  ({ blurbs, selectedBlurbPreviewLanguage }) =>
    _.find(blurbs, { language: selectedBlurbPreviewLanguage }),
);

// Replace fields on content and format field values to rtl.
const replaceFieldonContentReducer = params =>
  _.reduce(params.fileRecord, (content, fieldValue, fieldKey) =>
  content.replace(RegExp(`%{${fieldKey}}`, 'g'), wrapLTRParam(fieldValue, params.language)),
params.content);

const requirementsFormSelector = formValueSelector('newJobRequirements');

export const subjectWithPlaceholdersReplacedSelector = createSelector(
  state => requirementsFormSelector(state, 'Subject'),
  state => state.newJob.file.parsedContent.firstRecord,
  state => state.newJob.blurb.loaded.selectedBlurbPreviewLanguage,
  (subject, fileRecord, language) => {
    if (subject) {
      return replaceFieldonContentReducer({
        fileRecord,
        content: subject,
        language,
      });
    }
    return '';
  },
);

export const previewBlurbSelector = createSelector(
  selectedBlurbForPreviewSelector,
  state => state.newJob.file.parsedContent.firstRecord,
  (selectedBlurb, fileRecord) => {
    if (selectedBlurb === undefined) {
      return {};
    }

    if (selectedBlurb.parameters) {
      return {
        ...selectedBlurb,
        parameters: {
          ...selectedBlurb.parameters,
          Content: replaceFieldonContentReducer({
            fileRecord,
            content: selectedBlurb.parameters.Content,
            language: selectedBlurb.language,
          }),
          Subject: replaceFieldonContentReducer({
            fileRecord,
            content: selectedBlurb.parameters.Subject,
            language: selectedBlurb.language,
          }),
        },
      };
    }

    return {
      ...selectedBlurb,
      content: replaceFieldonContentReducer({
        fileRecord,
        content: selectedBlurb.content,
        language: selectedBlurb.language,
      }),
    };
  },
);
