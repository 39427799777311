import _ from 'lodash';
import {
  GET_JOB_DETAILS_PENDING,
  GET_JOB_DETAILS_FULFILLED,
  GET_JOB_EXECUTION_SUMMARY_PENDING,
  GET_JOB_EXECUTION_SUMMARY_FULFILLED,
  GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_PENDING,
  GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_FULFILLED,
  GET_JOB_APPROVAL_SUMMARY_PENDING,
  GET_JOB_APPROVAL_SUMMARY_FULFILLED
} from '../actions';
import { JobDetailsJarvisXpaths, MapJarvisResponseToWorkflowStatusSummary, getJarvisJobStatus } from '../jarvisConstants';
import { getUserAddedTags } from './loader';

export const initialState = {
  jobID: null,
  isLoadingJobDetails: false,
  jarvisJobStatus: null,
  isJobDataLoaded: false,

  isLoadingApprovalSummary: false,
  executionSummary: {
    isLoading: false,
    isExecutionDataLoaded: false,
    counts: {},
  },
  chunkProcessingWorkflowStatus: {
    isLoading: false,
    isChunkDataLoaded: false,
    counts: {},
  },
  approvalSummary: {
    approvalStatus: null,
  }
};

export const mapJarvisResponseToJobDetails = (data) => {
  const mappedResponse = _.mapValues(
    JobDetailsJarvisXpaths,
    path => _.get(data, path),
  );

  return {
    ...mappedResponse,
    tags: getUserAddedTags(_.map(mappedResponse.tags, 'val')),
    jarvisJobStatus: getJarvisJobStatus(data),
  };
};

export default function activeJob(state = initialState, action) {
  switch (action.type) {
    case GET_JOB_DETAILS_PENDING:
      return {
        ...state,
        isLoadingJobDetails: true,
      };
    case GET_JOB_DETAILS_FULFILLED:
      return {
        ...state,
        isLoadingJobDetails: false,
        isJobDataLoaded: true,
        ...mapJarvisResponseToJobDetails(action.payload.data),
      };
    case GET_JOB_EXECUTION_SUMMARY_PENDING:
      return {
        ...state,
        executionSummary: {
          ...state.executionSummary,
          isLoading: true,
        },
      };
    case GET_JOB_EXECUTION_SUMMARY_FULFILLED:
      return {
        ...state,
        executionSummary: {
          ...state.executionSummary,
          isLoading: false,
          isExecutionDataLoaded: true,
          counts: MapJarvisResponseToWorkflowStatusSummary(action.payload.data),
        },
      };
    case GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_PENDING:
      return {
        ...state,
        chunkProcessingWorkflowStatus: {
          ...state.chunkProcessingWorkflowStatus,
          isLoading: true,
        },
      };
    case GET_CHUNK_PROCESSING_WORKFLOWS_STATUS_FULFILLED:
      return {
        ...state,
        chunkProcessingWorkflowStatus: {
          ...state.chunkProcessingWorkflowStatus,
          isLoading: false,
          isChunkDataLoaded: true,
          counts: MapJarvisResponseToWorkflowStatusSummary(action.payload.data),
        },
      };
      case GET_JOB_APPROVAL_SUMMARY_PENDING:
      return {
        ...state,
        exception: null,
        isLoadingApprovalSummary: true,
      };
      case GET_JOB_APPROVAL_SUMMARY_FULFILLED:
      return {
        ...state,
        exception: null,
        isLoadingApprovalSummary: false,
        approvalSummary: {
            ...action.payload.data,
        },
      };
    default:
      return state;
  }
}
