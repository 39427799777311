import React, { Component } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose, defaultProps } from 'recompose';
import { Form, PanelGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'AWS-UI-Components-React';
import NextButton from '../../../components/NextButton';
import InputField from '../../../components/InputField';
import PreviousButton from '../../../components/PreviousButton';
import DetailsReview from './DetailsReview';
import PlanReview from './PlanReview';
import RequirementsReview from './RequirementsReview';
import { HULK_ON_CALL_URL } from '../../../externalLinks';
import { skipValidationDescription } from '../fieldValidations';
import { isSelectedPlanValidation } from '../../../redux/newJob/plans/selectors';
import { selectedMarketplaceSelector } from '../../../redux/marketplace/selectors';

export function AlertContent() {
  return (
    <div>
      HULK jobs cannot be cancelled after submission. Kindly review your job before proceeding.
      In case of emergency, please
      {' '}
      <a
        href={HULK_ON_CALL_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        page HULK oncall
      </a>
      .
    </div>
  );
}

export class ReviewForm extends Component {
  render() {
    const {
      handleSubmit, pristine, submitting, previousStep, submitSucceeded, invalid, concessionRecipeSelected, values, isValidationPlan, marketplace,
    } = this.props;
    const isInvalidMarketplace = (marketplace == null || marketplace.id == 'default');
    return (
      <Form>
        <Alert
          content={<AlertContent />}
          type="warning"
        />
        <h2>Review</h2>
        <PanelGroup>
          <DetailsReview
            concessionRecipeSelected={concessionRecipeSelected}
            isValidationPlan={isValidationPlan}
          />
          <PlanReview
            concessionRecipeSelected={concessionRecipeSelected}
          />
          <RequirementsReview
            concessionRecipeSelected={concessionRecipeSelected}
          />
        </PanelGroup>
        <div>
          <label htmlFor="acknowledged">
            <Field
              name="acknowledged"
              id="acknowledged"
              component="input"
              type="checkbox"
            />
            {' '}
            I am fully aware of the Customer service plan selected and have done due diligence before
            submitting the job
          </label>
        </div>
        {isValidationPlan && (
        <div>
          <label htmlFor="skipValidation">
            <Field
              name="skipValidation"
              id="skipValidation"
              component="input"
              type="checkbox"
            />
            {' '}
            (Optional) I have urgent business needs to skip concession
            value validation (please specify)
          </label>
        </div>
        )}
        {isValidationPlan && values && values.skipValidation && (
        <Field
          name="skipValidationDescription"
          label=""
          placeholder="Minimum 20 characters. Reasons for skipping validation will be audited by Quality team. You and your team will lose hulk
                      access if abusing this option"
          component={InputField}
          componentClass="textarea"
          validate={skipValidationDescription}
        />
        )}
        <div>
          <PreviousButton onClick={previousStep} />
          {isValidationPlan && !(values && values.skipValidation) ? (
            <NextButton
              disabled={
                      pristine
                      || submitSucceeded
                      || invalid
                      || isInvalidMarketplace
                      || !(values && values.acknowledged)
                    }
              label="Submit Job"
              onClick={handleSubmit}
            />
          ) : (
            <NextButton disabled={pristine || submitting || invalid || isInvalidMarketplace || !(values && values.acknowledged)} onClick={handleSubmit} />
          )}
        </div>
      </Form>
    );
  }
}
ReviewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  previousStep: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const selector = formValueSelector('newJobReview');
  return {
    isValidationPlan: isSelectedPlanValidation(state),
    values: selector(state, 'acknowledged', 'skipValidation', 'skipValidationDescription'),
    marketplace: selectedMarketplaceSelector(state),
  };
}

// Using compose and defaultProps to make react-hot-loader 1.3.0 work with redux-form.
// And adding displayName for unit testing.

const connectedComponent = compose(
  defaultProps({}),
  reduxForm({
    form: 'newJobReview',
    destroyOnUnmount: false,
    initialValues: {
      acknowledged: false,
    },
  }),
  connect(mapStateToProps),
)(ReviewForm);

connectedComponent.displayName = 'Connect(Form(ReviewForm))';

export default connectedComponent;
