import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

class NextButton extends Component {
  render() {
    const { label, disabled, onClick } = this.props;
    return (
      <Button
        bsStyle="primary"
        className="pull-right"
        type="submit"
        disabled={disabled}
        onClick={(e) => { e.preventDefault(); onClick(e); }}
      >
        {label}
      </Button>
    );
  }
}

NextButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

NextButton.defaultProps = {
  disabled: false,
  label: 'Next',
  onClick: () => {},
};

export default NextButton;
