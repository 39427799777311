import { connect } from 'react-redux';
import Avatar from '../components/Avatar';

function mapStateToProps(state) {
  return {
    name: state.userProfile.name,
    login: state.userProfile.login,
  };
}

export default connect(mapStateToProps)(Avatar);
