import { makeHulkProvokerServiceRequestForPost } from '../../../helpers/requestHelpers';
import { selectValuesFromJobReport } from '../../jobs/selectors';
import { totalConcessionAmountSelector } from '../totalConcession/selectors';
import { defaultFieldsForSelectedMarketplaceSelector } from '../../marketplace/selectors';

export const CONVERT_CURRENCY = 'CONVERT_CURRENCY';
export const GET_CONCESSION_REASONS_PENDING = 'GET_CONCESSION_REASONS_PENDING';
export const CONVERT_CURRENCY_FULFILLED = 'CONVERT_CURRENCY_FULFILLED';

export function getEstimatedTotalConcessionInUSD() {
  return (dispatch, getState) => {
    const state = getState();
    const estimatedTotalConcessionValue = selectValuesFromJobReport(state).estimatedTotalConcession;
    const validatedTotalConcessionValue = totalConcessionAmountSelector(state);
    const baseCurrency = defaultFieldsForSelectedMarketplaceSelector(state).CurrencyCode;
    const inputData = {
      values: [Math.max(
        estimatedTotalConcessionValue,
        validatedTotalConcessionValue,
      )],
      targetCurrency: 'USD',
      baseCurrency,
    };
    const requestOptions = {
      url: '/presentation/convertCurrency',
      data: inputData,
    };

    dispatch({
      type: CONVERT_CURRENCY,
      payload: {
        promise: makeHulkProvokerServiceRequestForPost(requestOptions),
      },
    });
  };
}
