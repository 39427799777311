/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectedConcessionReasonSelector = createSelector(
  state => state.newJob.concessionReasons,
  ({ availableConcessionReasons, selectedConcessionReasonCode }) =>
    _.find(availableConcessionReasons, { reasonCode: selectedConcessionReasonCode }),
);

export const availableConcessionReasonsSelector = createSelector(
  state => state.newJob.concessionReasons,
  ({ availableConcessionReasons }) => availableConcessionReasons,
);
