import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getHumanFriendlyDateTime } from '../helpers/dateHelpers';
import JobPagination from '../containers/Jobs/JobPagination';
import './JobsTable.scss';

export default class JobsTable extends Component {
  constructor(props) {
    super(props);
    this.renderJob = this.renderJob.bind(this);
  }

  renderJob({ jobID, jobName, planName, jobSubmitter, startTime, tags }) {
    const jobDetailsUrl = `/jobs/${jobID}`;

    return (
      <tr key={jobID} onClick={() => (this.props.router.navigate(jobDetailsUrl))}>
        <td><Link to={jobDetailsUrl}>{jobID}</Link></td>
        <td>{jobName}</td>
        <td>{planName}</td>
        <td>{jobSubmitter}</td>
        <td>{getHumanFriendlyDateTime(startTime)}</td>
        <td className="wrapped-text">{tags.join(', ')}</td>
      </tr>
    );
  }

  render() {
    if (_.isEmpty(this.props.jobs)) {
      return (<div>No jobs found</div>);
    }

    return (
      <div>
        <Table responsive striped bordered condensed hover>
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Job Name</th>
              <th>Plan Name</th>
              <th>Submitted By</th>
              <th>Submission time</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {this.props.jobs.map(job => this.renderJob(job))}
          </tbody>
        </Table>
        <JobPagination />
      </div>
    );
  }
}

JobsTable.defaultProps = {
  jobs: [],
};
