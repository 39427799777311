import { combineReducers } from 'redux';
import file from './file/reducer';
import blurb from './blurb/reducer';
import htmlBlurb from './htmlBlurb/reducer';
import concessionReasons from './concessionReasons/reducer';
import plans from './plans/reducer';
import previewSender from './previewSender/reducer';
import convertCurrency from './estimatedTotalConcessionInUSD/reducers';

export default combineReducers({
  blurb,
  file,
  htmlBlurb,
  concessionReasons,
  convertCurrency,
  plans,
  previewSender,
});
