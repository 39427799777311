import React from 'react';
import _ from 'lodash';
import { Spinner, ColumnLayout } from 'AWS-UI-Components-React';

// See https://polaris.corp.amazon.com/system/structures/dictionary/

const awsuiGrey600 = '#545b64';

export const DictionaryItem = props => (
  <div className="dictionary-item">
    <div className="dictionary-item-key" style={{ color: awsuiGrey600 }}>{props.itemKey}</div>
    <div className="dictionary-item-value">{props.itemValue}</div>
  </div>
);

const Dictionary = props => (
  <div className="awsui-util-container">
    <div className="awsui-util-container-header">
      <h2>{props.header} { props.isLoading && <Spinner />}</h2>
    </div>
    <ColumnLayout columns={props.columns} borders={props.borders}>
      <div data-awsui-column-layout-root="true">
        {_.map(props.items, ({ key, value }) => (
          <DictionaryItem
            key={key}
            itemKey={key}
            itemValue={value}
          />
        ))}
      </div>
    </ColumnLayout>
    {!_.isEmpty(props.footer) &&
      <div className="awsui-util-container-footer">{props.footer}</div>
    }
  </div>
);

export default Dictionary;
