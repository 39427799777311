import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoaderInputField from '../../components/NewJob/LoaderInputField';
import { loadHtmlBlurbByName, changeHtmlBlurbName } from '../../redux/newJob/htmlBlurb/loader/actions';

export class HtmlBlurbLoaderInputField extends Component {
  render() {
    return (
      <LoaderInputField
        {...this.props}
        name="htmlTemplate"
        label="HTML Template"
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.newJob.htmlBlurb.loader,
    value: state.newJob.htmlBlurb.loader.value || state.newJob.htmlBlurb.loaded.name,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSubmit: loadHtmlBlurbByName,
    onChange: changeHtmlBlurbName,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HtmlBlurbLoaderInputField);
