import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { selectMarketplaceId } from '../redux/marketplace/actions';
import {
  selectedMarketplaceSelector,
  availableMarketplacesSelector,
} from '../redux/marketplace/selectors';
import './MarketplaceMenu.scss';

function createMarketplaceSelectOptions(marketplace) {
  return {
    value: marketplace.id,
    label: marketplace.name,
  };
}

export class MarketplaceMenu extends Component {
  render() {
    return (
      <span className="pull-right">
        <Select
          style={{ width: '250px' }}
          id="marketplace-menu"
          options={this.props.availableMarketplaces.map(createMarketplaceSelectOptions)}
          value={this.props.selectedMarketPlace.id}
          onChange={option => this.props.selectMarketplaceId(option.value)}
          searchable
          clearable={false}
        />
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    availableMarketplaces: availableMarketplacesSelector(state),
    selectedMarketPlace: selectedMarketplaceSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selectMarketplaceId }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceMenu);
