import {
  GET_CONCESSION_REASONS_PENDING,
  GET_CONCESSION_REASONS_FULFILLED,
  SELECT_CONCESSION_REASON,
} from './actions';
import { RESET_REQUIREMENTS_FORM } from '../actions';

export const initialState = {
  availableConcessionReasons: [],
  selectedConcessionReasonCode: '',
};

export default function concessionReasons(state = initialState, action) {
  switch (action.type) {
    case GET_CONCESSION_REASONS_PENDING:
      return initialState;
    case GET_CONCESSION_REASONS_FULFILLED:
      return {
        ...state,
        availableConcessionReasons: action.payload.data.response,
      };
    case SELECT_CONCESSION_REASON:
      return {
        ...state,
        selectedConcessionReasonCode: action.concessionReasonCode,
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
