const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
const RESET_TOTAL_CONCESSION = 'RESET_TOTAL_CONCESSION';
const VALIDATED_JOB_ID = 'VALIDATED_JOB_ID';
const FETCH_API = 'FETCH_API';
const FETCH_API_SUCCESSS = 'FETCH_API_SUCCESSS';
const UPDATE_SELECT_PLAN_ID = 'UPDATE_SELECT_PLAN_ID';
const UPDATE_CURRENTLY_SELECTED_APPROVAL = 'UPDATE_CURRENTLY_SELECTED_APPROVAL';

const initialState = {
  totalConcessionSuccessMessage: '',
  totalConcessionAmount: '',
  validatedJobId: '',
  isFetching: false,
  selectedPlanId: '',
  failureCount: '',
  currentApprovalName: '',
};
export default function totalConcessionSuccessMessageReducer(state = initialState, action) {
    switch (action.type) {
        case SUCCESS_MESSAGE:
            return {
                ...state,
                totalConcessionSuccessMessage: action.totalConcessionSuccessMessage,
                totalConcessionAmount: action.totalConcessionAmount,
                failureCount: action.failureCount,
            };
        case RESET_TOTAL_CONCESSION:
            return {
                ...state,
                totalConcessionAmount: '',
                failureCount: '',
                selectedPlanId: '',
            };
        case VALIDATED_JOB_ID:
            return {
                ...state,
                validatedJobId: action.jobId,
            };
        case FETCH_API:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_API_SUCCESSS:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_SELECT_PLAN_ID:
            return {
                ...state,
                selectedPlanId: action.selectedPlanId,
            };
        case UPDATE_CURRENTLY_SELECTED_APPROVAL:
            return {
                ...state,
                currentApprovalName: action.currentApprovalName,
            };
        default:
            return state;
    }
}
