import { formValueSelector } from 'redux-form';
import {
  selectedBlurbForPreviewSelector,
  parsedEmailRecipientsSelector,
} from './selectors';
import { convertToJavascriptFormat } from '../../../../helpers/localeHelpers';
import { makeHulkProvokerServiceRequest } from '../../../../helpers/requestHelpers';

export const EMAIL_RECIPIENTS_INPUT_VALUE_CHANGED = 'EMAIL_RECIPIENTS_INPUT_VALUE_CHANGED';
export const EMAIL_RECIPIENTS_INPUT_TOUCHED = 'EMAIL_RECIPIENTS_INPUT_TOUCHED';

export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL'; // will produce the following actions:
export const SEND_TEST_EMAIL_PENDING = 'SEND_TEST_EMAIL_PENDING';
export const SEND_TEST_EMAIL_FULFILLED = 'SEND_TEST_EMAIL_FULFILLED';
export const SEND_TEST_EMAIL_REJECTED = 'SEND_TEST_EMAIL_REJECTED';

export const changeEmailRecipientsInputValue = value => ({
  type: EMAIL_RECIPIENTS_INPUT_VALUE_CHANGED,
  value,
});

export const touchEmailRecipientsInput = () => ({
  type: EMAIL_RECIPIENTS_INPUT_TOUCHED,
});

export function sendTestEmail() {
  return (dispatch, getState) => {
    const state = getState();

    let blurbContent;
    const blurb = selectedBlurbForPreviewSelector(state);
    if (blurb.parameters) {
      blurbContent = blurb.parameters.Content;
    } else {
      blurbContent = blurb.content;
    }

    const requirementsFormSelector = formValueSelector('newJobRequirements');
    const data = {
      emailAttributes: {
        BlurbId: blurb.blurbID,
        BlurbContent: blurbContent,
        Language: convertToJavascriptFormat(blurb.language, '_'),
        MarketplaceID: state.marketplace.selectedMarketplaceId,
        FromName: requirementsFormSelector(state, 'FromName'),
        FromAddress: requirementsFormSelector(state, 'FromAddress'),
        Subject: requirementsFormSelector(state, 'Subject'),
      },
      substitutionParameters: state.newJob.file.parsedContent.firstRecord,
      previewRecipients: parsedEmailRecipientsSelector(state),
    };

    const requestOptions = {
      url: '/presentation/actions/notifications/e-mail/preview',
      method: 'post',
      data,
    };

    dispatch({
      type: SEND_TEST_EMAIL,
      payload: {
        promise: makeHulkProvokerServiceRequest(requestOptions),
      },
    });
  };
}
