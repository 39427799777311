import { deploymentStages, deploymentRegions, currentStage, currentRegion } from '../../deploymentStages';
import { MARKETPLACE_SELECTED } from './actions';
import {
  DEFAULT_MARKETPLACE,
  NA_DEVO_AVAILABLE_MARKETPLACES,
  EU_DEVO_AVAILABLE_MARKETPLACES,
  FE_DEVO_AVAILABLE_MARKETPLACES,
  CN_DEVO_AVAILABLE_MARKETPLACES,
  NA_PROD_AVAILABLE_MARKETPLACES,
  EU_PROD_AVAILABLE_MARKETPLACES,
  FE_PROD_AVAILABLE_MARKETPLACES,
  CN_PROD_AVAILABLE_MARKETPLACES,
} from './availableMarketplaces';
import {
  DEFAULT_FIELDS_FOR_DEFAULT_MARKETPLACE,
  DEFAULT_FIELDS_FOR_DEVO_MARKETPLACES,
  DEFAULT_FIELDS_FOR_PROD_MARKETPLACES,
} from './defaultFieldsForMarketplaces';

export const DEVO_AVAILABLE_MARKETPLACES = {
  [deploymentRegions.NA]: NA_DEVO_AVAILABLE_MARKETPLACES,
  [deploymentRegions.EU]: EU_DEVO_AVAILABLE_MARKETPLACES,
  [deploymentRegions.FE]: FE_DEVO_AVAILABLE_MARKETPLACES,
  [deploymentRegions.CN]: CN_DEVO_AVAILABLE_MARKETPLACES,
};

export const PROD_AVAILABLE_MARKETPLACES = {
  [deploymentRegions.NA]: NA_PROD_AVAILABLE_MARKETPLACES,
  [deploymentRegions.EU]: EU_PROD_AVAILABLE_MARKETPLACES,
  [deploymentRegions.FE]: FE_PROD_AVAILABLE_MARKETPLACES,
  [deploymentRegions.CN]: CN_PROD_AVAILABLE_MARKETPLACES,
};

let availableMarketplaces;
let defaultFieldsForMarketplaces;
switch (currentStage) {
  case deploymentStages.ALPHA:
  case deploymentStages.BETA:
    availableMarketplaces = [...DEFAULT_MARKETPLACE, ...DEVO_AVAILABLE_MARKETPLACES[currentRegion]];
    defaultFieldsForMarketplaces = [...DEFAULT_FIELDS_FOR_DEFAULT_MARKETPLACE, ...DEFAULT_FIELDS_FOR_DEVO_MARKETPLACES];
    break;
  case deploymentStages.GAMMA:
  case deploymentStages.PROD:
    availableMarketplaces = [...DEFAULT_MARKETPLACE, ...PROD_AVAILABLE_MARKETPLACES[currentRegion]];
    defaultFieldsForMarketplaces = [...DEFAULT_FIELDS_FOR_DEFAULT_MARKETPLACE, ...DEFAULT_FIELDS_FOR_PROD_MARKETPLACES];
    break;
  default:
    availableMarketplaces = [...DEFAULT_MARKETPLACE, ...DEVO_AVAILABLE_MARKETPLACES[currentRegion]];
    defaultFieldsForMarketplaces = [...DEFAULT_FIELDS_FOR_DEFAULT_MARKETPLACE, ...DEFAULT_FIELDS_FOR_DEVO_MARKETPLACES];
}

export const initialState = {
  /**
   * availableMarketplaces is an array because order is important.
   * TODO: dynamically fetch marketplaces from backend:
   * https://issues.amazon.com/issues/HB-Hulk-423
   */
  availableMarketplaces,
  defaultFieldsForMarketplaces,
  selectedMarketplaceId: 'default',
};

export default function userProfile(state = initialState, action) {
  switch (action.type) {
    case MARKETPLACE_SELECTED:
      return {
        ...state,
        selectedMarketplaceId: action.marketplaceId,
      };
    default:
      return state;
  }
}
