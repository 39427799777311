import { LOAD_HTML_BLURB_PENDING, LOAD_HTML_BLURB_FULFILLED, LOAD_HTML_BLURB_REJECTED } from '../loader/actions';
import { RESET_REQUIREMENTS_FORM } from '../../actions';
import { extractFields } from '../../helpers';

export const initialState = {
  id: '',
  name: '',
  contents: '',
  fileRequirements: [],
};

export default function loaded(state = initialState, action) {
  switch (action.type) {
    case LOAD_HTML_BLURB_PENDING:
      return {
        ...initialState,
      };
    case LOAD_HTML_BLURB_FULFILLED:
      return {
        ...action.payload.data,
        fileRequirements: extractFields(action.payload.data.contents),
      };
    case LOAD_HTML_BLURB_REJECTED:
      return {
        ...initialState,
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
