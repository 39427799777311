import { createSelector } from 'reselect';
import _ from 'lodash';
import {
    isFileExtensionCSV,
    doesFileContainNoHeaderColumn,
    doesFileContainEmptyHeaders,
    doesFileContainDuplicateHeaders,
    areNumberOfRowsOverLimit
} from './helpers';
import {isSelectedPlanCommunicationSelector, planFileRequirementsSelector} from '../plans/selectors';

export const optionalFieldsSelector = createSelector(
  planFileRequirementsSelector,
  fields => _.map(_.filter(fields, 'isOptional'), f => f.name),
);

export const planFileRequirementNamesSelector = createSelector(
  planFileRequirementsSelector,
  fields => _.map(fields, f => f.name),
);

export const fileRequirementsSelector = createSelector(
  planFileRequirementNamesSelector,
  state => state.newJob.blurb.loaded.fileRequirements,
  state => state.newJob.htmlBlurb.loaded.fileRequirements,
  state => state.newJob.file.parsedContent.headers,
  optionalFieldsSelector,
  (
    planFileRequirements,
    blurbFileRequirements,
    htmlBlurbFileRequirements,
    fieldsInFile,
    optionalFieldsForPlan,
  ) => {
    // Remove duplicate requirements
    const uniqueBlurbFileRequirements = _.uniq(blurbFileRequirements);

    // If the blurb requires AsinTitle, the file needs to contain Asin.
    // AsinTitle will be fetched from Asin in the backend.
    const index = _.findIndex(uniqueBlurbFileRequirements, requirement => requirement === 'AsinTitle');
    if (index !== -1) {
      uniqueBlurbFileRequirements.splice(index, 1, 'Asin');
    }

    const fileRequirements = _.union(
      planFileRequirements,
      uniqueBlurbFileRequirements,
      htmlBlurbFileRequirements,
    );

    // Remove blurb requirements from optional fields
    const effectiveOptionalFields = _.difference(optionalFieldsForPlan, uniqueBlurbFileRequirements);

    // For each field, creates an object with
    // the name of the field and if it's been satisfied.
    return fileRequirements.map(field => ({
      name: field,
      satisfied: _.includes(fieldsInFile, field) || _.includes(effectiveOptionalFields, field),
    }));
  },
);

export const fileRequirementNamesSelector = createSelector(
  fileRequirementsSelector,
  requirements => requirements.map(r => r.name),
);

export const haveFileRequirementsBeenSatisfiedSelector = createSelector(
  fileRequirementsSelector,
  fields => _.every(fields, { satisfied: true }),
);

export const selectedFileErrorMessageSelector = createSelector(
  state => state.newJob.file,
    haveFileRequirementsBeenSatisfiedSelector,
    isSelectedPlanCommunicationSelector,
  (file, isSatisfied, isCommunicationPlanSelected) => {
    if (!isFileExtensionCSV(file.value)) return 'Only csv files are supported.';
    if (_.isEmpty(file.parsedContent.firstRecord)) return 'File must contain at least one record.';
    if (doesFileContainNoHeaderColumn(file.parsedContent.headers)) return 'All columns in the file should contain headers.';
    if (doesFileContainEmptyHeaders(file.parsedContent.headers)) return 'All columns in the file should contain non-empty headers.';
    if (doesFileContainDuplicateHeaders(file.parsedContent.metadata.fields)) return 'File should not contain duplicate headers.';
    if (areNumberOfRowsOverLimit(isCommunicationPlanSelected, file.parsedContent.metadata.size))
        return isCommunicationPlanSelected ? 'Number of Records should be less than or equal to 70,000' :
            'Number of Records should be less than or equal to 50000';
    if (!isSatisfied) return 'File must satisfy requirements.';
    return '';
  },
);
