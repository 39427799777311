import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Well, Alert } from 'react-bootstrap';
import _ from 'lodash';
import { RTLContent } from '../../components/RTLContent';
import {
  subjectWithPlaceholdersReplacedSelector,
  previewBlurbSelector,
} from '../../redux/newJob/previewSender/email/selectors';
import { isLanguageDirectionRTL } from '../../helpers/localeHelpers';
import {
  ADDITIONAL_INFO,
  MARKETPLACE_VERIFICATION_INSTRUCTION,
  TEST_EMAIL_REVIEW_INSTRUCTION,
} from '../../StringConstants';
import { RTN_SONAR_V2_MIGRATION } from '../../externalLinks';

export function SpecialInstructions({ instructions }) {
  const instructionList = [];
  instructionList.push(MARKETPLACE_VERIFICATION_INSTRUCTION);
  if (instructions) {
    instructionList.push(instructions);
  }
  return (
    <Alert bsStyle="info">
      <strong>Special Instructions</strong>
      <br />
      <ul>
        {instructionList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
        <li>
          {TEST_EMAIL_REVIEW_INSTRUCTION + ADDITIONAL_INFO}
          <a href={RTN_SONAR_V2_MIGRATION} target="_blank" rel="noopener noreferrer">
            here
          </a>
        </li>
      </ul>
    </Alert>);
}
export class EmailPreview extends Component {
  render() {
    let fromAddress;
    let fromName;
    let subject;
    let content;
    const blurbParameters = this.props.blurb.parameters;
    if (blurbParameters) {
      fromAddress = blurbParameters.FromAddress;
      fromName = blurbParameters.FromName;
      subject = blurbParameters.Subject;
      content = blurbParameters.Content;
    } else {
      fromAddress = this.props.fromAddress;
      fromName = this.props.fromName;
      subject = this.props.subject;
      content = this.props.blurb.content;
    }
    // enclose fromAddress in <> if not empty
    if (!_.isEmpty(fromAddress)) {
      fromAddress = `<${fromAddress}>`;
    }
    // Wrap Blurb content with html and format it to RTL.
    if (isLanguageDirectionRTL(this.props.blurb.language)) {
      content = <RTLContent content={content} />;
    }

    return (
      <div>
        <h4>Email Preview</h4>
        <Well>
          {/* TODO: Uncomment once notification API is integrated. */}
          {/* <div className="email-preview-to">
            To: <strong>{this.props.recipientsInputValue}</strong>
          </div> */}
          <div className="email-preview-from">
            From: <strong>{`${fromName} ${fromAddress}`}</strong>
          </div>
          <div className="email-preview-subject">
            Subject: <strong>{subject}</strong>
          </div>
          <br />
          <div className="email-preview-blurb" style={{ whiteSpace: 'pre-wrap' }} >
            <SpecialInstructions instructions={this.props.blurb.instructions} />
            <hr />
            {content}
          </div>
        </Well>
      </div>
    );
  }
}

EmailPreview.propTypes = {
  fromAddress: PropTypes.string,
  fromName: PropTypes.string,
  subject: PropTypes.string,
};

EmailPreview.defaultProps = {
  fromAddress: '',
  fromName: '',
  subject: '',
  blurb: {},
};

const requirementsFormSelector = formValueSelector('newJobRequirements');

function mapStateToProps(state) {
  return {
    ...state.newJob.previewSender.email,
    fromName: requirementsFormSelector(state, 'FromName'),
    fromAddress: requirementsFormSelector(state, 'FromAddress'),
    subject: subjectWithPlaceholdersReplacedSelector(state),
    blurb: previewBlurbSelector(state),
  };
}

export default connect(mapStateToProps)(EmailPreview);
