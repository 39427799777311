//export const REPORT_ISSUE_URL = 'https://t.corp.amazon.com/create/templates/b8c49bdb-322a-45b4-b665-934b70750725';
export const REPORT_ISSUE_URL = 'https://river.amazon.com/?org=CS_HULK';
export const USER_MANUAL_WIKI_URL = 'https://w.amazon.com/bin/view/WWDE/HULK/UserManual/';
export const REPORT_INTERPRETATION_WIKI_LINK = 'https://w.amazon.com/bin/view/WWDE/HULK/UserManual/HowToInterpretJobReport/';
export const REQUEST_ACCESS_URL = 'https://w.amazon.com/bin/view/WW_Concessions/HULK_requests/';
export const REQUEST_FEATURE_URL = 'https://w.amazon.com/bin/view/Ww_concessions/RequestIntake';
export const HULK_SAGE_LINK = 'https://sage.amazon.com/tags/Hulk';
export const CONCESSIONS_REASONS_WIKI_URL = 'https://w.amazon.com/bin/view/CSTech/Heartbeat/Resolution/HULK/Concession_Reasons/';
export const HULK_PLANS_SOP_WIKI_URL = 'https://w.amazon.com/bin/view/HULK_Job_Plan_Guidance/';
export const CONCESSIONS_JOB_APPROVAL_POLICY_WIKI_URL = 'https://tiny.amazon.com/7tpe7p8v/HulkApproval';
export const HULK_STUCK_JOB_TT_QUICK_LINK = 'https://tiny.amazon.com/12z553pg6/tcorpamazcreatempaf35';
export const HULK_ON_CALL_URL = 'https://oncall.corp.amazon.com/#/view/concessions-policies/schedule';
export const INTEGRATED_APPROVALS_WIKI_URL = 'https://w.amazon.com/bin/view/WWDE/HULK/IntegratedApprovalsUserManual/';
export const RTN_SONAR_V2_MIGRATION = 'https://w.amazon.com/bin/view/WWDE/HULK/UserManual/RioMigration/';
