import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Logo.scss';

export default class Logo extends Component {
  render() {
    return (
      <div className="hb-logo">
        <Link to="/">
          <i className="fa fa-heartbeat fa-lg" aria-hidden="true" />
          <span>Heartbeat HULK</span>
        </Link>
      </div>
    );
  }
}
