/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

// Converts the locale format, eg: en_us to en_US or en-US
export const convertToJavascriptFormat = (locale, joiningChar) => {
  const splitLocale = _.split(locale, '_');
  const languageCode = splitLocale[0];
  const countryCode = splitLocale[1];
  const countryCodeUppercase = _.toUpper(countryCode);

  return _.join([languageCode, countryCodeUppercase], joiningChar);
};

// Identify language script direction by locale.
// Currently RTL formatting enabled to AE, SA marketplaces!
const languageDirection = (locale) => {
  const enabledLocales = ['ar_ae', 'ar_sa'];
  const rtlLocales = [
    'ar', // Arabic
    'fa', // Persian
    'iw', // Hebrew (Deprecated)
    'he', // Hebrew (ISO 639-1)
    'kk', // Kazakh
    'ps', // Pashto, Pushto
    'ur', // Urdo
  ];

  if (locale && enabledLocales.includes(locale)) {
    const splitLocale = _.split(locale, '_');
    const languageCode = splitLocale[0];
    if (rtlLocales.includes(languageCode)) {
      return 'rtl';
    }
  }

  return 'ltr';
};

// Check if language script direction is RTL.
export const isLanguageDirectionRTL = locale => languageDirection(locale) === 'rtl';

// Force ltr format for email substitutionParameters.
export const wrapLTRParam = (value, locale) => {
  const orderIDPattern = new RegExp(/^[+]*[-\s.0-9\\n]+/g);

  if (locale && isLanguageDirectionRTL(locale) && orderIDPattern.test(value)) {
    return `\u{200E}${value}\u{200F}`;
  }

  return value;
};
