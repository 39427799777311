import React, { Component } from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import {concession2_0RecipeIDS} from '../helpers/constants';

const renderAction = ({ id, name, description }) =>
(
  <Col sm={4} key={id}>
    <Panel>
      <Panel.Heading>{name}</Panel.Heading>
      <Panel.Body>{description}</Panel.Body>
    </Panel>
  </Col>
);


export default class PlanActions extends Component {
  render() {
    return (
      <Row>
        {this.props.actions.map((action) => {
          return renderAction(action);
        })}
      </Row>
    );
  }
}

PlanActions.defaultProps = {
  actions: [],
  planId: '',
};
