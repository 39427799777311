import { selectedPlanSelector } from '../../../redux/newJob/plans/selectors';
import { selectedMarketplaceSelector } from '../../../redux/marketplace/selectors';
import { makeHulkProvokerServiceRequest } from '../../../helpers/requestHelpers';

export const GET_CONCESSION_REASONS = 'GET_CONCESSION_REASONS'; // will produce the following actions
export const GET_CONCESSION_REASONS_PENDING = 'GET_CONCESSION_REASONS_PENDING';
export const GET_CONCESSION_REASONS_FULFILLED = 'GET_CONCESSION_REASONS_FULFILLED';

export const SELECT_CONCESSION_REASON = 'SELECT_CONCESSION_REASON';

export function getConcessionReasons() {
  return (dispatch, getState) => {
    const state = getState();
    const planId = selectedPlanSelector(state).id;
    const marketplaceId = selectedMarketplaceSelector(state).id;
    const requestOptions = {
      url: `/presentation/concession-reasons?plan-id=${planId}&marketplace-id=${marketplaceId}`,
    };

    dispatch({
      type: GET_CONCESSION_REASONS,
      payload: {
        promise: makeHulkProvokerServiceRequest(requestOptions),
      },
    });
  };
}

export function selectConcessionReason(concessionReasonCode) {
  return {
    type: SELECT_CONCESSION_REASON,
    concessionReasonCode,
  };
}
