import { validate as isEmailValid } from 'email-validator';

export function requiredFieldValidation(value) {
  return value ? undefined : 'Required';
}

export function emailFieldValidation(value) {
  return isEmailValid(value) ? undefined : 'Invalid Email';
}

export function skipValidationDescription(value) {
  return value && value.length >= 20 ? undefined : 'A minimum of 20 characters is required.';
}