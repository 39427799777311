import _ from 'lodash';
import { BLURB_PREVIEW_LANGUAGE_SELECTED } from './actions';
import {
  GET_BLURBS_PENDING,
  GET_BLURBS_FULFILLED,
  GET_BLURBS_REJECTED,
} from '../loader/actions';
import { RESET_REQUIREMENTS_FORM } from '../../actions';
import { extractFields } from '../../helpers';
import { parseBlurbXml, isValidXml, containsAllMandatoryFields } from '../../../../helpers/blurbHelpers';

export const initialState = {
  blurbs: [],
  fileRequirements: [],
  selectedBlurbPreviewLanguage: '',
};

const getFileRequirements = (blurbs) => {
  const fileRequirements = [];
  _.forEach(blurbs, (blurb) => {
    if (blurb.parameters) {
      _.forEach(extractFields(blurb.parameters.Content), fileRequirement =>
        fileRequirements.push(fileRequirement));
      _.forEach(extractFields(blurb.parameters.Subject), fileRequirement =>
        fileRequirements.push(fileRequirement));
    } else {
      _.forEach(extractFields(blurb.content), fileRequirement =>
        fileRequirements.push(fileRequirement));
    }
  });

  return fileRequirements;
};

const constructBlurbParameters = (blurb) => {
  const blurbParameters = {};
  const parsedBlurb = parseBlurbXml(blurb.content);
  _.forEach(parsedBlurb.documentElement.children, (parameter) => {
    if (!_.has(blurbParameters, parameter.tagName)) {
      blurbParameters[parameter.tagName] = parameter.textContent;
    }
  });
  return blurbParameters;
};

const formatBlurbs = (blurbs) => {
  const formattedBlurbs = [];
  _.forEach(blurbs, (blurb) => {
    const formattedBlurb = _.pick(blurb, ['blurbID', 'name', 'instructions', 'language']);

    // Construct blurb parameters only if the blurb is a valid xml
    // and contains all the mandatory fields.
    if (isValidXml(blurb) && containsAllMandatoryFields(blurb)) {
      formattedBlurb.parameters = constructBlurbParameters(blurb);
    // Else add the content as it is.
    } else {
      formattedBlurb.content = blurb.content;
    }
    formattedBlurbs.push(formattedBlurb);
  });
  return formattedBlurbs;
};

export default function loaded(state = initialState, action) {
  switch (action.type) {
    case GET_BLURBS_PENDING:
      return initialState;
    case GET_BLURBS_FULFILLED: {
      const blurbs = action.payload.data;
      if (blurbs.length === 0) return initialState;

      let formattedBlurbs;
      if (action.meta.isPlanEmailDependent) {
        formattedBlurbs = formatBlurbs(blurbs);
      } else {
        formattedBlurbs = blurbs;
      }

      return {
        blurbs: formattedBlurbs,
        fileRequirements: getFileRequirements(blurbs),
        selectedBlurbPreviewLanguage: blurbs[0].language,
      };
    }
    case GET_BLURBS_REJECTED:
      return initialState;
    case BLURB_PREVIEW_LANGUAGE_SELECTED:
      return {
        ...state,
        selectedBlurbPreviewLanguage: action.blurbPreviewLanguage,
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
