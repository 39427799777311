import _ from 'lodash';
import { loadStatus } from '../../../../components/NewJob/LoaderInputField';
import { isValidXml, containsAllMandatoryFields } from '../../../../helpers/blurbHelpers';
import {
  BLURB_NAME_CHANGED,
  GET_BLURBS_PENDING,
  GET_BLURBS_FULFILLED,
  GET_BLURBS_REJECTED,
} from './actions';
import { RESET_REQUIREMENTS_FORM } from '../../actions';

export const initialState = {
  value: '',
  error: '',
  status: loadStatus.NOT_LOADED,
};

const getBlurbCountError = (state, action) => {
  const blurbCount = action.payload.data.length;
  const blurbName = state.value;
  if (blurbCount === 0) return `No blurb found with the given blurb name: ${blurbName} in the selected marketplace`;
  return '';
};

export const INVALID_BLURB_FORMAT_ERROR = 'Parsing error, one or more blurbs (marked with *) have invalid format';
export const MANDATORY_FIELDS_MISSING_FROM_BLURB_ERROR = 'Parsing error, mandatory fields are missing in one or more blurbs (marked with *)';

const getBlurbParsingError = (action) => {
  let errorMsg = '';
  const isPlanEmailDependent = action.meta.isPlanEmailDependent;

  // If plan is not email dependent, don't throw an error.
  // We will accept the content as it is.
  if (isPlanEmailDependent) {
    const blurbs = action.payload.data;

    if (blurbs.length > 1) {
      _.forEach(blurbs, (blurb) => {
        if (!isValidXml(blurb)) {
          errorMsg = INVALID_BLURB_FORMAT_ERROR;
        } else if (!containsAllMandatoryFields(blurb)) {
          errorMsg = MANDATORY_FIELDS_MISSING_FROM_BLURB_ERROR;
        }
      });
    } else {
      const blurb = blurbs[0];

      // If only a single blurb exists which is not a valid xml,
      // don't throw an error. We will accept the content as it is.
      if (isValidXml(blurb)) {
        if (!containsAllMandatoryFields(blurb)) {
          errorMsg = MANDATORY_FIELDS_MISSING_FROM_BLURB_ERROR;
        }
      }
    }
  }

  return errorMsg;
};

export default function blurbLoader(state = initialState, action) {
  switch (action.type) {
    case BLURB_NAME_CHANGED:
      return {
        ...state,
        value: action.value,
        error: '',
        status: loadStatus.NOT_LOADED,
      };
    case GET_BLURBS_FULFILLED: {
      const errorMsg = getBlurbCountError(state, action) || getBlurbParsingError(action);
      return {
        ...state,
        error: errorMsg,
        status: _.isEmpty(errorMsg) ? loadStatus.LOADED : loadStatus.NOT_LOADED,
      };
    }
    case GET_BLURBS_PENDING:
      return {
        ...state,
        status: loadStatus.LOADING,
      };
    case GET_BLURBS_REJECTED:
      return {
        ...state,
        error: "Couldn't load blurb. Please try again.",
        status: loadStatus.NOT_LOADED,
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
