import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose, defaultProps } from 'recompose';
import { Form, Row, Col } from 'react-bootstrap';
import { Alert } from 'AWS-UI-Components-React';
import _ from 'lodash';
import NextButton from '../../components/NextButton';
import PreviousButton from '../../components/PreviousButton';
import RequiredFieldMessage from '../../components/RequiredFieldMessage';
import FileUpload from './FileUpload';
import BlurbLoaderInputField from './BlurbLoaderInputField';
import HtmlBlurbLoaderInputField from './HtmlBlurbLoaderInputField';
import BlurbContent from './BlurbContent';
import BlurbTemplate from './BlurbTemplate';
import BlurbPreview from './BlurbPreview';
import EmailPreview from './EmailPreview';
import BlurbPreviewLanguagesDropdown from './BlurbPreviewLanguagesDropdown';
import ConcessionReasonsDropdown from './ConcessionReasonsDropdown';
import InputField from '../../components/InputField';
import FileRequirements from './FileRequirements';
import FileRequirementsTemplate from './FileRequirementsTemplate';
import { requiredFieldValidation, emailFieldValidation } from './fieldValidations';
import { UPLOADED } from '../../redux/newJob/file/uploadStatus';
import { loadStatus } from '../../components/NewJob/LoaderInputField';
import { resetRequirementsForm } from '../../redux/newJob/actions';
import { getConcessionReasons } from '../../redux/newJob/concessionReasons/actions';
import { selectedConcessionReasonSelector } from '../../redux/newJob/concessionReasons/selectors';
import {
  selectedMarketplaceSelector,
  defaultFieldsForSelectedMarketplaceSelector,
} from '../../redux/marketplace/selectors';
import {
  availableBlurbsSelector,
  blurbsWithoutMandatoryParametersSelector,
} from '../../redux/newJob/blurb/loaded/selectors';
import { blurbLoadingErrorSelector, blurbLoadingStatusSelector } from '../../redux/newJob/blurb/loader/selectors';
import {
  formFieldRequirementsSelector,
  isSelectedPlanBlurbDependentSelector,
  isSelectedPlanEmailDependentSelector,
  isSelectedPlanMobilePushDependentSelector,
  isSelectedPlanCurrencyCodeDependentSelector,
  isSelectedPlanOrderConditionDepenedentSelector,
  isSelectedPlanConcessionReasonDependentSelector,
  shouldShowSuppressEmailOptionSelector,
  shouldShowRefundToGcOptionSelector,
  isConcessionPlanSelectedSelector, isSelectedPlanIAConcessionSelector,
  formFieldForConcessionRecipe,
} from '../../redux/newJob/plans/selectors';
import { selectedBlurbForPreviewSelector } from '../../redux/newJob/previewSender/email/selectors';
import EmailTestSender from './EmailTestSender';

import {
  WARNING_MESSAGE_FOR_CHANGE_ORDER_CONDITION_PLANS,
  WARNING_MESSAGE_FOR_BLURB_DEPENDENT_PLANS,
  AlertContentForConcessionPlans
} from '../../warningMessages'
import {
  shouldAsyncValidateRequirementsForm,
  totalConcessionAsyncValidation
} from "./asyncValidate";
import {getPlanName} from "../../redux/newJob/planName/actions";
import { numberValidator } from '../../helpers/validation';

export const fileNameToComponentClass = (name) => {
  switch (name) {
    case 'Annotation':
      return 'textarea';
    default:
      return 'input';
  }
};

export const getFieldValidations = (field) => {
  const validations = [];

  if (!field.isOptional) validations.push(requiredFieldValidation);
  if (field.name === 'FromAddress') validations.push(emailFieldValidation);
  if (field.name === 'EstimatedTotalConcession') validations.push(numberValidator);

  return validations;
};

const SuppressEmailCheckbox = (
  <div>
    <Field
      name="SuppressCrowRefundConfirmationEmails"
      id="SuppressCrowRefundConfirmationEmails"
      component="input"
      type="checkbox"
    />
    {' '}
    <label htmlFor="SuppressCrowRefundConfirmationEmails">
    Suppress CROW Refund confirmation emails</label>
  </div>
);

const RefundToGcCheckbox = (
    <div>
      <Field
          name="RefundToGC"
          id="RefundToGC"
          component="input"
          type="checkbox"
      />
      {' '}
      <label htmlFor="RefundToGC">
        Refund the Amount as Electronic Gift Card</label>
    </div>
);

const AlertPeakReminder = () => (<div>
  Peak Reminder: Please run large jobs (>50K) in batches. For communication job (massmail only), divide your job into batches of 70K. For all other jobs, divide your job into batches of 50k.
</div>);

export class RequirementsForm extends Component {
  constructor(props) {
    super(props);
    this.renderFieldsWithPreview = this.renderFieldsWithPreview.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.shouldNextButtonBeDisabled = this.shouldNextButtonBeDisabled.bind(this);
    this.initializeStateWithLoadedBlurb = this.initializeStateWithLoadedBlurb.bind(this);
    this.initializeState = this.initializeState.bind(this);
    this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
    this.shouldFieldBeDisabled = this.shouldFieldBeDisabled.bind(this);
  }

  componentDidMount() {
    // initialize the form after mounting and before rendering
    this.initializeState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.marketplace.id !== nextProps.marketplace.id) {
      this.initializeState(nextProps);
    } else if ((this.props.blurbLoadStatus !== loadStatus.LOADED
          && nextProps.blurbLoadStatus === loadStatus.LOADED)
          || (this.props.selectedBlurb !== undefined
              && nextProps.selectedBlurb !== undefined
              && this.props.selectedBlurb.language !== nextProps.selectedBlurb.language)) {
      this.initializeStateWithLoadedBlurb(nextProps);
    }
  }

  initializeStateWithLoadedBlurb(props) {
    const defaultValues = {};
    const blurbParameters = props.selectedBlurb.parameters;

    if (blurbParameters) {
      if (blurbParameters.MobilePushText) {
        defaultValues.mobilePush = true;
        defaultValues.MobilePushText = blurbParameters.MobilePushText;
      }
      defaultValues.FromName = blurbParameters.FromName;
      defaultValues.FromAddress = blurbParameters.FromAddress;
      defaultValues.Subject = blurbParameters.Subject;
    } else {
      defaultValues.FromName = props.defaultFieldsForSelectedMarketplace.FromName;
      defaultValues.FromAddress = props.defaultFieldsForSelectedMarketplace.FromAddress;
    }

    props.initialize(defaultValues);

    if (blurbParameters) {
      if (blurbParameters.MobilePushText) {
        props.touch('MobilePushText');
      }
      props.touch('Subject');
    }
    props.touch('FromName');
    props.touch('FromAddress');
  }

  initializeState(props) {
    props.resetForm();

    if(this.props.isConcessionIAPlan) {
      props.getPlanName();
    }

    if (this.props.shouldDisplayConcessionReasonsDropdown) {
      this.props.getConcessionReasons();
    }

    const defaultValues = {};
    if (props.isPlanEmailDependent) {
      defaultValues.FromName = props.defaultFieldsForSelectedMarketplace.FromName;
      defaultValues.FromAddress = props.defaultFieldsForSelectedMarketplace.FromAddress;
    }
    if (props.isPlanCurrencyCodeDependent) {
      defaultValues.CurrencyCode = props.defaultFieldsForSelectedMarketplace.CurrencyCode;
    }

    if (props.validationJobId) {
      defaultValues.JobID = props.validationJobId;
    }

    props.initialize(defaultValues);

    // touch these fields to validate
    if (props.isPlanEmailDependent) {
      props.touch('FromName');
      props.touch('FromAddress');
    }
    if (props.isPlanCurrencyCodeDependent) {
      props.touch('CurrencyCode');
    }

    if (props.validationJobId) {
      props.touch('JobID');
      props.change('JobID', props.validationJobId);
      props.blur('JobID', props.validationJobId);
      // blur('newJobRequirements', 'JobID', props.validationJobId);
      // focus('newJobRequirements', 'JobID', props.validationJobId);
      props.asyncValidate();
    }
  }

  shouldFieldBeDisabled(name) {
    if (this.props.concessionRecipeSelected && name === 'JobID') return true;
    // Disable the fields if there are multiple blurbs
    // or if they are present in the blurb.
    // Either all or no parameters will be there.
    if (this.props.blurbs.length > 1 ||
          (this.props.selectedBlurb !== undefined
          && this.props.selectedBlurb.parameters)) {
      switch (name) {
        case 'FromName':
          return true;
        case 'FromAddress':
          return true;
        case 'Subject':
          return true;
        case 'mobilePush':
          return true;
        case 'MobilePushText':
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  handlePreviousButtonClick() {
    this.props.destroy();
    this.props.previousStep();
  }

  shouldNextButtonBeDisabled() {
    const {
      submitting,
      invalid,
      shouldHideFileUpload,
      fileUploadStatus,
      shouldDisplayBlurb,
      blurbLoadStatus,
      shouldDisplayConcessionReasonsDropdown,
      concessionReason,
      asyncValidating,
      enteredJobId,
      validatedJobId,
      isConcessionIAPlan,
      isFetching,
    } = this.props;

    if (enteredJobId !== validatedJobId && isConcessionIAPlan) return true;
    if (asyncValidating || isFetching) return true;
    if (submitting) return true;
    if (invalid) return true;
    if (shouldHideFileUpload) return false;
    if (fileUploadStatus !== UPLOADED) return true;
    if (shouldDisplayBlurb && blurbLoadStatus !== loadStatus.LOADED) return true;
    if (shouldDisplayConcessionReasonsDropdown && concessionReason.reasonCode === '') return true;
    return false;
  }

  renderFieldsWithPreview() {
    return (
      <Row>
        <Col sm={6}>
          {this.renderFields()}
        </Col>
        <Col sm={6}>
          <BlurbPreviewLanguagesDropdown />
          {this.props.shouldDisplayEmailPreview ?
          [((_.find(this.props.invalidBlurbs, this.props.selectedBlurb)
             && this.props.blurbLoadError) ?
             (<div> <BlurbContent /> <BlurbTemplate /> </div>) : <EmailPreview />)]
            : <BlurbPreview />}
          {this.props.shouldDisplayEmailPreview && <EmailTestSender />}
        </Col>
      </Row>
    );
  }

  renderMobilePushField() {
    return (
      <div>
        <Field
          name="mobilePush"
          id="mobilePush"
          component="input"
          type="checkbox"
          disabled={this.shouldFieldBeDisabled('mobilePush')}
        />
        {' '}
        <label htmlFor="mobilePush">Mobile Push</label>
        {this.props.mobilePush && <Field
          name="MobilePushText"
          label="Mobile Text"
          placeholder="You have a new message from Customer Service in your Message Center."
          validate={[requiredFieldValidation]}
          disabled={this.shouldFieldBeDisabled('MobilePushText')}
          component={InputField}
          componentClass="textarea"
          required
        />}
      </div>
    );
  }

  renderFields() {
    const { totalConcessionSuccessMessage, validatedJobId, isFetching, defaultFieldsForSelectedMarketplace } = this.props;
    const { CurrencyCode = '' } = defaultFieldsForSelectedMarketplace;
    return (
      <div>
        {this.props.shouldDisplayBlurb &&
        <BlurbLoaderInputField marketPlace={this.props.marketplace.id} />}
        {this.props.shouldDisplayHtmlBlurb &&
        <HtmlBlurbLoaderInputField marketPlace={this.props.marketplace.id} />}
        {this.props.formFields && this.props.formFields.map(field =>
          (<div>
            <Field
              key={field.name}
              name={field.name}
              label={field.name}
              required={!field.isOptional}
              disabled={this.shouldFieldBeDisabled(field.name)}
              validate={getFieldValidations(field)}
              component={InputField}
              componentClass={fileNameToComponentClass(field.name)}
              totalConcessionSuccessMessage={totalConcessionSuccessMessage}
              validatedJobId={validatedJobId}
              isFetching={isFetching}
            />
            {field.name === 'EstimatedTotalConcession' && <div>
              <b>Currency: </b>
              {CurrencyCode}
            </div>}
          </div>))}
        {this.props.shouldDisplayConcessionReasonsDropdown && <ConcessionReasonsDropdown />}
        {this.props.shouldDisplaySuppressEmailOption && SuppressEmailCheckbox}
        {this.props.shouldDisplayRefundToGcOption && RefundToGcCheckbox}
        {this.props.shouldDisplayMobilePush && this.renderMobilePushField()}
        {!this.props.shouldHideFileUpload && <FileRequirements />}
        {!this.props.shouldHideFileUpload && <FileRequirementsTemplate />}
        {!this.props.shouldHideFileUpload && <FileUpload />}
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Form key={this.props.validationJobId}>
        <Alert
            content={<AlertPeakReminder/>}
            type="warning"
        />
        <div>
          {this.props.isPlanOrderConditionDependent &&
            <Alert
              type="warning"
              content={WARNING_MESSAGE_FOR_CHANGE_ORDER_CONDITION_PLANS}
            />}
          {this.props.isPlanBlurbDependent &&
            <Alert
              type="warning"
              content={WARNING_MESSAGE_FOR_BLURB_DEPENDENT_PLANS}
            />}
          {this.props.isConcessionsPlanSelected &&
          <Alert
              type="warning"
              content={<AlertContentForConcessionPlans />} />
          }
        </div>
        <h2>Requirements</h2>
        {
          (this.props.shouldDisplayEmailPreview || this.props.shouldDisplayBlurbPreview)
          ? this.renderFieldsWithPreview()
          : this.renderFields()
        }
        <div>
          <RequiredFieldMessage />
          <PreviousButton onClick={this.handlePreviousButtonClick} />
          <NextButton disabled={this.shouldNextButtonBeDisabled()} onClick={handleSubmit} />
        </div>
      </Form>
    );
  }
}

RequirementsForm.defaultProps = {
  formFields: [],
  isFetching: false,
  concessionRecipeSelected: false,
};

const requirementsFormSelector = formValueSelector('newJobRequirements');

function mapStateToProps(state, props) {
  return {
    enteredJobId : selector(state, 'JobID'),
    validatedJobId : state.totalConcessionSuccessMessageReducer.validatedJobId,
    isFetching: state.totalConcessionSuccessMessageReducer.isFetching,
    isConcessionIAPlan : isSelectedPlanIAConcessionSelector(state),
    marketplace: selectedMarketplaceSelector(state),
    shouldDisplayBlurbPreview: isSelectedPlanBlurbDependentSelector(state),
    shouldDisplayEmailPreview: isSelectedPlanEmailDependentSelector(state),
    shouldDisplayBlurb: isSelectedPlanBlurbDependentSelector(state),
    shouldHideFileUpload: props.concessionRecipeSelected ? true : isConcessionPlanSelectedSelector(state),
    // shouldDisplayHtmlBlurb: isSelectedPlanHtmlBlurbDependentSelector(state),
    shouldDisplayHtmlBlurb: false, // TODO: use above line when supported by backend.
    shouldDisplayMobilePush: isSelectedPlanMobilePushDependentSelector(state),
    shouldDisplaySuppressEmailOption: props.concessionRecipeSelected ? false : shouldShowSuppressEmailOptionSelector(state),
    shouldDisplayRefundToGcOption: props.concessionRecipeSelected ? false : shouldShowRefundToGcOptionSelector(state),
    shouldDisplayConcessionReasonsDropdown: props.concessionRecipeSelected ? false : isSelectedPlanConcessionReasonDependentSelector(state),
    isPlanEmailDependent: isSelectedPlanEmailDependentSelector(state),
    isPlanBlurbDependent: isSelectedPlanBlurbDependentSelector(state),
    isPlanOrderConditionDependent: isSelectedPlanOrderConditionDepenedentSelector(state),
    isPlanCurrencyCodeDependent: isSelectedPlanCurrencyCodeDependentSelector(state),
    isConcessionsPlanSelected : isSelectedPlanConcessionReasonDependentSelector(state),
    defaultFieldsForSelectedMarketplace: defaultFieldsForSelectedMarketplaceSelector(state),
    concessionReason: selectedConcessionReasonSelector(state),
    mobilePush: requirementsFormSelector(state, 'mobilePush'),
    selectedBlurb: selectedBlurbForPreviewSelector(state),
    fileUploadStatus: state.newJob.file.upload.status,
    blurbs: availableBlurbsSelector(state),
    blurbLoadStatus: blurbLoadingStatusSelector(state),
    blurbLoadError: blurbLoadingErrorSelector(state),
    invalidBlurbs: blurbsWithoutMandatoryParametersSelector(state),
    formFields: props.concessionRecipeSelected ? formFieldForConcessionRecipe(state) : formFieldRequirementsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    resetForm: resetRequirementsForm,
    getConcessionReasons,
    totalConcessionAsyncValidation,
    getPlanName,
  }, dispatch);
}

RequirementsForm.defaultProps = {
  defaultFieldsForSelectedMarketplace: {
    CurrencyCode: '',
  },
};
// Using compose and defaultProps to make react-hot-loader 1.3.0 work with redux-form.
// And adding displayName for unit testing.
const connectedComponent = compose(
  defaultProps({}),
  reduxForm({
    asyncValidate: totalConcessionAsyncValidation,
    form: 'newJobRequirements',
    destroyOnUnmount: false,
    asyncBlurFields: ['JobID'],
    asyncChangeFields: ['JobID'],
    shouldAsyncValidate: shouldAsyncValidateRequirementsForm,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(RequirementsForm);

connectedComponent.displayName = 'Connect(Form(RequirementsForm))';
const selector = formValueSelector('newJobRequirements');

export default connectedComponent;
