import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchJobs, changeSearchQuery } from '../../redux/jobs/actions';
import LoaderInputField from '../../components/NewJob/LoaderInputField';

export class JobsSearchQueryField extends Component {
  render() {
    return (
      <LoaderInputField
        {...this.props}
        status={this.props.loadStatus}
        buttonLabel="Search"
      />
    );
  }
}

const mapStateToProps = state => ({
  value: state.jobs.searchParams.search_query,
  loadStatus: state.jobs.searchParams.loading_status,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSubmit: searchJobs,
    onChange: changeSearchQuery,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsSearchQueryField);
