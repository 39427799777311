import { createSelector } from 'reselect';
import _ from 'lodash';
import { formValueSelector } from 'redux-form';
import {actionRecipeID, communicationActionRecipeIDs, validationActionRecipeIDs} from '../../../helpers/constants';

const planFormSelector = formValueSelector('newJobPlan');

export const selectedPlanSelector = createSelector(
  state => state.newJob.plans,
  state => (planFormSelector(state, 'planId') || (state.totalConcessionSuccessMessageReducer && state.totalConcessionSuccessMessageReducer.selectedPlanId)),
  (plans, selectedPlanId) => {
    return _.find(plans, { id: selectedPlanId });
  },
);

export const planFileRequirementsSelector = createSelector(
  selectedPlanSelector,
  plan => _.filter((plan && plan.requirements) || [], { type: 'File' }),
);

export const planJobRequirementsSelector = createSelector(
  selectedPlanSelector,
  plan => _.filter((plan && plan.requirements) || [], { type: 'Job' }),
);

const concessionIntegratedApprovalActionRecipeIDs = [
  actionRecipeID.refundEGC2_0, // Refund E-GCs 2.0
  actionRecipeID.refundItems2_0, // Refund Items 2.0
];

export const isSelectedPlanIAConcessionSelector = createSelector(
    state => planFormSelector(state, 'planId'),
    (selectedPlanId) => _.includes(concessionIntegratedApprovalActionRecipeIDs, selectedPlanId),
);

export const isSelectedPlanCommunicationSelector = createSelector(
    state => planFormSelector(state, 'planId'),
    (selectedPlanId) => _.includes(communicationActionRecipeIDs, selectedPlanId),
);

export const isSelectedPlanValidation = createSelector(
    state => (state.totalConcessionSuccessMessageReducer && state.totalConcessionSuccessMessageReducer.selectedPlanId) || planFormSelector(state, 'planId'),
    (selectedPlanId) => _.includes(validationActionRecipeIDs, selectedPlanId),
);

const jobRequirementsThatWillNotBeDisplayed = [
  'MarketplaceID', // is being displayed elsewhere.
  'UserName', // Frontend get this from Sentry via HPS and sends it to HULK's backend.
  'User', // Frontend get this from Sentry via HPS and sends it to HULK's backend.
  'BlurbID', // Frontend shows different UI for this job requirement: BlurbLoaderInputField
  'BlurbName', // Frontend shows different UI for this job requirement: BlurbLoaderInputField
  'HtmlTemplateID', // Frontend shows different UI for this job requirement: HtmlBlurbLoaderInputField
  'MobilePushText', // Frontend shows UI differently for this job requirement.
  'Reason', // Frontend shows UI as a dropdown for this job requirement.
  'ReversalReason', // Frontend shows UI as a dropdown for this job requirement.
  'ReduceShipChargeReason', // Frontend shows UI as a dropdown for this job requirement.
  'SuppressCrowRefundConfirmationEmails', // Frontend shows UI as a checkbox for this job requirement.
  'RefundToGC', // Frontend shows UI as a checkbox for this job requirement.
];

export const formFieldRequirementsSelector = createSelector(
  planJobRequirementsSelector,
  jobRequirements => _.remove(jobRequirements,
    j => !_.includes(jobRequirementsThatWillNotBeDisplayed, j.name)),
);

export const formFieldsPlansAutoSelected = createSelector(
  planJobRequirementsSelector,
  jobRequirements => _.remove(jobRequirements,
    j => !_.includes(jobRequirementsThatWillNotBeDisplayed, j.name)),
);

export const formFieldForConcessionRecipe = () => ([{
  isOptional: false,
  name: 'JobID',
  type: 'Job',
}]);

export const formFieldRequirementNamesSelector = createSelector(
  formFieldRequirementsSelector,
  fields => fields.map(f => f.name),
);

export const isSelectedPlanBlurbDependentSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'BlurbID', // All blurb-dependent plans have this job parameter.
  ),
);

export const isSelectedPlanEmailDependentSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'FromAddress', // All email-dependent plans have this job parameter.
  ),
);

export const isSelectedPlanHtmlBlurbDependentSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'HtmlTemplateID', // All html blurb dependent plans have this job parameter.
  ),
);

export const isSelectedPlanMobilePushDependentSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'MobilePushText', // All mobile-dependent plans have this job parameter.
  ),
);

export const isSelectedPlanCurrencyCodeDependentSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'CurrencyCode',
  ),
);

export const isSelectedPlanOrderConditionDepenedentSelector = createSelector(
  planFileRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'Condition', // All plans containing Change Order Condition action
  ),
);

export const isSelectedPlanConcessionReasonDependentSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.some(
    ['Reason', 'ReversalReason', 'ReduceShipChargeReason'], value =>
    _.indexOf(requirements.map(r => r.name), value) >= 0,
  ),
);

export const isConcessionPlanSelectedSelector = createSelector(
    planJobRequirementsSelector,
    requirements => _.includes(
        requirements.map(r => r.name),
        'JobID', // All Concession Job plans have this job parameter.
    ),
);

export const shouldShowSuppressEmailOptionSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'SuppressCrowRefundConfirmationEmails', // Refund items plans
  ),
);

export const shouldShowRefundToGcOptionSelector = createSelector(
  planJobRequirementsSelector,
  requirements => _.includes(
    requirements.map(r => r.name),
    'RefundToGC', // Refund items plans
  ),
);
