import crypto from 'crypto';
import { NOT_SUBMITTED } from './constants';
import { getApprovalAPIRequestForApprovalDetails } from '../redux/jobs/jarvisConstants';
import { makeHulkProvokerServiceRequest } from './requestHelpers';

export const isMainJobAlreadyCreated = (approvalStatus) => (
  approvalStatus !== NOT_SUBMITTED
);

export const getMainJobId = (validationJobId) => {
  const crypto = require('crypto');
  const data = validationJobId + '-' + 'ExecutionJob';
  const hash = crypto.createHash('sha256');
  hash.update(data);
  const digest = hash.digest('hex');
  console.log('SHA256 digest:', digest);
  return digest;
};

export const getMainJobApprovalSummary = (mainJobId) => {
  const request = getApprovalAPIRequestForApprovalDetails(mainJobId);
  const response = makeHulkProvokerServiceRequest(request);
  return response; // Return the response synchronously
};
