
export const actionRecipeID = {
  refundCompleteShipmentValidation: '7e222d16-bf12-49ab-9573-2b8b9e827c5c',
  refundCompleteShipment2_0: '3da8e2c6-9f65-11e7-abc4-cec278b6b50a_2',
  refundCompleteShipment : '3da8e2c6-9f65-11e7-abc4-cec278b6b50a',
  refundCompleteShipmentAndMail : 'eae1690e-9f65-11e7-abc4-cec278b6b50a',
  refundCompleteShipmentAndMailValidation : '77617d9b-bb5c-44f4-b6ff-4df147cf0e6b',
  refundCompleteShipmentAndMail2_0 : 'eae1690e-9f65-11e7-abc4-cec278b6b50a_2',
  refundEGCValidation: 'fce74e52-175e-4cbb-b9cf-1b4f6cc6416d',
  refundEGC2_0: '57c4f316-9f65-11e7-abc4-cec278b6b50a_2',
  refundEGC: '57c4f316-9f65-11e7-abc4-cec278b6b50a',
  refundEGCAndMail: '2a52f760-9f66-11e7-abc4-cec278b6b50a',
  refundEGCAndMailValidation: '5291026a-dfb0-40f8-b0da-261ea7bc2cc4',
  refundEGCAndMail2_0: '2a52f760-9f66-11e7-abc4-cec278b6b50a_2',
  refundItemsValidation: '86cd11be-d047-4112-8e72-823403ebc169',
  refundItems2_0: '554c706a-ba17-11e7-abc4-cec278b6b50a_2',
  refundItemsAndMailValidation: '88881e18-7a5d-4aa1-a53f-a3f010e8600f',
  refundItemsAndMail2_0: '7c045fb5-ee5e-42cc-8c5a-004805e661ea_2',
  refundItemsAndMail: '7c045fb5-ee5e-42cc-8c5a-004805e661ea',
  refundItemsAndSMSValidation: '20c9f35d-5e0b-4ef9-8a45-4dc3115bdd37',
  refundItemsAndSMS2_0: 'a3d222d4-bdb2-11e9-9cb5-2a2ae2dbcce4_2',
  refundItemsAndSMS: 'a3d222d4-bdb2-11e9-9cb5-2a2ae2dbcce4',
  refundItems: '554c706a-ba17-11e7-abc4-cec278b6b50a',
  mailByContactId: 'abbbb6e0-9f64-11e7-abc4-cec278b6b50a',
  mailByOrderId: '478aa9e8-aa4a-11e6-80f5-76304dec7eb7',
  mailByCustomerId: 'f655bbfc-969f-11e6-ae22-56b6b6499611',
  smsByOrderId: 'c4ad53c0-9f64-11e7-abc4-cec278b6b50a'
};

export const communicationActionRecipeIDs = [
  actionRecipeID.mailByContactId,
  actionRecipeID.mailByOrderId,
  actionRecipeID.mailByCustomerId,
  actionRecipeID.smsByOrderId,
]

export const concession2_0RecipeIDS = [
  actionRecipeID.refundEGC2_0,
  actionRecipeID.refundItems2_0,
  actionRecipeID.refundCompleteShipment2_0,
  actionRecipeID.refundItemsAndMail2_0,
  actionRecipeID.refundItemsAndSMS2_0,
  actionRecipeID.refundCompleteShipmentAndMail2_0,
  actionRecipeID.refundEGCAndMail2_0
];

export const planIdForSkipValidation = {
  [actionRecipeID.refundEGCValidation]: actionRecipeID.refundEGC,
  [actionRecipeID.refundItemsValidation]: actionRecipeID.refundItems,
  [actionRecipeID.refundCompleteShipmentValidation]: actionRecipeID.refundCompleteShipment,
  [actionRecipeID.refundItemsAndMailValidation]: actionRecipeID.refundItemsAndMail,
  [actionRecipeID.refundItemsAndSMSValidation]: actionRecipeID.refundItemsAndSMS,
  [actionRecipeID.refundCompleteShipmentAndMailValidation]: actionRecipeID.refundCompleteShipmentAndMail,
  [actionRecipeID.refundEGCAndMailValidation]: actionRecipeID.refundEGCAndMail,
};

export const planIdForValidationAction = {
  [actionRecipeID.refundEGCValidation]: actionRecipeID.refundEGC2_0,
  [actionRecipeID.refundItemsValidation]: actionRecipeID.refundItems2_0,
  [actionRecipeID.refundCompleteShipmentValidation]: actionRecipeID.refundCompleteShipment2_0,
  [actionRecipeID.refundItemsAndMailValidation]: actionRecipeID.refundItemsAndMail2_0,
  [actionRecipeID.refundItemsAndSMSValidation]: actionRecipeID.refundItemsAndSMS2_0,
  [actionRecipeID.refundCompleteShipmentAndMailValidation]: actionRecipeID.refundCompleteShipmentAndMail2_0,
  [actionRecipeID.refundEGCAndMailValidation]: actionRecipeID.refundEGCAndMail2_0,
};

export const planNameForSkipValidation = {
  [actionRecipeID.refundEGCValidation]: 'Refund E-GCs 2.0',
  [actionRecipeID.refundItemsValidation]: 'Refund Items 2.0',
  [actionRecipeID.refundCompleteShipmentValidation]: 'Refund Complete Shipment 2.0',
  [actionRecipeID.refundItemsAndMailValidation]: 'Refund Items and Mail By OrderID 2.0',
  [actionRecipeID.refundItemsAndSMSValidation]: 'Refund Items and SMS By CustomerID 2.0',
  [actionRecipeID.refundCompleteShipmentAndMailValidation]: 'Refund Complete Shipment and Mail By OrderID 2.0',
  [actionRecipeID.refundEGCAndMailValidation]: 'Refund E-GCs and Mail By OrderID 2.0',
};

export const validationActionRecipeIDs = [
  actionRecipeID.refundItemsValidation, // Refund Item Validation Action RecipeID
  actionRecipeID.refundEGCValidation, // Refund E-GCs Validation Action RecipeID
  actionRecipeID.refundCompleteShipmentValidation, // Refund Complete Shipment Validation Action RecipeID
  actionRecipeID.refundItemsAndMailValidation, // Refund Items and Mail by OrderID Validation Action RecipeID
  actionRecipeID.refundItemsAndSMSValidation, // Refund Items and SMS By CustomerID Validation Action RecipeID
  actionRecipeID.refundCompleteShipmentAndMailValidation, // Refund Complete Shipment and Mail By OrderID Validation Action RecipeID
  actionRecipeID.refundEGCAndMailValidation, // Refund E-GCs and Mail By OrderID Validation Action RecipeID
];

export const infoMessages = {
  userAlias: 'To add multiple users use commas. e.g: alias1,alias2',
};

export const jobApproverFields = [
  {
    title: 'Job Approver Details',
    name: 'approverAlias',
    infoMessage: infoMessages.userAlias,
  },
  {
    title: 'Job Requestor Details',
    name: 'requestorAlias',
    infoMessage: infoMessages.userAlias,
  },
  {
    title: 'Business Approver Details',
    name: 'businessUserAlias',
    infoMessage: infoMessages.userAlias,
  },
  {
    title: 'Finance Approver Details',
    name: 'financeUserAlias',
    infoMessage: infoMessages.userAlias,
  },
];

export const NOT_SUBMITTED = 'NOT_SUBMITTED';
