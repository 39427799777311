import React, { Component } from 'react';
import { Spinner } from 'AWS-UI-Components-React'
import { connect } from 'react-redux';
import { Alert } from 'AWS-UI-Components-React';
import {HULK_ON_CALL_URL} from "../../externalLinks";
import {getJobApprovalDetailsPathForJob} from "../../routes";
import PreviousButton from "../../components/PreviousButton";
import withRouter from '../../helpers/withRouter';

export const AlertContent = (approvalApi) => (<div>
    There has been an error submitting your job. Please review the following: <b>"{approvalApi.exception}"</b>.
    If you have additional questions please<a
    href={HULK_ON_CALL_URL}
    target="_blank"
    rel="noopener noreferrer"
> page the HULK on-call</a>.
</div>);

export class LoadingSpinner extends Component {
    constructor(props) {
        super(props);
        this.previousStep = props.previousStep;
    }

    render() {
        const approvalJobDetails = this.props.approvalJobDetails;
        const submitJobDetails = this.props.submitJobDetails;
        if(approvalJobDetails.isLoadingApprovalApi || submitJobDetails.isLoadingSubmitApi) {
            return (
                <div className="awsui-util-t-c">
                    <Spinner className="awsui-util-mt-xxl" size="large"/>
                </div>
            );
        } else if (approvalJobDetails.exception || submitJobDetails.exception) {
            return (
                <div>
                    <div>
                        <Alert
                            //Display the exception message to the user (if any) from any of the Submit APIs.
                            content={AlertContent(approvalJobDetails.exception ? approvalJobDetails :
                                submitJobDetails)}
                            type="warning"
                        />
                    </div>
                    <br/>
                    <div>
                        <PreviousButton onClick={this.previousStep} />
                    </div>
                </div>
            )
        } else {
            const jobID = approvalJobDetails.jobID ? approvalJobDetails.jobID : submitJobDetails.jobID;
            this.props.router.navigate(getJobApprovalDetailsPathForJob(jobID));
        }
        return null;
    }
}

const mapStateToProps = state => ({
    approvalJobDetails: state.approvalJobDetails,
    submitJobDetails: state.submitJobDetails
});

export default withRouter(connect(mapStateToProps, null)(LoadingSpinner));
