import { combineReducers } from 'redux';
import loader from './loader';
import records from './records';
import activeJob from './activeJob';
import searchParams from './searchParams';
import approvalLoader from './approvalLoader';

const jobs = combineReducers({
  loader,
  records,
  activeJob,
  approvalLoader,
  searchParams,
});

export default jobs;
