import React, { Component } from 'react';
import { Field } from 'redux-form';
import { requiredFieldValidation } from '../fieldValidations';
import InputField from '../../../components/InputField';
import HorizontalDescriptionListGroup from '../../../components/HorizontalDescriptionListGroup';

class ApproverDetails extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { header, infoMessage, name, updateCurrentlySelectedApproval } = this.props;
    return (
      <HorizontalDescriptionListGroup header={header}>
        <Field
          name={name}
          label="User Alias"
          component={InputField}
          validate={requiredFieldValidation}
          required
          onFocus={(e) => updateCurrentlySelectedApproval(e.target.id)}
        />
        <div>{infoMessage}</div>
      </HorizontalDescriptionListGroup>
    );
  }
}

export default ApproverDetails;
