import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { hasMorePagesToLoadSelector } from '../../redux/jobs/selectors';
import { searchJobs } from '../../redux/jobs/actions';
import './JobIndex.scss';

export const JobPagination = (props) => {
  if (!props.hasMorePagesToLoad) {
    return (<div>There are no more jobs to be loaded</div>);
  }

  return (
    <div>
      <Button
        disabled={props.isLoading}
        onClick={props.searchJobs}
        className="normal-button"
      >Load more jobs</Button>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.jobs.loader.isLoading,
  hasMorePagesToLoad: hasMorePagesToLoadSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchJobs,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JobPagination);
