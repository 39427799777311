export const DEFAULT_MARKETPLACE = [
  {
    name: 'Select Marketplace to continue',
    id: 'default',
  }
];

export const NA_DEVO_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.com',
    id: 'ATVPDKIKX0DER',
  },
  {
    name: 'www.amazon.ca',
    id: 'A2EUQ1WTGCTBG2',
  },
  {
    name: 'www.amazon.com.br',
    id: 'AZXD3QD5B39HD',
  },
  {
    name: 'www.amazon.com.mx',
    id: 'A3P3J5A7D2ZVXI',
  },
  {
    name: 'www.amazon.com.co',
    id: 'A1NOHO22DE3OHQ',
  },
  {
    name: 'www.amazon.cl',
    id: 'A2IDBVIE7EAGN4',
  },
  {
    name: 'www.audible.com',
    id: 'A3G3TQ0ISIOPG8',
  },
  {
    name: 'www.audible.ca',
    id: 'A16QVBR1WHSTVN',
  },
  {
    name: 'Prime Now US',
    id: 'A25AZXLUQC00VX',
  },
  {
    name: 'Prime Now CA',
    id: 'A210MR9VJP84CZ',
  },
  {
    name: 'Amazon Prime Now.stores.amazon.com',
    id: 'A1TR4EXBSRW0XC',
  },
  {
    name: 'Prime Video ROW-NA',
    id: 'A3U49GLEJ1PS4Y',
  },
  {
    name: 'CBA US',
    id: 'A2624CKF0N40XQ',
  },
  {
    name: 'DPReview',
    id: 'A33AYUQ0D0VIBE',
  },
  {
    name: 'www.shopbop.com',
    id: 'AQIG1CWF2P8ZF',
  },
  {
    name: 'nafc.stores.amazon.com',
    id: 'A3QYJXEB80R0HD',
  },
  {
    name: 'local.amazon.com',
    id: 'A3B37DB0EHK9JT',
  },
  {
    name: 'localregister.stores.amazon.com',
    id: 'A15A49QYWX7788',
  },
  {
    name: 'woot.com',
    id: 'ABIJF3C8NHQ6M',
  },
  {
    name: 'wine.amazon.com',
    id: 'A1NF7LDSKVSYZ7',
  },
  {
    name: 'wireless.amazon.com',
    id: 'A1GA4GP79ABM4D',
  },
  {
    name: 'imdb.com',
    id: 'A1G03W8LWCC2P1',
  },
  {
    name: 'aws.amazon.com',
    id: 'A3U2NSBAPCSC3W',
  },
  {
    name: 'myhabit.com',
    id: 'A1T5QVC5JXV2VN',
  },
  {
    name: 'CSACentral.stores.amazon.com',
    id: 'A1GNBPPZBQ8VRO',
  },
  {
    name: 'apa.amazon.com',
    id: 'A35A4JO734ER04',
  },
  {
    name: 'aps.amazon.stores.amazon.com',
    id: 'A2DL4BHEQQ3CI0',
  },
  {
    name: 'ship.amazon.com',
    id: 'A1AJCQWNDAI0YN',
  },
  {
    name: '6pm.com',
    id: 'A1KWSSLRHP0Q7D',
  },
  {
    name: 'zappos.com',
    id: 'A1BU3DMPZUCBKG',
  },
  {
    name: 'zapposcouture.com',
    id: 'A2PVUI4FM1HZMJ',
  },
  {
    name: 'marketplace-kaspian-na.amazon.com',
    id: 'A2N5PC858MPPV8',
  },
];

export const EU_DEVO_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.co.uk',
    id: 'A1F83G8C2ARO7P',
  },
  {
    name: 'www.amazon.de',
    id: 'A1PA6795UKMFR9',
  },
  {
    name: 'www.amazon.fr',
    id: 'A13V1IB3VIYZZH',
  },
  {
    name: 'www.amazon.es',
    id: 'AJZF8LZ1EJVJN',
  },
  {
    name: 'www.amazon.in',
    id: 'A2XZLSVIQ0F4JT',
  },
  {
    name: 'www.amazon.it',
    id: 'A3HOBANJMCMD83',
  },
  {
    name: 'www.amazon.nl',
    id: 'A1M3WC0SJ3A38T',
  },
  {
    name: 'www.amazon.com.tr',
    id: 'A3CQBQD3RGPJR8',
  },
  {
    name: 'www.amazon.sa',
    id: 'A1MQPSGJ6U9Q54',
  },
  {
    name: 'www.amazon.se',
    id: 'AT614YYYNOC1S'
  },
  {
    name: 'www.amazon.pl',
    id: 'AKY4K4WKH21YQ'
  },
  {
    name: 'www.amazon.com.be',
    id: 'A1YFOMBPFBRY22'
  },
  {
    name: 'www.amazon.com.ng',
    id: 'A3PJZM0QIA8QDU'
  },
  {
    name: 'www.amazon.co.za',
    id: 'A3TFQ1G9L8TQ5L'
  },
  {
    name: 'www.audible.co.uk',
    id: 'A3G8SHMNK6Y7PK',
  },
  {
    name: 'www.audible.de',
    id: 'ABMF2NRWK6Q4Q',
  },
  {
    name: 'www.audible.fr',
    id: 'A1PM6UBHJK3SLV',
  },
  {
    name: 'www.audible.in',
    id: 'A2ITDZXD3G7XX6',
  },
  {
    name: 'www.audible.it',
    id: 'ARGSKDDBM3ISN',
  },
  {
    name: 'Prime Now UK',
    id: 'A31S5IEFAOLGXC',
  },
  {
    name: 'Prime Now DE',
    id: 'AVFDNTCG43SJ1',
  },
  {
    name: 'Prime Now FR',
    id: 'A3A7FBE29Z0H2Y',
  },
  {
    name: 'Prime Now ES',
    id: 'A3FQJJS6CFOOV1',
  },
  {
    name: 'Prime Now IT',
    id: 'A39EJH0AK2BYSI',
  },
  {
    name: 'Prime Video ROW-EU',
    id: 'A1D7Z662KHSYZY',
  },
  {
    name: 'Prime Video ROE-EU',
    id: 'A1MJEQWJOUEPEQ',
  },
  {
    name: 'CBA UK',
    id: 'A1L6JAWJ6GFJXF',
  },
  {
    name: 'CBA DE',
    id: 'A367Z95WXV2YM4',
  },
  {
    name: 'CBA IN',
    id: 'A3T3TIQ3S63ABA',
  },
  {
    name: 'BuyVIP DE',
    id: 'A18GPKEFX2L58H',
  },
  {
    name: 'BuyVIP ES',
    id: 'AUPVX9MSQPGCN',
  },
  {
    name: 'BuyVIP IT',
    id: 'A949ST7L1UTFX',
  },
  {
    name: 'junglee.com',
    id: 'A2RQ2X2B434ALC',
  },
  {
    name: 'CBA Junglee',
    id: 'A1N780XG1VTP5D',
  },
  {
    name: 'paymentsglobal-csc.amazon.com',
    id: 'A1GX9VJLFV4LJ5',
  },
  {
    name: 'apa.amazon.co.uk',
    id: 'A2UWQKHEFGNQY3',
  },
  {
    name: 'apa.amazon.de',
    id: 'A34RX6LWLXD2KA',
  },
  {
    name: 'apa.amazon.in',
    id: 'A18VPDB9UTK24D',
  },
  {
    name: 'ship.amazon.co.uk',
    id: 'A3UDV9872DJPPA',
  },
  {
    name: 'ship.amazon.in',
    id: 'A1EMWETFUIX9XN',
  },
  {
    name: 'accseu.stores.amazon.co.uk',
    id: 'A37NLEFCXJ41LE',
  },
  {
     name: 'local.amazon.co.uk',
     id: 'A26B8O6PHU3QR',
   },
  {
     name: 'Amazon.ae',
     id: 'A34GYYCZVDBSIK',
  },
  {
    name: 'www.amazonbusiness.in',
    id: 'A1D38IXLEJ6VQC',
  },
  {
    name: 'www.amazon.eg',
    id: 'AUJPM9XGFJRC7',
  },
  {
    name: 'amazonfreshstores.amazon.co.uk',
    id: 'A3GL64YS7K94H0',
  },
  {
    name: 'ukjaguar01',
    id: 'ACD0V2G7CTY2P',
  },
];

export const FE_DEVO_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.jp',
    id: 'A1VC38T7YXB528',
  },
  {
    name: 'www.amazon.com.au',
    id: 'A1RNPCQ4K8U27I',
  },
  {
    name: 'www.amazon.sg',
    id: 'A1DQ6JV7I20JSG',
  },
  {
    name: 'www.audible.co.jp',
    id: 'A298HDQ29ZA38N',
  },
  {
    name: 'www.audible.com.au',
    id: 'A2AT07N6OMIWFU',
  },
  {
    name: 'apa.amazon.co.jp',
    id: 'A2EFPHRCZ60KI3',
  },
  {
    name: 'Prime Now JP',
    id: 'A34XX9OGNJ8Z6T',
  },
  {
    name: 'Prime Now SG',
    id: 'A2QWGHQV78QQMU',
  },
  {
    name: 'Prime Video ROW-FE',
    id: 'A9QSUUOYRWVOG',
  },
];

export const CN_DEVO_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.cn',
    id: 'AAHKV2X7AFYLW',
  },
];

export const NA_PROD_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.com',
    id: 'ATVPDKIKX0DER',
  },
  {
    name: 'www.amazon.ca',
    id: 'A2EUQ1WTGCTBG2',
  },
  {
    name: 'www.amazon.com.br',
    id: 'A2Q3Y263D00KWC',
  },
  {
    name: 'www.amazon.com.mx',
    id: 'A1AM78C64UM0Y8',
  },
  {
    name: 'www.amazon.com.co',
    id: 'A1ZXRLSQW2V54D',
  },
  {
    name: 'www.amazon.cl',
    id: 'A2WDZJZ5TCRMLH',
  },
  {
    name: 'www.audible.com',
    id: 'AF2M0KC94RCEA',
  },
  {
    name: 'www.audible.ca',
    id: 'A2CQZ5RBY40XE',
  },
  {
    name: 'Prime Now US',
    id: 'A1IXFGJ6ITL7J4',
  },
  {
    name: 'Prime Now CA',
    id: 'AWUFL3O4Q4HZJ',
  },
  {
    name: 'Amazon Prime Now.stores.amazon.com',
    id: 'A1FI6AJ872SLSY',
  },
  {
    name: 'Prime Video ROW-NA',
    id: 'ART4WZ8MWBX2Y',
  },
  {
    name: 'AbeBooks COM',
    id: 'A3HJ3LHVHW6R8S',
  },
  {
    name: 'Amazon go US',
    id: 'A260JPYZJUXIHF',
  },
  {
    name: 'CBA US',
    id: 'AZ4B0ZS3LGLX',
  },
  {
    name: 'DPReview',
    id: 'AI2AE4A16O33',
  },
  {
    name: 'www.shopbop.com',
    id: 'A2817T8MKXE20N',
  },
  {
    name: 'splashbaths.webstorepowered.com',
    id: 'A2PO04FIBKA0V6',
  },
  {
    name: 'the-independent-texan-press.hostedbyamazon.com',
    id: 'A3QW0Q8L3Z6D3Q',
  },
  {
    name: 'nafc.stores.amazon.com',
    id: 'AGQQ0A1HY8X7V',
  },
  {
    name: 'local.amazon.com',
    id: 'A2OZJSJ3C1QC02',
  },
  {
    name: 'localregister.stores.amazon.com',
    id: 'A23YMMVOQY6KRT',
  },
  {
    name: 'woot.com',
    id: 'A22ZUEY6FG8RY',
  },
  {
    name: 'wine.amazon.com',
    id: 'A2HM9SEN36TS7U',
  },
  {
    name: 'wireless.amazon.com',
    id: 'A2YXG7NG141OP2',
  },
  {
    name: 'imdb.com',
    id: 'A1EVAM02EL8SFB',
  },
  {
    name: 'aws.amazon.com',
    id: 'A12QK8IU0H0XW5',
  },
  {
    name: 'myhabit.com',
    id: 'A39WRC2IB8YGEK',
  },
  {
    name: 'amazonbooks.amazon.com',
    id: 'A28LUFWO5HYYIZ',
  },
  {
    name: 'amazonregistry.com',
    id: 'A25NTRYU0OUV0L',
  },
  {
    name: 'amazonsupply.com',
    id: 'A2YBZOQLHY23UT',
  },
  {
    name: 'CSACentral.stores.amazon.com',
    id: 'AYZ4TAHMVXJLX',
  },
  {
    name: 'csc-trans-isp-na.stores.amazon.com',
    id: 'A3G6ICTLM20UQX',
  },
  {
    name: 'deepone.hostedbyamazon.com',
    id: 'A2LPTH3J8119AA',
  },
  {
    name: 'jam-llc.hostedbyamazon.com',
    id: 'A1T0F3SY1TPEAY',
  },
  {
    name: 'loading-dock.webstorepowered.com',
    id: 'AM5XBVGNQNKHG',
  },
  {
    name: 'serpents-kiss.hostedbyamazon.com',
    id: 'A30DUSGFTNV3ZQ',
  },
  {
    name: 'apa.amazon.com',
    id: 'AGWSWK15IEJJ7',
  },
  {
    name: 'aps.amazon.stores.amazon.com',
    id: 'A3C66CJ8C1SW1I',
  },
  {
    name: 'ship.amazon.com',
    id: 'A2BR6UVHX99FEC',
  },
  {
    name: '6pm.com',
    id: 'ALYICPJ31MJ6Q',
  },
  {
    name: 'zappos.com',
    id: 'APH27RIBNWMOI',
  },
  {
    name: 'zapposcouture.com',
    id: 'A38DPNFLOPUVDX',
  },
  {
    name: 'FPA.beautybar.com',
    id: 'A1XL214U7JA46G',
  },
  {
    name: 'FPA.casa.com',
    id: 'A3I52P3BRBPQB8',
  },
  {
    name: 'FPA.diapers.com',
    id: 'A133VYBJYE8OXU',
  },
  {
    name: 'FPA.soap.com',
    id: 'A2IBI0CA6RLSHR',
  },
  {
    name: 'FPA.wag.com',
    id: 'A18RULUF6B5AN8',
  },
  {
    name: 'FPA.yoyo.com',
    id: 'A3T155I11LQFV7',
  },
  {
    name: 'tva.afterschool.com',
    id: 'A3GVQO73QG4HLY',
  },
  {
    name: 'tva.bookworm.com',
    id: 'A2PTOWNQL7HN7E',
  },
  {
    name: 'tva.macsales.com',
    id: 'ANBJ0JT9LAPZ4',
  },
  {
    name: 'tva.shopbop.com',
    id: 'A3SW0KBROV2X6Q',
  },
  {
    name: 'tva.vine.com',
    id: 'A2ZN6VKQ29XW2B',
  },
  {
    name: 'tva.woot.com',
    id: 'A97B0XXD56IZ1',
  },
  {
    name: 'urmp-2-prod.amazon.com',
    id: 'ABFCYI119Q7RV',
  },
  {
    name: 'urmp-3-prod.amazon.com',
    id: 'AKYDKPSGVMMUD',
  },
  {
    name: 'urmp-4-prod.amazon.com',
    id: 'A3IN3V5ELTHSS1',
  },
  {
    name: 'urmp-5-prod.amazon.com',
    id: 'A1UI7O2AITRR2Q',
  },
  {
    name: 'urmp-7-prod.amazon.com',
    id: 'AH4LQL64AIXN4',
  },
  {
    name: 'marketplace-kaspian-na.amazon.com',
    id: 'ACQK4JSLMBLPI',
  },
];

export const EU_PROD_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.co.uk',
    id: 'A1F83G8C2ARO7P',
  },
  {
    name: 'www.amazon.de',
    id: 'A1PA6795UKMFR9',
  },
  {
    name: 'www.amazon.fr',
    id: 'A13V1IB3VIYZZH',
  },
  {
    name: 'www.amazon.es',
    id: 'A1RKKUPIHCS9HS',
  },
  {
    name: 'www.amazon.in',
    id: 'A21TJRUUN4KGV',
  },
  {
    name: 'www.amazon.it',
    id: 'APJ6JRA9NG5V4',
  },
  {
    name: 'www.amazon.nl',
    id: 'A1805IZSGTT6HS',
  },
  {
    name: 'www.amazon.com.tr',
    id: 'A33AVAJ2PDY3EV',
  },
  {
    name: 'www.amazon.sa',
    id: 'A17E79C6D8DWNP',
  },
  {
    name: 'www.amazon.se',
    id: 'A2NODRKZP88ZB9',
  },
  {
    name: 'www.amazon.pl',
    id: 'A1C3SOZRARQ6R3'
  },
  {
    name: 'www.amazon.com.be',
    id: 'AMEN7PMS3EDWL'
  },
  {
    name: 'www.amazon.com.ng',
    id: 'A3OCL2LJ6GDH9T'
  },
  {
    name: 'www.amazon.co.za',
    id: 'AE08WJ6YKNBMC'
  },
  {
    name: 'www.audible.co.uk',
    id: 'A2I9A3Q2GNFNGQ',
  },
  {
    name: 'www.audible.de',
    id: 'AN7V1F1VY261K',
  },
  {
    name: 'www.audible.fr',
    id: 'A2728XDNODOQ8T',
  },
  {
    name: 'www.audible.in',
    id: 'AJO3FBRUE6J4S',
  },
  {
    name: 'www.audible.it',
    id: 'A2N7FU2W2BU2ZC',
  },
  {
    name: 'Prime Now UK',
    id: 'AM7DNVYQULIQ5',
  },
  {
    name: 'Prime Now DE',
    id: 'A1KU16HT7ALXJ0',
  },
  {
    name: 'Prime Now FR',
    id: 'A2905VW864VWWF',
  },
  {
    name: 'Prime Now ES',
    id: 'ACDNLAE5F4JT1',
  },
  {
    name: 'Prime Now IT',
    id: 'A1TERGVA4U2MLK',
  },
  {
    name: 'Prime Video ROW-EU',
    id: 'A2MFUE2XK8ZSSY',
  },
  {
    name: 'Prime Video ROE-EU',
    id: 'A3K6Y4MI8GDYMT',
  },
  {
    name: 'CBA UK',
    id: 'A2WQPBGJ59HSXT',
  },
  {
    name: 'CBA DE',
    id: 'A1OCY9REWJOCW5',
  },
  {
    name: 'CBA IN',
    id: 'A3PY9OQTG31F3H',
  },
  {
    name: 'BuyVIP DE',
    id: 'A38GABX06X24K',
  },
  {
    name: 'BuyVIP ES',
    id: 'A2TG6KGE1SCY95',
  },
  {
    name: 'BuyVIP IT',
    id: 'A3E7MN3FRLVK93',
  },
  {
    name: 'AbeBooks UK',
    id: 'A1GH8JQC28KQMO',
  },
  {
    name: 'AbeBooks DE',
    id: 'A10A3N4DLO63GE',
  },
  {
    name: 'AbeBooks FR',
    id: 'A3XA1DPPGYA9H',
  },
  {
    name: 'AbeBooks ES',
    id: 'A1MHIT7LTD3TXB',
  },
  {
    name: 'AbeBooks IT',
    id: 'A14JTEK4O1XNK4',
  },
  {
    name: 'junglee.com',
    id: 'A3M22N3OY5KY7Q',
  },
  {
    name: 'CBA Junglee',
    id: 'A2UTKXGQLHXMO',
  },
  {
    name: 'paymentsglobal-csc.amazon.com',
    id: 'A3RGCJYTOPRINC',
  },
  {
    name: 'apa.amazon.co.uk',
    id: 'AZAJMM36N6WQL',
  },
  {
    name: 'apa.amazon.de',
    id: 'A53RDEWN57UU5',
  },
  {
    name: 'apa.amazon.in',
    id: 'A3FDG49KKM823Y',
  },
  {
    name: 'ship.amazon.co.uk',
    id: 'AGJTXQVCWGLM9',
  },
  {
    name: 'ship.amazon.in',
    id: 'A2ACQFO88DPSZS',
  },
  {
    name: 'accseu.stores.amazon.co.uk',
    id: 'A1L4CO7C8TPH76',
  },
  {
    name: 'local.amazon.co.uk',
    id: 'AMRPEKZ6O67O3',
  },
  {
    name: 'Amazon.ae',
    id: 'A2VIGQ35RCS4UG',
  },
  {
    name: 'www.amazonbusiness.in',
    id: 'A2D32KE73PNS33',
  },
  {
    name: 'www.amazon.eg',
    id: 'ARBP9OOSHTCHU'
  },
  {
    name: 'amazonfreshstores.amazon.co.uk',
    id: 'A3P3DP59YHPL03',
  },
  {
    name: 'ukjaguar01.amazon.co.uk',
    id: 'A15OR4562BFFUV',
  },
];

export const FE_PROD_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.jp',
    id: 'A1VC38T7YXB528',
  },
  {
    name: 'www.amazon.com.au',
    id: 'A39IBJ37TRP1C6',
  },
  {
    name: 'www.amazon.sg',
    id: 'A19VAU5U5O7RUS',
  },
  {
    name: 'www.audible.co.jp',
    id: 'A1QAP3MOU4173J',
  },
  {
    name: 'www.audible.com.au',
    id: 'AN7EY7DTAW63G',
  },
  {
    name: 'apa.amazon.co.jp',
    id: 'A75P1MG8VMU51',
  },
  {
    name: 'Prime Now JP',
    id: 'A2MKBGGTHABQEV',
  },
  {
    name: 'Prime Now SG',
    id: 'AUK5T4I7X4ZCF',
  },
  {
    name: 'Prime Video ROW-FE',
    id: 'A15PK738MTQHSO',
  },
];

export const CN_PROD_AVAILABLE_MARKETPLACES = [
  {
    name: 'www.amazon.cn',
    id: 'AAHKV2X7AFYLW',
  },
];
