import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HelpBlock, Panel } from 'react-bootstrap';
import { UPLOADING, UPLOADED } from '../../../redux/newJob/file/uploadStatus';
import SelectedFileButtonToolbar from './SelectedFileButtonToolbar';
import { defaultFieldsForSelectedMarketplaceSelector } from '../../../redux/marketplace/selectors';
import { isSelectedPlanCurrencyCodeDependentSelector } from '../../../redux/newJob/plans/selectors';

export class SelectedFile extends Component {
  render() {
    const { file } = this.props;
    if (file.value === null) { return null; }

    const { status, error } = file.upload;
    let concessionValue = null;
    let currency = null;
    if (file.parsedContent != null && file.parsedContent.metadata != null) {
      concessionValue = file.parsedContent.metadata.concessionValue;
      currency = this.props.defaultFieldsForSelectedMarketplace.CurrencyCode;
    }

    let size = null;
    if (file.parsedContent != null && file.parsedContent.metadata != null) {
      size = file.parsedContent.metadata.size;
    }

    return (
      <Panel>
        <Panel.Body>
          {file.value.name}
          {!!size && (
          <b>
            <br />
            Number of Records in the File :
            {' '}
            {size}
          </b>
          )}
          {!!concessionValue && (
          <b>
            <br />
            Amount of Concession that will be issued :
            {' '}
            {concessionValue}
            {' '}
            {currency}
            <br />
          </b>
          )}
          {status === UPLOADING && <HelpBlock>Uploading</HelpBlock>}
          {!!error && <HelpBlock>{error}</HelpBlock>}
          {status === UPLOADED && <HelpBlock>File uploaded successfully.</HelpBlock>}
        </Panel.Body>
        <Panel.Footer>
          <SelectedFileButtonToolbar />
        </Panel.Footer>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    file: state.newJob.file,
    defaultFieldsForSelectedMarketplace: defaultFieldsForSelectedMarketplaceSelector(state),
  };
}

export default connect(mapStateToProps)(SelectedFile);
