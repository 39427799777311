import axios from 'axios';
import {
  HULK_PROVOKER_SERVICE_ENDPOINT,
} from '../endpoints';

export function makeRequest(reqObjParam) {
  return axios({
    method: 'get',
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' },
    data: null,
    ...reqObjParam,
  });
}

export function makeHulkProvokerServiceRequest(reqObjParam) {
  return makeRequest({
    ...reqObjParam,
    url: HULK_PROVOKER_SERVICE_ENDPOINT + reqObjParam.url,
    withCredentials: true,
  });
}

export function makeRequestForPost(reqObjParam) {
    return axios({
        method: 'post',
        withCredentials: false,
        headers: { 'Content-Type': 'application/json' },
        data: null,
        ...reqObjParam,
    });
}

export function makeHulkProvokerServiceRequestForPost(reqObjParam) {
    return makeRequestForPost({
        ...reqObjParam,
        url: HULK_PROVOKER_SERVICE_ENDPOINT + reqObjParam.url,
        withCredentials: true,
    });
}

export function openInNewTab(url) {
  window.open(url, '_blank');
}

export function getParameterByName(name) {
    const url = window.location.href;
    const qs = url.substring(url.indexOf('?') + 1).split('&');
    const result = {};
    for(let i = 0; i < qs.length; i++) {
        qs[i] = qs[i].split('=');
        result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return (result[name] || '');
}

