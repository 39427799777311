import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, ControlLabel } from 'react-bootstrap';

import './RequiredFieldIndicator.scss';

export default class DropdownInputField extends Component {
  render() {
    const {
      name,
      label,
      placeholder,
      options,
      required,
      searchable,
      clearable,
      } = this.props;

    const bsClass = classNames({
      'form-group': true,
      required,
    });

    return (
      <FormGroup bsClass={bsClass} controlId={name}>
        <ControlLabel>{label}</ControlLabel>
        <Select
          placeholder={placeholder}
          options={options}
          value={this.props.value}
          onChange={e => this.props.onChange(e.value)}
          searchable={searchable}
          clearable={clearable}
        />
      </FormGroup>
    );
  }
}

DropdownInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
};

DropdownInputField.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  options: [],
  required: false,
  searchable: false,
  clearable: false,
};
