import React, { Component, useEffect} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { BreadcrumbGroup, BreadcrumbItem, Badge, Button, Checkbox, Alert, Spinner, Modal
} from 'AWS-UI-Components-React';
import { getHumanFriendlyDateTime } from '../helpers/dateHelpers';
import withRouter from '../helpers/withRouter';
import { getMarketplaceName } from '../helpers/marketplaceHelpers';
import { getCommaSeparatedApprovers } from '../helpers/approverListHelpers';
import {
  getJobDetails,
  getJobExecutionSummary,
  getChunkProcessingWorkflowStatus,
  getJobApprovalSummary,
} from '../redux/jobs/actions';
import { resetRequirementsForm } from '../redux/newJob/actions';
import {
  isMainJobAlreadyCreated,
  getMainJobId,
  getMainJobApprovalSummary
} from '../helpers/mainJobHelpers';
import { getDownloadJobExecutionReportLink } from '../redux/jobs/jarvisConstants';
import {
  aggregatedActiveJobSelector,
  completedRecordProcessingWorkflowsCountSelector,
  jobStatusSelector,
  createdRecordProcessingWorkflowsCountSelector,
  inProgressRecordProcessingWorkflowsCountSelector,
  jobApprovalStatusSelector,
} from '../redux/jobs/selectors';
import { availableMarketplacesSelector } from '../redux/marketplace/selectors';
import { Dictionary, ActionStripe } from '../components/AWSInspiredComponents';
import { jobStatusType } from '../redux/jobs/types';
import { HULK_ON_CALL_URL, REPORT_INTERPRETATION_WIKI_LINK } from '../externalLinks';
import { openInNewTab } from '../helpers/requestHelpers';
import { INTEGRATED_APPROVALS_LINK } from '../endpoints';
import { getSkipValidationPath } from '../routes';
import {
  planIdForValidationAction,
  planNameForSkipValidation,
  validationActionRecipeIDs
} from '../helpers/constants';

// TODO: Refactor this once other pages uses it.
export const JobDetailsBreadcrumb = props => (
  <BreadcrumbGroup>
    <BreadcrumbItem text="Jobs" href="#/jobs" />
    <BreadcrumbItem text={props.jobID} href={`#/jobs/${props.jobID}`} />
  </BreadcrumbGroup>
);

export const JobExecutionReportDownloadComponent = props => (
  <div>
    <Button variant="primary" onClick={props.onClick} text="Download Report" />
    &nbsp;
    <a href={REPORT_INTERPRETATION_WIKI_LINK} target="_blank" rel="noopener noreferrer">
      How to interpret the report?
    </a>
  </div>
);

export const JobReportContinueButton = (props) => {
  if (props.jobFailed) return null;
  return (
    <div>
      <Button
        variant="primary"
        onClick={props.onClick}
        disabled={!props.jobCompleted}
        text={props.jobCompleted ? 'Continue' : 'Please wait...'}
      />
    </div>
  );
};

export const DownloadReportConfirmationDialogBox = props => (
  <Modal
    content="Your job is not complete yet, report may contain in progress records. Do you want to proceed?"
    header="Download Report"
    visible={props.showDownloadReportConfirmation}
    footer={<span className="awsui-util-f-r">
      <Button variant="link" onClick={props.onCancelClick}>Cancel</Button>
      <Button variant="primary" onClick={props.onDownloadClick}>Download</Button>
    </span>}
  />
);

export const createLinkForTicketId = ttID => (
  <a href={`https://tt.amazon.com/${ttID}`} target="_blank" rel="noopener noreferrer">
    {ttID}
  </a>
);

export const JobStatusErrorMessage = (props) => {
  if (props.jobStatus === jobStatusType.IN_PROGRESS_WITH_ERRORS) {
    return (<Alert
      header={<div> Something went wrong. </div>}
      content={<div><b> Do not submit a duplicate job</b>. If this job never completes, <a
        href={HULK_ON_CALL_URL}
        target="_blank"
        rel="noopener noreferrer"
      >contact Hulk oncall</a>.</div>}
      type="error"
    />);
  }

  return null;
};

export const mapPropsToJobExecutionSummaryDictionaryItems = props => ([
  {
    key: 'Total Records',
    value: props.job.totalNumberOfRecords,
  },
  {
    key: 'Scheduled',
    value: props.inQueueRecordsCount,
  },
  {
    key: 'In Progress',
    value: props.inProgressRecordsCount,
  },
  {
    key: 'Completed',
    value: props.completedJobExecutionSummaryCount,
  },
]);

const getBlurbNameForDisplay = (blurbName) => {
  if (blurbName == null) {
    return '-';
  }
  return blurbName;
};

const getTagsForDisplay = (tags) => {
  if (tags == null) {
    return null;
  }
  if (tags.length === 0) {
    return '-';
  }
  return _.join(tags, ', ');
};

export const mapJobToJobInformationDictionaryItems = ({ job, jobStatus, availableMarketplaces }) => ([
  {
    key: 'Status',
    value: <Badge color="grey" content={jobStatus} />,
  },
  {
    key: 'Job ID',
    value: job.jobID,
  },
  {
    key: 'Plan name',
    value: job.planName,
  },
  {
    key: 'Created by',
    value: job.createdBy,
  },
  {
    key: 'Approvers',
    value: getCommaSeparatedApprovers(job.approvalSummary.approvers),
  },
  {
    key: 'Creation time',
    value: getHumanFriendlyDateTime(job.approvalSummary.jobSubmissionTime),
  },
  {
    key: 'Submission time',
    value: getHumanFriendlyDateTime(job.creationTime),
  },
  {
    key: 'Marketplace',
    value: getMarketplaceName(job.marketplaceID, availableMarketplaces),
  },
  {
    key: 'Blurb',
    value: getBlurbNameForDisplay(job.blurbName),
  },
  // TODO: display once backend provides this information.
  // {
  //   key: 'TT ID',
  //   value: createLinkForTicketId(job.ttID),
  // },
  {
    key: 'Tags',
    value: getTagsForDisplay(job.tags),
  },
]);

export const mapJobToJobApprovalInformationDictionaryItems = (approvalSummary, availableMarketplaces) => ([
  {
    key: 'Status',
    value: <Badge color="grey" content={approvalSummary.approvalStatus} />,
  },
  {
    key: 'Job ID',
    value: approvalSummary.jobId,
  },
  {
    key: 'Approval Link',
    value: <a href={INTEGRATED_APPROVALS_LINK+approvalSummary.approvalID} target="_blank"> Link </a>
  },
  {
    key: 'Approval ID',
    value: approvalSummary.approvalID,
  },
  {
    key: 'Approvers',
    value: getCommaSeparatedApprovers(approvalSummary.approvers),
  },
  {
    key: 'Plan name',
    value: approvalSummary.planName,
  },
  {
    key: 'Created by',
    value: approvalSummary.createdBy,
  },
  {
    key: 'Creation time',
    value: getHumanFriendlyDateTime(approvalSummary.jobSubmissionTime),
  },
  {
    key: 'Marketplace',
    value: getMarketplaceName(approvalSummary.marketplaceID, availableMarketplaces),
  },
  {
    key: 'Blurb',
    value: approvalSummary.blurbName,
  },
]);

export const JobDetailsActions = props => (
  <div>
    <Checkbox
      className="awsui-util-d-ib awsui-util-mh-m"
      label="Auto refresh"
      checked={props.autoRefresh}
      onChange={props.handleToggleAutoRefresh}
    />

    <Button
      text="Refresh"
      variant="normal"
      disabled={props.isLoading}
      icon="refresh"
      loading={props.isLoading}
      onClick={props.handleRefreshJobDetails}
    />
  </div>
);

const AUTO_REFRESH_INTERVAL = 15000; // milliseconds = 15 seconds

const ACTION_RETRY_INTERVAL = 3000; // milliseconds = 3 seconds

const isAnyPageSectionLoading = ({ job }) => (
  job.isLoadingApprovalSummary || job.isLoadingJobDetails
  || job.executionSummary.isLoading || job.chunkProcessingWorkflowStatus.isLoading
);

const isAllJobDataFromJarvisLoaded = ({ job }) => (
    job.isJobDataLoaded && job.executionSummary.isExecutionDataLoaded && job.chunkProcessingWorkflowStatus.isChunkDataLoaded
);

const isJobApproved = ({ jobApprovalStatus }) => (
    jobApprovalStatus == "APPROVED"
);

// We are returning "NOT_SUBMITTED" in Approval status from HPS if job was not submitted through IA workflow
// Ref - https://tiny.amazon.com/19qaiztd0/simamazissuConP
const isJobSubmittedWithoutIA = ({ jobApprovalStatus }) => (
    jobApprovalStatus == "NOT_SUBMITTED"
);

export class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.refreshJobDetaills = this.refreshJobDetaills.bind(this);
    this.toggleAutoRefresh = this.toggleAutoRefresh.bind(this);
    this.shouldAutoRefreshJobDetails = this.shouldAutoRefreshJobDetails.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.closeDownloadReportConfirmationDialogBox = this.closeDownloadReportConfirmationDialogBox.bind(this);
    this.confirmDownloadReport = this.confirmDownloadReport.bind(this);

    this.state = {
      autoRefresh: false,
      mainJobId: null,
      showDownloadReportConfirmation: false,
      isNewJobRefresh: false,
    };

    this.timer = setInterval(() => {
      if (isAnyPageSectionLoading(this.props)) {
        this.refreshJobDetaills();
      }
    }, ACTION_RETRY_INTERVAL);

    this.timer = setInterval(() => {
      if (this.shouldAutoRefreshJobDetails()) {
        this.refreshJobDetaills();
      }
    }, AUTO_REFRESH_INTERVAL);
  }

  // componentWillMount is deprecated
  componentDidMount() {
    if(!this.state.mainJobId) {
      const mainJobId = getMainJobId(this.props.router.params.jobId);
      this.setState({ mainJobId });
    }
    this.refreshJobDetaills();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  shouldAutoRefreshJobDetails() {
    return this.state.autoRefresh && this.props.jobStatus !== jobStatusType.COMPLETED;
  }

  toggleAutoRefresh() {
    this.setState({
      autoRefresh: !this.state.autoRefresh,
    });
  }

  refreshJobDetaills() {
    const params = this.props.router.params;
    if (params && params.jobId) {
      // Check if approval summary is already present to avoid unnecessary calls for approval information
      if (!this.props.approvalSummary || this.state.isNewJobRefresh) {
        this.props.getJobApprovalSummary(params.jobId);
      }
      // If job is approved or job was submitted without IA workflow, fetch data from Jarvis
      if (isJobApproved(this.props) || isJobSubmittedWithoutIA(this.props)) {
        this.props.getJobDetails(params.jobId);
        this.props.getJobExecutionSummary(params.jobId);
        this.props.getChunkProcessingWorkflowStatus(params.jobId);
      }
    }
  }

  downloadReport() {
    if (this.props.jobStatus !== jobStatusType.COMPLETED) {
      this.setState({
        showDownloadReportConfirmation: true,
      });
    } else {
      openInNewTab(getDownloadJobExecutionReportLink(this.props.job.jobID, this.props.job.creationTime));
    }
  }

  handleRedirect() {
    this.props.resetRequirementsForm();
    const mainJobDetailsUrl = `/jobs/${this.state.mainJobId}`;
    const jobDetails = {
      jobId: this.props.job.jobID,
      planName: planNameForSkipValidation[this.props.job.planId],
      actionRecipeId: planIdForValidationAction[this.props.job.planId],
    };
    getMainJobApprovalSummary(this.state.mainJobId).then((response) => {
      const mainJobApprovalStatus = response.data.approvalStatus;
      if (
        mainJobApprovalStatus
        && isMainJobAlreadyCreated(mainJobApprovalStatus)
      ) {
        this.props.router.navigate(mainJobDetailsUrl);
        this.setState({
          isNewJobRefresh: true,
        });
        this.refreshJobDetaills();
      } else {
        this.props.router.navigate(getSkipValidationPath(jobDetails), {
          state: jobDetails,
        });
      }
    });
  }

  closeDownloadReportConfirmationDialogBox() {
    this.setState({
      showDownloadReportConfirmation: false,
    });
  }

  confirmDownloadReport() {
    openInNewTab(getDownloadJobExecutionReportLink(this.props.job.jobID, this.props.job.creationTime));
    this.closeDownloadReportConfirmationDialogBox();
  }

  render() {
    const job = this.props.job;

    // If any page is loading, view spinner
    if (isAnyPageSectionLoading(this.props)) {
      return (
        <div>
          <JobDetailsBreadcrumb jobID={job.jobID} />
          <div className="awsui-util-t-c">
            <Spinner className="awsui-util-mt-xxl" size="large" />
          </div>
        </div>);
    }

    const detailsActions = (<JobDetailsActions
        isLoading={false}
        handleRefreshJobDetails={this.refreshJobDetaills}
        autoRefresh={this.state.autoRefresh}
        handleToggleAutoRefresh={this.toggleAutoRefresh}
      />);
    // If Approval status is pending and job was submitted through IA workflow, only show approval details/page
    if (!isJobApproved(this.props) && !isJobSubmittedWithoutIA(this.props)) {
      return (
        <div>
          <JobDetailsBreadcrumb jobID={this.props.job.jobId} />
          <ActionStripe title={this.props.job.approvalSummary.jobName} group={detailsActions} />
          <Dictionary
            header="Job Approval Information"
            isLoading={job.isLoadingApprovalSummary}
            columns={3}
            borders="vertical"
            items={mapJobToJobApprovalInformationDictionaryItems(this.props.job.approvalSummary, this.props.availableMarketplaces)}
          />
        </div>
      );
    }

    const JobReportFooter = (props) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <JobExecutionReportDownloadComponent {...this.props} onClick={this.downloadReport} />
        {props.isValidationJob && <JobReportContinueButton {...this.props} onClick={this.handleRedirect} jobCompleted={this.props.jobStatus === 'COMPLETED'} jobFailed={this.props.jobStatus === 'FAILED'} />}
      </div>
    );


    //If the job has been approved or the job has been submitted without IA workflow, show the job and its execution details
    if( isJobApproved(this.props) || isJobSubmittedWithoutIA(this.props) ) {
        //If all the data from Jarvis is loaded, stop making queries
        if(!isAllJobDataFromJarvisLoaded(this.props)) {
            this.refreshJobDetaills();
        }
        const isValidationJob = _.includes(validationActionRecipeIDs, job.planId);
        return (
          <div>
            <DownloadReportConfirmationDialogBox
              showDownloadReportConfirmation={this.state.showDownloadReportConfirmation}
              onCancelClick={this.closeDownloadReportConfirmationDialogBox}
              onDownloadClick={this.confirmDownloadReport}
            />
            <JobDetailsBreadcrumb jobID={job.jobID} />
            <ActionStripe title={job.jobName} group={detailsActions} />
            <JobStatusErrorMessage {...this.props} />
            <Dictionary
              header="Job information"
              isLoading={job.isLoadingJobDetails}
              columns={3}
              borders="vertical"
              items={mapJobToJobInformationDictionaryItems(this.props)}
            />
            <Dictionary
              header="Job execution summary"
              isLoading={job.executionSummary.isLoading}
              columns={4}
              borders="vertical"
              items={mapPropsToJobExecutionSummaryDictionaryItems(this.props)}
              footer={<JobReportFooter isValidationJob={isValidationJob} />}
            />
          </div>
        );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  jobApprovalStatus: jobApprovalStatusSelector(state),
  job: aggregatedActiveJobSelector(state),
  completedJobExecutionSummaryCount: completedRecordProcessingWorkflowsCountSelector(state),
  jobStatus: jobStatusSelector(state),
  inQueueRecordsCount: createdRecordProcessingWorkflowsCountSelector(state),
  inProgressRecordsCount: inProgressRecordProcessingWorkflowsCountSelector(state),
  availableMarketplaces: availableMarketplacesSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getJobApprovalSummary,
    getJobDetails,
    getJobExecutionSummary,
    jobStatusSelector,
    jobApprovalStatusSelector,
    getChunkProcessingWorkflowStatus,
    resetRequirementsForm,
  },
  dispatch,
);

JobDetails.defaultProps = {
  router: {
    params: {
      jobId: '',
    },
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobDetails));
