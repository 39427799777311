import React from 'react';
import { ListGroup, Panel } from 'react-bootstrap';

const HorizontalDescriptionListGroup = props => (
  <Panel collapsible defaultExpanded>
    <Panel.Heading>
      <Panel.Toggle componentClass="a">
        {props.header}
      </Panel.Toggle>
    </Panel.Heading>
    <Panel.Collapse>
      <ListGroup fill className="dl-horizontal" componentClass="dl">{props.children}</ListGroup>
    </Panel.Collapse>
  </Panel>
);

export default HorizontalDescriptionListGroup;
