import React, { Component } from 'react';
import { connect } from 'react-redux';
import HorizontalDescriptionListGroup from '../../../components/HorizontalDescriptionListGroup';
import DescriptionListGroupItem from '../../../components/DescriptionListGroupItem';
import PlanActions from '../../../components/PlanActions';
import { selectedPlanSelector } from '../../../redux/newJob/plans/selectors';

export class PlanReview extends Component {
  render() {
    if (!this.props.selectedPlan) return null;

    const selectedPlan = this.props.selectedPlan;
    return (
      <HorizontalDescriptionListGroup header="Plan">
        <DescriptionListGroupItem dt="Name" dd={selectedPlan.name} />
        <DescriptionListGroupItem dt="Description" dd={selectedPlan.description} />
        <DescriptionListGroupItem
          dt="Actions"
          dd={<PlanActions actions={selectedPlan.actions} planId={selectedPlan.id} />}
        />
      </HorizontalDescriptionListGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedPlan: selectedPlanSelector(state),
  };
}

export default connect(mapStateToProps)(PlanReview);
