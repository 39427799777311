export const DEFAULT_FIELDS_FOR_DEFAULT_MARKETPLACE = [
  {
    id: 'default',
    FromName: '',
    FromAddress: '',
    CurrencyCode: '',
    searchBlurbLink: '',
  }
];

export const DEFAULT_FIELDS_FOR_DEVO_MARKETPLACES = [
  {
    id: 'ATVPDKIKX0DER',
    FromName: 'Amazon.com',
    FromAddress: 'order-update@amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2EUQ1WTGCTBG2',
    FromName: 'Amazon.ca',
    FromAddress: 'order-update@amazon.ca',
    CurrencyCode: 'CAD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AZXD3QD5B39HD',
    FromName: 'Amazon.com.br',
    FromAddress: 'order-update@amazon.com.br',
    CurrencyCode: 'BRL',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3P3J5A7D2ZVXI',
    FromName: 'Amazon.com.mx',
    FromAddress: 'order-update@amazon.com.mx',
    CurrencyCode: 'MXN',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1NOHO22DE3OHQ',
    FromName: 'Amazon.com.co',
    FromAddress: 'order-update@amazon.com.co',
    CurrencyCode: 'COP',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com.co/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2IDBVIE7EAGN4',
    FromName: 'Amazon.cl',
    FromAddress: 'order-update@amazon.cl',
    CurrencyCode: 'CLP',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.cl/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3G3TQ0ISIOPG8',
    FromName: 'Audible',
    FromAddress: 'order-update@audible',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A16QVBR1WHSTVN',
    FromName: 'AudibleCA',
    FromAddress: 'order-update@audibleca',
    CurrencyCode: 'CAD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A25AZXLUQC00VX',
    FromName: 'PrimeNow-US',
    FromAddress: 'order-update@amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A210MR9VJP84CZ',
    FromName: 'PrimeNow-CA',
    FromAddress: 'order-update@primenow-ca',
    CurrencyCode: 'CAD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1TR4EXBSRW0XC',
    FromName: 'PrimeNow',
    FromAddress: 'order-update@primenow',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3U49GLEJ1PS4Y',
    FromName: 'Prime Video ROW-NA',
    FromAddress: 'order-update@prime video row-na',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2624CKF0N40XQ',
    FromName: 'CBA',
    FromAddress: 'order-update@cba',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A33AYUQ0D0VIBE',
    FromName: 'DPReview',
    FromAddress: 'order-update@dpreview',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AQIG1CWF2P8ZF',
    FromName: 'shopbop.com',
    FromAddress: 'order-update@shopbop.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3QYJXEB80R0HD',
    FromName: 'NA FC Staffing Engine',
    FromAddress: 'order-update@na fc staffing engine',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3B37DB0EHK9JT',
    FromName: 'AmazonLocal',
    FromAddress: 'order-update@amazonlocal.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A15A49QYWX7788',
    FromName: 'LocalRegister',
    FromAddress: 'order-update@localregister',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'ABIJF3C8NHQ6M',
    FromName: 'woot.com',
    FromAddress: 'order-update@woot.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1NF7LDSKVSYZ7',
    FromName: 'AmazonWine',
    FromAddress: 'order-update@amazonwine',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1GA4GP79ABM4D',
    FromName: 'AmazonWireless',
    FromAddress: 'order-update@amazonwireless',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1G03W8LWCC2P1',
    FromName: 'imdb.com',
    FromAddress: 'order-update@imdb.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3U2NSBAPCSC3W',
    FromName: 'AWS',
    FromAddress: 'order-update@aws',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1T5QVC5JXV2VN',
    FromName: 'myhabit.com',
    FromAddress: 'order-update@myhabit.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1GNBPPZBQ8VRO',
    FromName: 'LeaderSupport',
    FromAddress: 'order-update@leadersupport',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A35A4JO734ER04',
    FromName: 'Amazon Payments Advanced US Live',
    FromAddress: 'order-update@amazon payments advanced us live',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2DL4BHEQQ3CI0',
    FromName: 'aps.amazon.com',
    FromAddress: 'order-update@aps.amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1AJCQWNDAI0YN',
    FromName: 'ship.amazon.com',
    FromAddress: 'order-update@ship.amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1KWSSLRHP0Q7D',
    FromName: '6pm.com',
    FromAddress: 'order-update@6pm.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1BU3DMPZUCBKG',
    FromName: 'Zappos.com',
    FromAddress: 'order-update@zappos.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2PVUI4FM1HZMJ',
    FromName: 'zapposcouture.com',
    FromAddress: 'order-update@zapposcouture.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1F83G8C2ARO7P',
    FromName: 'Amazon.co.uk',
    FromAddress: 'order-update@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1PA6795UKMFR9',
    FromName: 'Amazon.de',
    FromAddress: 'bestellung-aktuell@amazon.de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A13V1IB3VIYZZH',
    FromName: 'Amazon.fr',
    FromAddress: 'modification-commande@amazon.fr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'AJZF8LZ1EJVJN',
    FromName: 'Amazon.es',
    FromAddress: 'pedido-actualizado@amazon.es',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2XZLSVIQ0F4JT',
    FromName: 'Amazon.in',
    FromAddress: 'order-update@amazon.in',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3HOBANJMCMD83',
    FromName: 'Amazon.it',
    FromAddress: 'ordini@amazon.it',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1M3WC0SJ3A38T',
    FromName: 'Amazon.nl',
    FromAddress: 'order-update@amazon.nl',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3CQBQD3RGPJR8',
    FromName: 'Amazon.com.tr',
    FromAddress: 'order-update@amazon.com.tr',
    CurrencyCode: 'TRY',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1MQPSGJ6U9Q54',
    FromName: 'Amazon.sa',
    FromAddress: 'order-update@amazon.sa',
    CurrencyCode: 'SAR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.sa/gp/knowledge/blurb/detail',
  },
  {
    id: 'AT614YYYNOC1S',
    FromName: 'Amazon.se',
    FromAddress: 'order-update@amazon.se',
    CurrencyCode: 'SEK',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.se/gp/knowledge/blurb/detail',
  },
  // The CSCentral Devo link does not exist for Poland Marketplace.
  {
    id: 'AKY4K4WKH21YQ',
    FromName: 'Amazon.pl',
    FromAddress: 'order-update@amazon.pl',
    CurrencyCode: 'PLN',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.pl/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1YFOMBPFBRY22',
    FromName: 'Amazon.com.be',
    FromAddress: 'order-update@amazon.com.be',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.com.be/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3PJZM0QIA8QDU',
    FromName: 'Amazon.com.ng',
    FromAddress: 'order-update@amazon.com.ng',
    CurrencyCode: 'NGN',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.com.ng/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3TFQ1G9L8TQ5L',
    FromName: 'Amazon.co.za',
    FromAddress: 'order-update@amazon.co.za',
    CurrencyCode: 'ZAR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.za/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3G8SHMNK6Y7PK',
    FromName: 'AudibleUK',
    FromAddress: 'order-update@audibleuk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'ABMF2NRWK6Q4Q',
    FromName: 'AudibleDE',
    FromAddress: 'order-update@audiblede',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1PM6UBHJK3SLV',
    FromName: 'AudibleFR',
    FromAddress: 'order-update@audiblefr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2ITDZXD3G7XX6',
    FromName: 'AudibleIN',
    FromAddress: 'order-update@audiblein',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'ARGSKDDBM3ISN',
    FromName: 'AudibleIT',
    FromAddress: 'order-update@audibleit',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A31S5IEFAOLGXC',
    FromName: 'PrimeNow-UK',
    FromAddress: 'no-reply-primenow@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'AVFDNTCG43SJ1',
    FromName: 'PrimeNow-DE',
    FromAddress: 'no-reply-primenow@amazon.de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3A7FBE29Z0H2Y',
    FromName: 'PrimeNow-FR',
    FromAddress: 'no-reply-primenow@amazon.fr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3FQJJS6CFOOV1',
    FromName: 'PrimeNow-ES',
    FromAddress: 'no-reply-primenow@amazon.es',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A39EJH0AK2BYSI',
    FromName: 'PrimeNow-IT',
    FromAddress: 'no-reply-primenow@amazon.it',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1D7Z662KHSYZY',
    FromName: 'Prime Video ROW-EU',
    FromAddress: 'order-update@prime video row-eu',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1MJEQWJOUEPEQ',
    FromName: 'Prime Video ROE-EU',
    FromAddress: 'order-update@prime video roe-eu',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1L6JAWJ6GFJXF',
    FromName: 'Checkout by Amazon UK',
    FromAddress: 'order-update@checkout by amazon uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A367Z95WXV2YM4',
    FromName: 'Checkout by Amazon DE',
    FromAddress: 'order-update@checkout by amazon de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3T3TIQ3S63ABA',
    FromName: 'Pay with Amazon',
    FromAddress: 'order-update@pay with amazon',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A18GPKEFX2L58H',
    FromName: 'Buyvip.de',
    FromAddress: 'no-reply-de@buyvip.com',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'AUPVX9MSQPGCN',
    FromName: 'Buyvip.es',
    FromAddress: 'no-reply-es@buyvip.com',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A949ST7L1UTFX',
    FromName: 'Buyvip.it',
    FromAddress: 'no-reply-it@buyvip.com',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2RQ2X2B434ALC',
    FromName: 'Junglee',
    FromAddress: 'order-update@junglee',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1N780XG1VTP5D',
    FromName: 'Pay with Amazon on Junglee',
    FromAddress: 'order-update@pay with amazon on junglee',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1GX9VJLFV4LJ5',
    FromName: 'amazonpaymentsglobal',
    FromAddress: 'order-update@amazonpaymentsglobal',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2UWQKHEFGNQY3',
    FromName: 'Amazon Payments UK',
    FromAddress: 'order-update@amazon payments uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A34RX6LWLXD2KA',
    FromName: 'Amazon Payments DE',
    FromAddress: 'order-update@amazon payments de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A18VPDB9UTK24D',
    FromName: 'Amazon Pay Live',
    FromAddress: 'order-update@amazon pay live',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3UDV9872DJPPA',
    FromName: 'ship.amazon.co.uk',
    FromAddress: 'order-update@ship.amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1EMWETFUIX9XN',
    FromName: 'ship.amazon.in',
    FromAddress: 'order-update@ship.amazon.in',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A37NLEFCXJ41LE',
    FromName: 'ACCS EU',
    FromAddress: 'order-update@accs eu',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A26B8O6PHU3QR',
    FromName: 'AmazonLocal UK',
    FromAddress: 'amazonlocal-order-update@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1D38IXLEJ6VQC',
    FromName: 'AmazonBusiness.in',
    FromAddress: 'order-update@amazonbusiness.in',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1VC38T7YXB528',
    FromName: 'Amazon.co.jp',
    FromAddress: 'cs.no-reply@amazon.co.jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1RNPCQ4K8U27I',
    FromName: 'Amazon.co.au',
    FromAddress: 'order-update@amazon.com.au',
    CurrencyCode: 'AUD',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2QWGHQV78QQMU',
    FromName: 'Amazon.com.sg',
    FromAddress: 'order-update@amazon.com.sg',
    CurrencyCode: 'SGD',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1DQ6JV7I20JSG',
    FromName: 'Amazon.sg',
    FromAddress: 'order-update@amazon.sg',
    CurrencyCode: 'SGD',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.sg/gp/knowledge/blurb/detail',
  },
  {
    id: 'A298HDQ29ZA38N',
    FromName: 'Audible.co.jp',
    FromAddress: 'order-update@audible.co.jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2AT07N6OMIWFU',
    FromName: 'AudibleAU',
    FromAddress: 'order-update@audibleau',
    CurrencyCode: 'AUD',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2EFPHRCZ60KI3',
    FromName: 'Amazon Payments JP',
    FromAddress: 'order-update@amazon payments jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A34XX9OGNJ8Z6T',
    FromName: 'primenow-jp',
    FromAddress: 'cs.no-reply@amazon.co.jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A9QSUUOYRWVOG',
    FromName: 'Prime Video ROW-FE',
    FromAddress: 'order-update@prime video row-fe',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-fe-beta.integ.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'AAHKV2X7AFYLW',
    FromName: '亚马逊',
    FromAddress: 'order-update@amazon.cn',
    CurrencyCode: 'CNY',
    searchBlurbLink: 'https://cscentral-cn-beta.vipinteg.amazon.com/gp/stores/www.amazon.cn/gp/knowledge/blurb/detail',
  },
  {
    id: 'A34GYYCZVDBSIK',
    FromName: 'Amazon.ae',
    FromAddress: 'order-update@amazon.ae',
    CurrencyCode: 'AED',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.ae/gp/knowledge/blurb/detail',
  },
  // The CSCentral Devo link does not exist for Egypt Marketplace.
  {
    id: 'AUJPM9XGFJRC7',
    FromName: 'Amazon.eg',
    FromAddress: 'order-update@amazon.eg',
    CurrencyCode: 'EGP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/www.amazon.eg/gp/knowledge/blurb/detail',
  },
  // The CSCentral Devo link does not exist for UK Amazon Fresh Stores Marketplace.
  {
    id: 'A3GL64YS7K94H0',
    FromName: 'amazonfreshstores.amazon.co.uk',
    FromAddress: 'no-reply@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.amazon.com/gp/stores/amazonfreshstores.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'ACD0V2G7CTY2P',
    FromName: 'ukjaguar01.amazon.co.uk',
    FromAddress: 'no-reply@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu-beta.integ.amazon.com/gp/stores/ukjaguar01.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2N5PC858MPPV8',
    FromName: 'marketplace-kaspian-na.amazon.com',
    FromAddress: 'order-update@marketplace-kaspian-na.amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/marketplace-kaspian-na.amazon.com/gp/knowledge/blurb/detail',
  },
];

export const DEFAULT_FIELDS_FOR_PROD_MARKETPLACES = [
  {
    id: 'ATVPDKIKX0DER',
    FromName: 'Amazon.com',
    FromAddress: 'order-update@amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2EUQ1WTGCTBG2',
    FromName: 'Amazon.ca',
    FromAddress: 'order-update@amazon.ca',
    CurrencyCode: 'CAD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazon.ca/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2Q3Y263D00KWC',
    FromName: 'Amazon.com.br',
    FromAddress: 'order-update@amazon.com.br',
    CurrencyCode: 'BRL',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazon.com.br/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1AM78C64UM0Y8',
    FromName: 'Amazon.com.mx',
    FromAddress: 'order-update@amazon.com.mx',
    CurrencyCode: 'MXN',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazon.com.mx/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1ZXRLSQW2V54D',
    FromName: 'Amazon.com.co',
    FromAddress: 'order-update@amazon.com.co',
    CurrencyCode: 'COP',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazon.com.co/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2WDZJZ5TCRMLH',
    FromName: 'Amazon.cl',
    FromAddress: 'order-update@amazon.cl',
    CurrencyCode: 'CLP',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazon.cl/gp/knowledge/blurb/detail',
  },
  {
    id: 'AF2M0KC94RCEA',
    FromName: 'Audible',
    FromAddress: 'order-update@audible',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/audible.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2CQZ5RBY40XE',
    FromName: 'AudibleCA',
    FromAddress: 'order-update@audibleca',
    CurrencyCode: 'CAD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/audible.ca/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1IXFGJ6ITL7J4',
    FromName: 'PrimeNow-US',
    FromAddress: 'order-update@amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/primenow-us.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AWUFL3O4Q4HZJ',
    FromName: 'PrimeNow-CA',
    FromAddress: 'order-update@primenow-ca',
    CurrencyCode: 'CAD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/primenow.amazon.ca/gp/knowledge/blurb/detail',
  },
  {
    id: 'ABFCYI119Q7RV',
    FromName: 'PrimeNow-Miami',
    FromAddress: 'order-update@primenow-miami',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/urmp-2-prod.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AKYDKPSGVMMUD',
    FromName: 'PrimeNow-Baltimore',
    FromAddress: 'order-update@primenow-baltimore',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/urmp-3-prod.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3IN3V5ELTHSS1',
    FromName: 'PrimeNow-Dallas',
    FromAddress: 'order-update@primenow-dallas',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/urmp-4-prod.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1UI7O2AITRR2Q',
    FromName: 'PrimeNow-Atlanta',
    FromAddress: 'order-update@primenow-atlanta',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/urmp-5-prod.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AH4LQL64AIXN4',
    FromName: 'PrimeNow-Austin',
    FromAddress: 'order-update@primenow-austin',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/urmp-7-prod.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1FI6AJ872SLSY',
    FromName: 'PrimeNow',
    FromAddress: 'order-update@primenow',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/Amazon%20Prime%20Now.stores.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'ART4WZ8MWBX2Y',
    FromName: 'Prime Video ROW-NA',
    FromAddress: 'order-update@prime video row-na',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/row-na.primevideo.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3HJ3LHVHW6R8S',
    FromName: 'Abebooks',
    FromAddress: 'order-update@abebooks',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.abebooks.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A260JPYZJUXIHF',
    FromName: 'Amazon Go',
    FromAddress: 'order-update@amazon go',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/amazongo.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AZ4B0ZS3LGLX',
    FromName: 'CBA',
    FromAddress: 'order-update@cba',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/cba.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AI2AE4A16O33',
    FromName: 'DPReview',
    FromAddress: 'order-update@dpreview',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.dpreview.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2817T8MKXE20N',
    FromName: 'shopbop.com',
    FromAddress: 'order-update@shopbop.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.shopbop.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2PO04FIBKA0V6',
    FromName: 'Splashbaths',
    FromAddress: 'order-update@splashbaths',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/splashbaths.webstorepowered.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3QW0Q8L3Z6D3Q',
    FromName: 'The Independent Texan Press',
    FromAddress: 'order-update@the independent texan press',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/the-independent-texan-press.hostedbyamazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AGQQ0A1HY8X7V',
    FromName: 'NA FC Staffing Engine',
    FromAddress: 'order-update@na fc staffing engine',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/nafc.stores.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2OZJSJ3C1QC02',
    FromName: 'AmazonLocal',
    FromAddress: 'order-update@amazonlocal.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/local.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A23YMMVOQY6KRT',
    FromName: 'LocalRegister',
    FromAddress: 'order-update@localregister',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/localregister.stores.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A22ZUEY6FG8RY',
    FromName: 'woot.com',
    FromAddress: 'order-update@woot.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/woot.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2HM9SEN36TS7U',
    FromName: 'AmazonWine',
    FromAddress: 'order-update@amazonwine',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/wine.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2YXG7NG141OP2',
    FromName: 'AmazonWireless',
    FromAddress: 'order-update@amazonwireless',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/wireless.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1EVAM02EL8SFB',
    FromName: 'imdb.com',
    FromAddress: 'order-update@imdb.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.imdb.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A12QK8IU0H0XW5',
    FromName: 'AWS',
    FromAddress: 'order-update@aws',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/aws.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A39WRC2IB8YGEK',
    FromName: 'myhabit.com',
    FromAddress: 'order-update@myhabit.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.myhabit.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A28LUFWO5HYYIZ',
    FromName: 'Amazon Retail LLC',
    FromAddress: 'order-update@amazon retail llc',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/amazonbooks.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A25NTRYU0OUV0L',
    FromName: 'Amazon Registry Services Inc',
    FromAddress: 'order-update@amazon registry services inc',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazonregistry.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2YBZOQLHY23UT',
    FromName: 'Amazonsupply',
    FromAddress: 'order-update@amazonsupply',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.amazonsupply.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AYZ4TAHMVXJLX',
    FromName: 'LeaderSupport',
    FromAddress: 'order-update@leadersupport',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/CSACentral.stores.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3G6ICTLM20UQX',
    FromName: 'CSCTransISPNA',
    FromAddress: 'order-update@csctransispna',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/csc-trans-isp-na.stores.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2LPTH3J8119AA',
    FromName: 'Deep One',
    FromAddress: 'order-update@deepone',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/deepone.hostedbyamazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1T0F3SY1TPEAY',
    FromName: 'JAM llc.',
    FromAddress: 'order-update@jam llc.',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/jam-llc.hostedbyamazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AM5XBVGNQNKHG',
    FromName: 'Loading Dock',
    FromAddress: 'order-update@loading dock',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/loading-dock.webstorepowered.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A30DUSGFTNV3ZQ',
    FromName: 'Serpent\'s Kiss',
    FromAddress: 'order-update@serpent\'s kiss',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/serpents-kiss.hostedbyamazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AGWSWK15IEJJ7',
    FromName: 'Amazon Payments Advanced US Live',
    FromAddress: 'order-update@amazon payments advanced us live',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/apa.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3C66CJ8C1SW1I',
    FromName: 'aps.amazon.com',
    FromAddress: 'order-update@aps.amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/aps.amazon.stores.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2BR6UVHX99FEC',
    FromName: 'ship.amazon.com',
    FromAddress: 'order-update@ship.amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/ship.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1XL214U7JA46G',
    FromName: 'BeautyBar',
    FromAddress: 'order-update@beautybar',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.beautybar.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3I52P3BRBPQB8',
    FromName: 'fpa-casa',
    FromAddress: 'order-update@fpa-casa',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.casa.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A133VYBJYE8OXU',
    FromName: 'Quidsi FPA',
    FromAddress: 'order-update@quidsi fpa',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.diapers.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2IBI0CA6RLSHR',
    FromName: 'fpa-soap',
    FromAddress: 'order-update@fpa-soap',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.soap.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A18RULUF6B5AN8',
    FromName: 'fpa-wag',
    FromAddress: 'order-update@fpa-wag',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.wag.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3T155I11LQFV7',
    FromName: 'fpa-yoyo',
    FromAddress: 'order-update@fpa-yoyo',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/FPA.yoyo.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3GVQO73QG4HLY',
    FromName: 'TVA-jump',
    FromAddress: 'order-update@tva-jump',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/tva.afterschool.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2PTOWNQL7HN7E',
    FromName: 'quidsi-ph2',
    FromAddress: 'order-update@quidsi-ph2',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/tva.bookworm.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'ANBJ0JT9LAPZ4',
    FromName: 'Other World Computing',
    FromAddress: 'order-update@other world computing',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/tva.macsales.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3SW0KBROV2X6Q',
    FromName: 'shopbop',
    FromAddress: 'order-update@shopbop',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/tva.shopbop.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2ZN6VKQ29XW2B',
    FromName: 'quidsi-ph1',
    FromAddress: 'order-update@quidsi-ph1',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/tva.vine.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A97B0XXD56IZ1',
    FromName: 'Woot TVA',
    FromAddress: 'order-update@woot tva',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/TVA.woot.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'ALYICPJ31MJ6Q',
    FromName: '6pm.com',
    FromAddress: 'order-update@6pm.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.6pm.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'APH27RIBNWMOI',
    FromName: 'Zappos.com',
    FromAddress: 'order-update@zappos.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.zappos.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A38DPNFLOPUVDX',
    FromName: 'zapposcouture.com',
    FromAddress: 'order-update@zapposcouture.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/www.zapposcouture.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1F83G8C2ARO7P',
    FromName: 'Amazon.co.uk',
    FromAddress: 'order-update@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1PA6795UKMFR9',
    FromName: 'Amazon.de',
    FromAddress: 'bestellung-aktuell@amazon.de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.de/gp/knowledge/blurb/detail',
  },
  {
    id: 'A13V1IB3VIYZZH',
    FromName: 'Amazon.fr',
    FromAddress: 'modification-commande@amazon.fr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.fr/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1RKKUPIHCS9HS',
    FromName: 'Amazon.es',
    FromAddress: 'pedido-actualizado@amazon.es',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.es/gp/knowledge/blurb/detail',
  },
  {
    id: 'A21TJRUUN4KGV',
    FromName: 'Amazon.in',
    FromAddress: 'order-update@amazon.in',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.in/gp/knowledge/blurb/detail',
  },
  {
    id: 'APJ6JRA9NG5V4',
    FromName: 'Amazon.it',
    FromAddress: 'ordini@amazon.it',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.it/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1805IZSGTT6HS',
    FromName: 'Amazon.nl',
    FromAddress: 'order-update@amazon.nl',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.nl/gp/knowledge/blurb/detail',
  },
  {
    id: 'A33AVAJ2PDY3EV',
    FromName: 'Amazon.com.tr',
    FromAddress: 'order-update@amazon.com.tr',
    CurrencyCode: 'TRY',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.com.tr/gp/knowledge/blurb/detail',
  },
  {
    id: 'A17E79C6D8DWNP',
    FromName: 'Amazon.sa',
    FromAddress: 'order-update@amazon.sa',
    CurrencyCode: 'SAR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.sa/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2NODRKZP88ZB9',
    FromName: 'Amazon.se',
    FromAddress: 'order-update@amazon.se',
    CurrencyCode: 'SEK',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.se/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1C3SOZRARQ6R3',
    FromName: 'Amazon.pl',
    FromAddress: 'order-update@amazon.pl',
    CurrencyCode: 'PLN',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.pl/gp/knowledge/blurb/detail',
  },
  {
    id: 'AMEN7PMS3EDWL',
    FromName: 'Amazon.com.be',
    FromAddress: 'order-update@amazon.com.be',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.com.be/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3OCL2LJ6GDH9T',
    FromName: 'Amazon.com.ng',
    FromAddress: 'order-update@amazon.com.ng',
    CurrencyCode: 'NGN',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.com.ng/gp/knowledge/blurb/detail',
  },
  {
    id: 'AE08WJ6YKNBMC',
    FromName: 'Amazon.co.za',
    FromAddress: 'order-update@amazon.co.za',
    CurrencyCode: 'ZAR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.co.za/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2I9A3Q2GNFNGQ',
    FromName: 'AudibleUK',
    FromAddress: 'order-update@audibleuk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/audible.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'AN7V1F1VY261K',
    FromName: 'AudibleDE',
    FromAddress: 'order-update@audiblede',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/audible.de/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2728XDNODOQ8T',
    FromName: 'AudibleFR',
    FromAddress: 'order-update@audiblefr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/audible.fr/gp/knowledge/blurb/detail',
  },
  {
    id: 'AJO3FBRUE6J4S',
    FromName: 'AudibleIN',
    FromAddress: 'order-update@audiblein',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/audible.in/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2N7FU2W2BU2ZC',
    FromName: 'AudibleIT',
    FromAddress: 'order-update@audibleit',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/audible.it/gp/knowledge/blurb/detail',
  },
  {
    id: 'AM7DNVYQULIQ5',
    FromName: 'PrimeNow-UK',
    FromAddress: 'no-reply-primenow@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/primenow.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1KU16HT7ALXJ0',
    FromName: 'PrimeNow-DE',
    FromAddress: 'no-reply-primenow@amazon.de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/primenow.amazon.de/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2905VW864VWWF',
    FromName: 'PrimeNow-FR',
    FromAddress: 'no-reply-primenow@amazon.fr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/primenow.amazon.fr/gp/knowledge/blurb/detail',
  },
  {
    id: 'ACDNLAE5F4JT1',
    FromName: 'PrimeNow-ES',
    FromAddress: 'no-reply-primenow@amazon.es',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/primenow.amazon.es/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1TERGVA4U2MLK',
    FromName: 'PrimeNow-IT',
    FromAddress: 'no-reply-primenow@amazon.it',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/primenow.amazon.it/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2MFUE2XK8ZSSY',
    FromName: 'Prime Video ROW-EU',
    FromAddress: 'order-update@prime video row-eu',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/row-eu.primevideo.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3K6Y4MI8GDYMT',
    FromName: 'Prime Video ROE-EU',
    FromAddress: 'order-update@prime video roe-eu',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/roe-eu.primevideo.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2WQPBGJ59HSXT',
    FromName: 'Checkout by Amazon UK',
    FromAddress: 'order-update@checkout by amazon uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/payments.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1OCY9REWJOCW5',
    FromName: 'Checkout by Amazon DE',
    FromAddress: 'order-update@checkout by amazon de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/payments.amazon.de/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3PY9OQTG31F3H',
    FromName: 'Pay with Amazon',
    FromAddress: 'order-update@pay with amazon',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/paywithamazon.amazon.in/gp/knowledge/blurb/detail',
  },
  {
    id: 'A38GABX06X24K',
    FromName: 'Buyvip.de',
    FromAddress: 'no-reply-de@buyvip.com',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/de.buyvip.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2TG6KGE1SCY95',
    FromName: 'Buyvip.es',
    FromAddress: 'no-reply-es@buyvip.com',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/es.buyvip.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3E7MN3FRLVK93',
    FromName: 'Buyvip.it',
    FromAddress: 'no-reply-it@buyvip.com',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/it.buyvip.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1GH8JQC28KQMO',
    FromName: 'Abebooks UK',
    FromAddress: 'order-update@abebooks uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/FPA.abebooks.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A10A3N4DLO63GE',
    FromName: 'Abebooks DE',
    FromAddress: 'order-update@abebooks de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/FPA.abebooks.de/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3XA1DPPGYA9H',
    FromName: 'Abebooks FR',
    FromAddress: 'order-update@abebooks fr',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/FPA.abebooks.fr/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1MHIT7LTD3TXB',
    FromName: 'Abebooks FPA ES',
    FromAddress: 'order-update@abebooks fpa es',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/FPA.abebooks.es/gp/knowledge/blurb/detail',
  },
  {
    id: 'A14JTEK4O1XNK4',
    FromName: 'Abebooks FPA IT',
    FromAddress: 'order-update@abebooks fpa it',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/FPA.abebooks.it/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3M22N3OY5KY7Q',
    FromName: 'Junglee',
    FromAddress: 'order-update@junglee',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.junglee.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2UTKXGQLHXMO',
    FromName: 'Pay with Amazon on Junglee',
    FromAddress: 'order-update@pay with amazon on junglee',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/paywithamazon.junglee.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3RGCJYTOPRINC',
    FromName: 'amazonpaymentsglobal',
    FromAddress: 'order-update@amazonpaymentsglobal',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/paymentsglobal-csc.amazon.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AZAJMM36N6WQL',
    FromName: 'Amazon Payments UK',
    FromAddress: 'order-update@amazon payments uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/apa.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A53RDEWN57UU5',
    FromName: 'Amazon Payments DE',
    FromAddress: 'order-update@amazon payments de',
    CurrencyCode: 'EUR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/apa.amazon.de/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3FDG49KKM823Y',
    FromName: 'Amazon Pay Live',
    FromAddress: 'order-update@amazon pay live',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/apa.amazon.in/gp/knowledge/blurb/detail',
  },
  {
    id: 'AGJTXQVCWGLM9',
    FromName: 'ship.amazon.co.uk',
    FromAddress: 'order-update@ship.amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/ship.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2ACQFO88DPSZS',
    FromName: 'ship.amazon.in',
    FromAddress: 'order-update@ship.amazon.in',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/ship.amazon.in/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1L4CO7C8TPH76',
    FromName: 'ACCS EU',
    FromAddress: 'order-update@accs eu',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/accseu.stores.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'AMRPEKZ6O67O3',
    FromName: 'AmazonLocal UK',
    FromAddress: 'amazonlocal-order-update@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/local.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2D32KE73PNS33',
    FromName: 'AmazonBusiness.in',
    FromAddress: 'order-update@amazonbusiness.in',
    CurrencyCode: 'INR',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazonbusiness.in/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1VC38T7YXB528',
    FromName: 'Amazon.co.jp',
    FromAddress: 'cs.no-reply@amazon.co.jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/www.amazon.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A39IBJ37TRP1C6',
    FromName: 'Amazon.com.au',
    FromAddress: 'order-update@amazon.com.au',
    CurrencyCode: 'AUD',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/www.amazon.com.au/gp/knowledge/blurb/detail',
  },
  {
    id: 'AUK5T4I7X4ZCF',
    FromName: 'Amazon.com.sg',
    FromAddress: 'order-update@amazon.com.sg',
    CurrencyCode: 'SGD',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/www.amazon.com.sg/gp/knowledge/blurb/detail',
  },
  {
    id: 'A1QAP3MOU4173J',
    FromName: 'Audible.co.jp',
    FromAddress: 'order-update@audible.co.jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/audible.co.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'AN7EY7DTAW63G',
    FromName: 'AudibleAU',
    FromAddress: 'order-update@audibleau',
    CurrencyCode: 'AUD',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/audible.com.au/gp/knowledge/blurb/detail',
  },
  {
    id: 'A75P1MG8VMU51',
    FromName: 'Amazon Payments JP',
    FromAddress: 'order-update@amazon payments jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/apa.amazon.co.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2MKBGGTHABQEV',
    FromName: 'primenow-jp',
    FromAddress: 'cs.no-reply@amazon.co.jp',
    CurrencyCode: 'JPY',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/primenow.amazon.co.jp/gp/knowledge/blurb/detail',
  },
  {
    id: 'A15PK738MTQHSO',
    FromName: 'Prime Video ROW-FE',
    FromAddress: 'order-update@prime video row-fe',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/row-fe.primevideo.com/gp/knowledge/blurb/detail',
  },
  {
    id: 'AAHKV2X7AFYLW',
    FromName: '亚马逊',
    FromAddress: 'order-update@amazon.cn',
    CurrencyCode: 'CNY',
    searchBlurbLink: 'https://cscentral-cn.amazon.com/gp/stores/www.amazon.cn/gp/knowledge/blurb/detail',
  },
  {
    id: 'A2VIGQ35RCS4UG',
    FromName: 'Amazon.ae',
    FromAddress: 'order-update@amazon.ae',
    CurrencyCode: 'AED',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.ae/gp/knowledge/blurb/detail',
  },
  {
    id: 'A19VAU5U5O7RUS',
    FromName: 'Amazon.sg',
    FromAddress: 'order-update@amazon.sg',
    CurrencyCode: 'SGD',
    searchBlurbLink: 'https://cscentral-fe.amazon.com/gp/stores/www.amazon.sg/gp/knowledge/blurb/detail',
  },
  {
    id: 'ARBP9OOSHTCHU',
    FromName: 'Amazon.eg',
    FromAddress: 'order-update@amazon.eg',
    CurrencyCode: 'EGP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/www.amazon.eg/gp/knowledge/blurb/detail',
  },
  {
    id: 'A3P3DP59YHPL03',
    FromName: 'amazonfreshstores.amazon.co.uk',
    FromAddress: 'no-reply@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/amazonfreshstores.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'A15OR4562BFFUV',
    FromName: 'ukjaguar01.amazon.co.uk',
    FromAddress: 'no-reply@amazon.co.uk',
    CurrencyCode: 'GBP',
    searchBlurbLink: 'https://cscentral-eu.amazon.com/gp/stores/ukjaguar01.amazon.co.uk/gp/knowledge/blurb/detail',
  },
  {
    id: 'ACQK4JSLMBLPI',
    FromName: 'marketplace-kaspian-na.amazon.com',
    FromAddress: 'order-update@marketplace-kaspian-na.amazon.com',
    CurrencyCode: 'USD',
    searchBlurbLink: 'https://cscentral.amazon.com/gp/stores/marketplace-kaspian-na.amazon.com/gp/knowledge/blurb/detail',
  },
];
