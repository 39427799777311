/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import _ from 'lodash';
import { workflowStatusType, jobStatusType } from './types';

export const hasMorePagesToLoadSelector = createSelector(
  state => state.jobs.loader.loaded,
  state => state.jobs.loader.total,
  (loaded, total) => (
    _.size(loaded) !== total
  ),
);

export const aggregatedActiveJobSelector = createSelector(
  state => state.jobs.loader.loaded,
  state => state.jobs.activeJob,
  (loadedJobs, activeJob) => ({
    ..._.find(loadedJobs, { jobID: activeJob.jobID }),
    ...activeJob,
  }),
);

export const completedRecordProcessingWorkflowsCountSelector = createSelector(
  state => state.jobs.activeJob.executionSummary.counts,
  counts => _.sum(_.values(_.omit(counts, [
    workflowStatusType.IN_PROGRESS,
    workflowStatusType.CREATED,
  ]))),
);

const countWorkflows = (executionSummaryCounts, workflowStatuses) => (
  _.sum(_.values(_.pick(executionSummaryCounts, workflowStatuses)))
);

export const createdRecordProcessingWorkflowsCountSelector = createSelector(
  state => state.jobs.activeJob.executionSummary.counts,
  counts => countWorkflows(counts, [
    workflowStatusType.CREATED,
  ]),
);

export const inProgressRecordProcessingWorkflowsCountSelector = createSelector(
  state => state.jobs.activeJob.executionSummary.counts,
  counts => countWorkflows(counts, [
    workflowStatusType.IN_PROGRESS,
  ]),
);

export const failedChunkProcessingWorkflowsCountSelector = createSelector(
  state => state.jobs.activeJob.chunkProcessingWorkflowStatus.counts,
  counts => countWorkflows(counts, _.flatten([
    workflowStatusType.TERMINATED,
    workflowStatusType.FAILED_TYPE,
    workflowStatusType.TIMED_OUT,
  ])),
);

export const createdChunkProcessingWorkflowsCountSelector = createSelector(
  state => state.jobs.activeJob.chunkProcessingWorkflowStatus.counts,
  counts => _.sum(_.values(counts)),
);

export const recordProcessingWorkflowsCountsSelector = createSelector(
  state => state.jobs.activeJob.totalNumberOfRecords,
  completedRecordProcessingWorkflowsCountSelector,
  (totalCount, completedCount) => ({
    total: totalCount,
    completed: completedCount,
  }),
);

export const chunkProcessingWorkflowsCountsSelector = createSelector(
  failedChunkProcessingWorkflowsCountSelector,
  createdChunkProcessingWorkflowsCountSelector,
  (failedCount, createdCount) => (
    { created: createdCount,
      failed: failedCount }),
);

export const hasWorkflowFailed = (workflowStatus) => {
  if (_.indexOf(workflowStatusType.FAILED_TYPE, workflowStatus) !== -1) return true;
  if (workflowStatus === workflowStatusType.TERMINATED) return true;
  if (workflowStatus === workflowStatusType.TIMED_OUT) return true;
  return false;
};

/**
 * Selector logic for HULK's job status:
 * https://code.amazon.com/packages/HeartbeatTechDesigns/blobs/mainline/--/Design%20Diagrams/Project%20HULK/Flowcharts/JobStatusDecisionTree.png
 */
export const jobStatusSelector = createSelector(
  state => state.jobs.activeJob.jarvisJobStatus,
  recordProcessingWorkflowsCountsSelector,
  chunkProcessingWorkflowsCountsSelector,
  (jobCreationEPStatus, recordProcessingWorkflowsCount, chunkProcessingWorkflowsCount) => {
    if (_.indexOf(workflowStatusType.SCHEDULED, jobCreationEPStatus) !== -1) {
      return jobStatusType.IN_QUEUE;
    }
    if (hasWorkflowFailed(jobCreationEPStatus) &&
       chunkProcessingWorkflowsCount.created > 0) return jobStatusType.IN_PROGRESS_WITH_ERRORS;
    if (_.indexOf(workflowStatusType.SUCCESS_TYPE, jobCreationEPStatus) === -1) {
      return jobCreationEPStatus;
    }
    if (recordProcessingWorkflowsCount.total === recordProcessingWorkflowsCount.completed) {
      return jobStatusType.COMPLETED;
    }
    if (chunkProcessingWorkflowsCount.failed > 0) return jobStatusType.IN_PROGRESS_WITH_ERRORS;
    return jobStatusType.IN_PROGRESS;
  },
);

export const jobApprovalStatusSelector = createSelector(
  state => state.jobs.activeJob.approvalSummary.approvalStatus,
  (approvalStatus) => {
    return approvalStatus;
  }
);

export const selectValuesFromJobReport = createSelector(
  state => state.jobs.activeJob,
  ({ jobName = '', description, simttId, tags, estimatedTotalConcession = '' }) => ({
    jobName: jobName.replace('_validation', ''),
    jobDescription: description,
    simttID: simttId,
    tags: (tags && tags.join(',')) || '',
    estimatedTotalConcession,
  }),
);
