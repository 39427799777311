import {selectedPlanSelector} from "../plans/selectors";

export const SET_PLAN_NAME = "SET_PLAN_NAME";

export function getPlanName() {
    return (dispatch, getState) => {
        const state = getState();
        const planName = selectedPlanSelector(state).name;
        dispatch({
            type: SET_PLAN_NAME,
            planName: planName,
        });
    };
}
