import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Avatar.scss';

class Avatar extends Component {
  render() {
    return (
      <div className="hb-avatar">
        <span>{this.props.name}</span>
        <img src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${this.props.login}`} alt={this.props.name} />
      </div>
    );
  }
}

Avatar.propTypes = {
  name: PropTypes.string,
  login: PropTypes.string,
};

Avatar.defaultProps = {
  name: '',
  login: '',
};

export default Avatar;
