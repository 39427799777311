export const deploymentStages = {
  ALPHA: 'alpha',
  BETA: 'beta',
  GAMMA: 'gamma',
  PROD: 'production',
};

export const deploymentRegions = {
  NA: 'na',
  EU: 'eu',
  CN: 'cn',
  FE: 'fe',
};

export const currentStage = process.env.NODE_ENV;
export const currentRegion = process.env.REGION;
