import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import _ from 'lodash';
import HorizontalDescriptionListGroup from '../../../components/HorizontalDescriptionListGroup';
import DescriptionListGroupItem from '../../../components/DescriptionListGroupItem';
import { loadedBlurbNameSelector } from '../../../redux/newJob/blurb/loaded/selectors';
import { defaultFieldsForSelectedMarketplaceSelector } from '../../../redux/marketplace/selectors';
import {
  formFieldRequirementNamesSelector,
  isSelectedPlanBlurbDependentSelector,
  isSelectedPlanMobilePushDependentSelector,
  isSelectedPlanConcessionReasonDependentSelector,
  shouldShowSuppressEmailOptionSelector,
  shouldShowRefundToGcOptionSelector,
} from '../../../redux/newJob/plans/selectors';
import { selectedConcessionReasonSelector } from '../../../redux/newJob/concessionReasons/selectors';
import { totalConcessionAmountSelector, failureCountSelector } from '../../../redux/newJob/totalConcession/selectors';
import { selectValuesFromJobReport } from '../../../redux/jobs/selectors';

export class RequirementsReview extends Component {
  render() {
    return (
      <HorizontalDescriptionListGroup header="Requirements">
        {this.props.shouldDisplayBlurb && (
          <DescriptionListGroupItem dt="Blurb Name" dd={this.props.blurbName} />
        )}

        {this.props.shouldDisplayHtmlBlurb && (
          <DescriptionListGroupItem
            dt="Html Template Name"
            dd={this.props.htmlBlurbName}
          />
        )}

        {this.props.planFields.map((field) => {
          if (field === 'EstimatedTotalConcession') return null;
          return (
            <DescriptionListGroupItem
              key={field}
              dt={field}
              dd={this.props.requirementsFormValues[field]}
            />
          );
        })}

        {this.props.shouldDisplayConcessionReason && this.props.concessionReason && (
          <DescriptionListGroupItem
            dt="ConcessionReason"
            dd={this.props.concessionReason.reasonDescription}
          />
        )}

        {this.props.shouldDisplayTotalConcessionAmounts && (
          <span>
            <DescriptionListGroupItem
              dt={
                <div>
                  Estimated concession
                  <br />
                  value {this.props.currencyCode}
                </div>
              }
              dd={this.props.estimatedTotalConcessionValue || '-'}
            />
            <DescriptionListGroupItem
              dt={
                <div>
                  Validated concession
                  <br />
                  value {this.props.currencyCode}
                </div>
              }
              dd={
                this.props.validatedTotalConcessionValue
                  ? `${this.props.validatedTotalConcessionValue}, Number of failed records: ${this.props.failureCount}`
                  : '-'
              }
            />
          </span>
        )}

        {this.props.shouldDisplaySuppressEmailOption && (
          <DescriptionListGroupItem
            className="requirements-review-suppress-crow-refund-emails"
            dt="Suppress Crow Emails"
            dd={this.props.suppressCrowRefundConfirmationEmails ? 'Yes' : 'No'}
          />
        )}

        {this.props.shouldDisplayRefundToGcOption && (
          <DescriptionListGroupItem
            className="requirements-review-refund-to-gc"
            dt="Refund as e-GiftCard"
            dd={this.props.refundToGc ? 'Yes' : 'No'}
          />
        )}

        {this.props.shouldDisplayMobilePushText && (
          <DescriptionListGroupItem
            className="requirements-review-mobile-push-text"
            dt="Mobile Push Text"
            dd={this.props.mobilePushText}
          />
        )}

        <DescriptionListGroupItem dt="File" dd={this.props.file.name} />
      </HorizontalDescriptionListGroup>
    );
  }
}

RequirementsReview.defaultProps = {
  planFields: [],
  file: { name: '' },
  estimatedTotalConcessionValue: '',
  validatedTotalConcessionValue: '',
  failureCount: '',
  concessionRecipeSelected: false,
};

const requirementsFormSelector = formValueSelector('newJobRequirements');

/* Consistently return Requirement form values as dictionary object
 * This is necessary because redux-form's formValueSelector behavior.
 * From the documentation https://redux-form.com/6.8.0/docs/api/formvalueselector.md/:
 * If you provide only one field name, the function will return the value of that field.
 * If you provide more than one field name, it will return an object mapping fields to values.
 */
function getRequirementsFormValuesAsDictionary(state) {
  const planFields = formFieldRequirementNamesSelector(state);
  if (_.isEmpty(planFields)) return {};

  let requirementsFormValues = requirementsFormSelector(state, ...planFields);
  if (planFields.length === 1) {
    requirementsFormValues = {
      [planFields[0]]: requirementsFormValues,
    };
  }
  return requirementsFormValues;
}

function mapStateToProps(state, props) {
  const planFields = formFieldRequirementNamesSelector(state);
  const hasMobilePushBeenSelected = requirementsFormSelector(state, 'mobilePush');
  const isSuppressCrowRefundConfirmationEmailsSelected = requirementsFormSelector(state, 'SuppressCrowRefundConfirmationEmails');
  const isRefundToGcSelected = requirementsFormSelector(state, 'RefundToGC');
  const estimatedTotalConcessionValue = selectValuesFromJobReport(state).estimatedTotalConcession;
  const validatedTotalConcessionValue = totalConcessionAmountSelector(state);
  const failureCount = failureCountSelector(state);
  return {
    planFields,
    requirementsFormValues: getRequirementsFormValuesAsDictionary(state),
    file: state.newJob.file.value,
    shouldDisplayBlurb: isSelectedPlanBlurbDependentSelector(state),
    blurbName: loadedBlurbNameSelector(state),
    // shouldDisplayHtmlBlurb: isSelectedPlanHtmlBlurbDependentSelector(state),
    shouldDisplayHtmlBlurb: false, // TODO: use above line when supported by backend.
    htmlBlurbName: state.newJob.htmlBlurb.loaded.name,
    shouldDisplayMobilePushText: isSelectedPlanMobilePushDependentSelector(state)
      && hasMobilePushBeenSelected,
    mobilePushText: requirementsFormSelector(state, 'MobilePushText'),
    shouldDisplaySuppressEmailOption: shouldShowSuppressEmailOptionSelector(state) &&
    isSuppressCrowRefundConfirmationEmailsSelected,
    shouldDisplayConcessionReason: isSelectedPlanConcessionReasonDependentSelector(state),
    concessionReason: selectedConcessionReasonSelector(state),
    suppressCrowRefundConfirmationEmails: isSuppressCrowRefundConfirmationEmailsSelected,
    shouldDisplayRefundToGcOption: shouldShowRefundToGcOptionSelector(state) &&
        isRefundToGcSelected,
    refundToGc: isRefundToGcSelected,
    shouldDisplayTotalConcessionAmounts: props.concessionRecipeSelected,
    estimatedTotalConcessionValue,
    validatedTotalConcessionValue,
    failureCount,
    currencyCode: defaultFieldsForSelectedMarketplaceSelector(state).CurrencyCode,
  };
}

export default connect(mapStateToProps)(RequirementsReview);
