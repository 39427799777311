import React, { Component } from 'react';
import './RTLContent.scss';

// RTL HTML Content wrapper for email preview.
export class RTLContent extends Component {
  render() {
    const content = { __html: this.props.content.replace(/(\r\n|\n|\r)/gm, '<br>') };
    return (
      <div
        dir="rtl"
        style={{ textAlign: 'right' }}
        dangerouslySetInnerHTML={content} // eslint-disable-line
      />
    );
  }
}

RTLContent.defaultProps = {
  content: '',
};

export default RTLContent;
