import {SET_PLAN_NAME} from "./actions";

export default function
    planNameReducer(state = {planName: ''}, action) {
    switch (action.type) {
        case SET_PLAN_NAME:
            return {
                ...state,
                planName: action.planName,
            };
        default:
            return state;
    }
}
