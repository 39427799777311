import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { promiseActionBaseType } from './helpers/promiseMiddlewareHelpers';
import { deploymentStages, currentStage } from './deploymentStages';
import reducers from './redux';

const middlewares = [promiseActionBaseType, ReduxPromiseMiddleware, thunk];

if (currentStage !== deploymentStages.PROD) {
  middlewares.push(createLogger());
}

const defaultReducer = state => state;

export default createStore(
  reducers || defaultReducer,
  applyMiddleware(...middlewares),
);
