import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label } from 'react-bootstrap';
import { fileRequirementsSelector } from '../../redux/newJob/file/selectors';

const renderField = ({ name, satisfied }) => {
  const iconClass = satisfied
    ? 'fa fa-check-circle'
    : 'fa fa-exclamation-circle';

  const labelBsStyle = satisfied ? 'success' : 'danger';

  return (
    <li key={name}>
      <Label bsStyle={labelBsStyle}>
        <i className={iconClass} aria-hidden="true" /> {name}
      </Label>
    </li>
  );
};

export class FileRequirements extends Component {
  render() {
    return (
      <div>
        <h4>Required fields:</h4>
        <ul className="list-inline">
          {this.props.fields.map(renderField)}
        </ul>
      </div>
    );
  }
}

FileRequirements.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      satisfied: PropTypes.bool,
    }),
  ),
};

FileRequirements.defaultProps = {
  fields: [],
};

function mapStateToProps(state) {
  return {
    fields: fileRequirementsSelector(state),
  };
}


export default connect(mapStateToProps)(FileRequirements);
