import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Well } from 'react-bootstrap';
import { previewBlurbSelector } from '../../redux/newJob/previewSender/email/selectors';

export class BlurbContent extends Component {
  render() {
    return (
      <div>
        <h4>Input Blurb</h4>
        <Well style={{ whiteSpace: 'pre-wrap' }}>
          {this.props.blurb.content}
        </Well>
      </div>
    );
  }
}

BlurbContent.defaultProps = {
  blurb: '',
};

function mapStateToProps(state) {
  return {
    blurb: previewBlurbSelector(state),
  };
}

export default connect(mapStateToProps)(BlurbContent);
