import { createSelector } from 'reselect';

export const blurbLoadingErrorSelector = createSelector(
  state => state.newJob.blurb.loader,
  ({ error }) => error,
);

export const blurbLoadingStatusSelector = createSelector(
  state => state.newJob.blurb.loader,
  ({ status }) => status,
);
