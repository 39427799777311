import 'react-tabs/style/react-tabs.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Spinner } from 'AWS-UI-Components-React';
import { LinkContainer } from 'react-router-bootstrap';
import JobsTable from '../../components/JobsTable';
import { searchJobs, getRecordProcessingWorkflowsStatus, getApprovalsSubmittedByUser } from '../../redux/jobs/actions';
import { ActionStripe } from '../../components/AWSInspiredComponents';
import JobsSearchQueryField from './JobsSearchQueryField';
import { Alert } from 'AWS-UI-Components-React';
import ApprovalsTable from '../../components/ApprovalsTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import withRouter from '../../helpers/withRouter';
import { selectedMarketplaceSelector } from '../../redux/marketplace/selectors';

export class Jobs extends Component {
  componentDidMount() {
    this.props.getRecordProcessingWorkflowsStatus();
    if (_.isEmpty(this.props.jobs)) {
      this.props.searchJobs();
      this.props.getApprovalsSubmittedByUser();
    }
  }

  getSystemLoad() {
    if (this.props.records.isLoading === true) {
      return <Spinner />;
    }
    const numberFormat = new Intl.NumberFormat();
    return numberFormat.format(this.props.records.inProgressRecordsCount);
  }

  render() {
    const isInvalidMarketplace = (this.props.marketplace == null || this.props.marketplace.id == 'default');
    const invalidMarketplaceErrorMsg = "Select Marketplace to create new job";
    const actionGroup = (
      <div className="action-group">
        <span className="action-group-text">
          Current load on system: {this.getSystemLoad()} records
        </span>
        <LinkContainer to={{ pathname: isInvalidMarketplace ? '' : '/jobs/new'  }}>
          <Button variant="primary" disabled={isInvalidMarketplace} >New Job</Button>
        </LinkContainer>
        { isInvalidMarketplace &&
            <div className="action-group-error">{invalidMarketplaceErrorMsg}</div>
        }
      </div>
    );
    const tabStyle = { minWidth: '50%', textAlign: 'center' };

    return (
      <div>
        <br /> <br />
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab style={tabStyle}>
              <b>Jobs</b>
            </Tab>
            <Tab style={tabStyle}>
              <b>Approvals</b>
            </Tab>
          </TabList>

          <TabPanel>
            <br /> <br /> <br />
            <ActionStripe group={actionGroup} />
            <JobsSearchQueryField />
            <JobsTable jobs={this.props.jobs} router={this.props.router} />
          </TabPanel>
          <TabPanel>
            <br /> <br /> <br />
            <ApprovalsTable approvals={this.props.approvals} router={this.props.router} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobs: state.jobs.loader.loaded,
  approvals: state.jobs.approvalLoader.loadedApprovals,
  records: state.jobs.records,
  marketplace: selectedMarketplaceSelector(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  searchJobs,
  getApprovalsSubmittedByUser,
  getRecordProcessingWorkflowsStatus,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Jobs));
