import React from 'react';

const DescriptionListGroupItem = props => (
  <div className="list-group-item">
    <dt>{props.dt}</dt>
    <dd>{props.dd}</dd>
  </div>
);

export default DescriptionListGroupItem;
