import React from 'react';

// See https://polaris.corp.amazon.com/system/structures/action_stripe/
const ActionStripe = props => (
  <div className="awsui-util-action-stripe-large">
    <div className="awsui-util-action-stripe-title">
      <h1>{props.title}</h1>
    </div>
    <div className="awsui-util-action-stripe-group">
      {props.group}
    </div>
  </div>
);
export default ActionStripe;
