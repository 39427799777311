import _ from 'lodash';
import { SEARCH_JOBS_PENDING, SEARCH_JOBS_FULFILLED, LOAD_MORE_JOBS_PENDING, LOAD_MORE_JOBS_FULFILLED } from '../actions';
import { ListOfJobsJarvisXpath, JobPropertiesJarvisXpaths, TotalNumberOfResultsJarvisXpath } from '../jarvisConstants';

export const initialState = {
  isLoading: false,
  loaded: [],
  total: 0,
};

const JOB_NAME_TAG_PREFIX = 'JobName::';
const JOB_SUBMITTER_TAG_PREFIX = 'JobSubmitter::';
const PLAN_NAME_TAG_PREFIX = 'Plan::';
const USER_TAG_PREFIX = 'UserTag::';

const removeTagPrefix = (tags, prefix) => _.map(tags, tag => _.replace(tag, prefix, ''));

const getPrefixedTags = (tags, prefix) => _.filter(tags, tag => (tag.indexOf(prefix) === 0));

export const getUserAddedTags = tags =>
  removeTagPrefix(getPrefixedTags(tags, USER_TAG_PREFIX), USER_TAG_PREFIX);

const getMetaTagValue = (tags, metaTagPrefix) => _.replace(_.head(getPrefixedTags(tags, metaTagPrefix)), metaTagPrefix, '');

const getTotalNumberOfResults = responseJson => _.get(responseJson, TotalNumberOfResultsJarvisXpath);

const mapJarvisResponseToListOfJobs = (responseJson) => {
  const listOfJobsJarvisResponse = _.mapValues(
    ListOfJobsJarvisXpath,
    path => _.get(responseJson, path),
  );

  const listOfJobs = _.map(
    listOfJobsJarvisResponse.jobs,
    (jobJson) => {
      const jobProperties = _.mapValues(
        JobPropertiesJarvisXpaths,
        path => _.get(jobJson, path),
      );

      return {
        tags: getUserAddedTags(jobProperties.tags),
        startTime: jobProperties.startTime,
        jobID: jobProperties.jobID,
        jobName: getMetaTagValue(jobProperties.tags, JOB_NAME_TAG_PREFIX),
        jobSubmitter: getMetaTagValue(jobProperties.tags, JOB_SUBMITTER_TAG_PREFIX),
        planName: getMetaTagValue(jobProperties.tags, PLAN_NAME_TAG_PREFIX),
      };
    },
  );

  return listOfJobs;
};

export default function loader(state = initialState, action) {
  let mappedResponse;

  switch (action.type) {
    case SEARCH_JOBS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_JOBS_FULFILLED:
      mappedResponse = mapJarvisResponseToListOfJobs(action.payload.data);
      return {
        ...state,
        isLoading: false,
        loaded: mappedResponse,
        total: getTotalNumberOfResults(action.payload.data),
      };
    case LOAD_MORE_JOBS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_MORE_JOBS_FULFILLED:
      mappedResponse = mapJarvisResponseToListOfJobs(action.payload.data);
      return {
        ...state,
        isLoading: false,
        loaded: _.concat(state.loaded, mappedResponse),
      };
    default:
      return state;
  }
}
