import _ from 'lodash';
import { JARVIS_EXPORT_ENDPOINT, JARVIS_X_AMZN_ACCOUNT_NAME } from '../../endpoints';

const btoa = require('btoa');

export const JOB_ID_TAG_PREFIX = 'JobId::';

export const INITIAL_CURSOR = 'initial';

export const JobDetailsJarvisXpaths = {
  jobName: 'response.entities.ValidateJob.0.inputs.job.jobMetadata.name',
  jobID: 'response.entities.ValidateJob.0.inputs.job.jobId.val',
  planName: 'response.entities.ValidateJob.0.inputs.actionRecipe.actionRecipeMetadata.name',
  createdBy: 'response.entities.ValidateJob.0.inputs.job.jobSubmitter.login',
  creationTime: 'response.entities.ValidateJob.0.start_time',
  marketplaceID: 'response.entities.ValidateJob.0.inputs.job.jobParameters.parameters.MarketplaceID',
  blurbName: 'response.entities.ValidateJob.0.inputs.job.jobParameters.parameters.BlurbName',
  tags: 'response.entities.ValidateJob.0.inputs.job.tags',
  totalNumberOfRecords: 'response.entities.BreakJobIntoChunks.0.outputs.chunkList.totalNumberOfRecords',
  description: 'response.entities.ValidateJob.0.inputs.job.jobMetadata.description',
  simttId: 'response.entities.ValidateJob.0.inputs.job.jobMetadata.simttId',
  estimatedTotalConcession: 'response.inputs.job.jobParameters.parameters.EstimatedTotalConcession',
  planId: 'response.inputs.job.actionRecipeId.val',
};

export const WorkflowStatusAggregationBucketsJarvisXpath = 'response.aggregations.status.buckets';

export const ListOfJobsJarvisXpath = {
  jobs: 'response.hits.hits',
};

export const TotalNumberOfResultsJarvisXpath = 'response.hits.total';

export const JobPropertiesJarvisXpaths = {
  tags: '_source.tags',
  startTime: '_source.start_time',
  jobID: '_source.process_id',
};

export const MapJarvisResponseToWorkflowStatusSummary = data => _.reduce(
  _.get(data, WorkflowStatusAggregationBucketsJarvisXpath),
  (counts, { key, doc_count }) => _.set(counts, key, doc_count),
  {},
);

export const getDownloadJobExecutionReportLink = (jobID, startTime) => {
  const requestPayload = `{"query": {"fields": {"start_time": "${startTime}", "tags": "${JOB_ID_TAG_PREFIX}${jobID}", "name": "HULKRecordProcessingWorkflow"}, "return": ["process_id", "status", "tags"]}}`;
  const base64EncodedRequestPayload = btoa(requestPayload);
  const jobExecutionReportLink = `${JARVIS_EXPORT_ENDPOINT}/${JARVIS_X_AMZN_ACCOUNT_NAME}/${base64EncodedRequestPayload}`;

  return jobExecutionReportLink;
};

const WORKFLOW_QUERY_URL = '/reporting/query';
const JOB_APPROVAL_SUMMARY_URL = '/reporting/job-summary/';
const JOB_APPROVAL_LIST_QUERY_URL = '/reporting/job-approval-list-for-user';
const GET_WORKFLOW_QUERY = 'GetWorkflow';
const SEARCH_WORKFLOWS_QUERY = 'SearchWorkflows';
const POST_METHOD = 'POST';

export const getJarvisAPIRequestOptionsForJobDetails = (jobID) => {
  const requestData = `{"query": "${GET_WORKFLOW_QUERY}", "jobId": "${jobID}"}`;

  return {
    url: WORKFLOW_QUERY_URL,
    method: POST_METHOD,
    data: requestData,
  };
};

export const getJarvisAPIRequestOptionsForJobExecutionSummary = (jobID, epName) => {
  const requestData = `{"query": "${SEARCH_WORKFLOWS_QUERY}", "epName": "${epName}", "jobId": "${jobID}", "cursor": "${INITIAL_CURSOR}"}`;

  return {
    url: WORKFLOW_QUERY_URL,
    method: POST_METHOD,
    data: requestData,
  };
};

export const getApprovalAPIRequestForApprovalDetails = (jobID) => {

    return {
        url: JOB_APPROVAL_SUMMARY_URL + jobID,
        method: POST_METHOD,
      };
};

export const getJarvisAPIRequestOptionsForListOfJobs = (cursor, searchQuery) => {
  let requestData = '';

  if (searchQuery === '') {
    requestData = `{"query": "${SEARCH_WORKFLOWS_QUERY}", "epName": "HULKJobCreationEP", "cursor": "${cursor}"}`;
  } else {
    requestData = `{"query": "${SEARCH_WORKFLOWS_QUERY}", "epName": "HULKJobCreationEP", "cursor": "${cursor}", "q": "${searchQuery}"}`;
  }

  return {
    url: WORKFLOW_QUERY_URL,
    method: POST_METHOD,
    data: requestData,
  };
};

export const getApprovalAPIRequestOptionsForListOfJobsSubmittedForApproval = () => {
  return {
    url: JOB_APPROVAL_LIST_QUERY_URL,
    method: POST_METHOD,
  };
};

export const getJarvisAPIRequestOptionsForRecordProcessingWorkflowsStatus = () => {
  const requestData = `{"query": "${SEARCH_WORKFLOWS_QUERY}", "epName": "HULKRecordProcessingWorkflow", "cursor": "${INITIAL_CURSOR}"}`;

  return {
    url: WORKFLOW_QUERY_URL,
    method: POST_METHOD,
    data: requestData,
  };
};

export const getJarvisJobStatus = data => _.get(data, 'response.status');
