import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DropdownInputField from '../../components/DropdownInputField';
import { convertToJavascriptFormat } from '../../helpers/localeHelpers';
import { selectBlurbPreviewLanguage } from '../../redux/newJob/blurb/loaded/actions';
import { blurbLoadingErrorSelector } from '../../redux/newJob/blurb/loader/selectors';
import {
  blurbsWithoutMandatoryParametersSelector,
  availableBlurbLanguagesSelector,
  selectedBlurbPreviewLanguageSelector,
} from '../../redux/newJob/blurb/loaded/selectors';
import { isSelectedPlanEmailDependentSelector } from '../../redux/newJob/plans/selectors';

// const LocaleCode = require('locale-code');
import LocaleCode from 'locale-code';

export class BlurbPreviewLanguagesDropdown extends Component {
  constructor(props) {
    super(props);
    this.createBlurbPreviewLanguagesDropdownOptions
         = this.createBlurbPreviewLanguagesDropdownOptions.bind(this);
  }

  createBlurbPreviewLanguagesDropdownOptions(locale) {
    const formattedLocale = convertToJavascriptFormat(locale, '-');
    const language = LocaleCode.getLanguageName(formattedLocale);
    const country = LocaleCode.getCountryName(formattedLocale);
    let label = _.join([language, country], ' - ');
    if (this.props.isPlanEmailDependent
        && this.props.blurbLoadError
        // Includes the case when a blurb is an invalid xml.
        && _.find(this.props.invalidBlurbs, { language: locale })) {
      label += ' *';
    }
    return {
      value: locale,
      label,
    };
  }

  render() {
    return (
      <DropdownInputField
        {...this.props}
        name="blurbPreviewLanguage"
        label="BlurbPreviewLanguage"
        placeholder="Select a preview language after loading blurb"
        options={this.props.availableBlurbLanguages.map(
          this.createBlurbPreviewLanguagesDropdownOptions)}
        value={this.props.selectedBlurbPreviewLanguage}
        required
        searchable
        clearable={false}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    invalidBlurbs: blurbsWithoutMandatoryParametersSelector(state),
    availableBlurbLanguages: availableBlurbLanguagesSelector(state),
    selectedBlurbPreviewLanguage: selectedBlurbPreviewLanguageSelector(state),
    isPlanEmailDependent: isSelectedPlanEmailDependentSelector(state),
    blurbLoadError: blurbLoadingErrorSelector(state),

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onChange: selectBlurbPreviewLanguage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlurbPreviewLanguagesDropdown);
