import { loadStatus } from '../../../../components/NewJob/LoaderInputField';
import { HTML_BLURB_NAME_CHANGED, LOAD_HTML_BLURB_PENDING, LOAD_HTML_BLURB_FULFILLED, LOAD_HTML_BLURB_REJECTED } from './actions';
import { RESET_REQUIREMENTS_FORM } from '../../actions';

export const initialState = {
  value: '',
  error: '',
  status: loadStatus.NOT_LOADED,
};

export default function blurbLoader(state = initialState, action) {
  switch (action.type) {
    case HTML_BLURB_NAME_CHANGED:
      return {
        ...state,
        value: action.value,
        error: '',
        status: loadStatus.NOT_LOADED,
      };
    case LOAD_HTML_BLURB_PENDING:
      return {
        ...state,
        status: loadStatus.LOADING,
      };
    case LOAD_HTML_BLURB_FULFILLED:
      return {
        ...state,
        error: '',
        status: loadStatus.LOADED,
      };
    case LOAD_HTML_BLURB_REJECTED:
      return {
        ...state,
        error: action.error,
        status: loadStatus.NOT_LOADED,
      };
    case RESET_REQUIREMENTS_FORM:
      return initialState;
    default:
      return state;
  }
}
