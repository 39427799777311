import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoaderInputField from '../../components/NewJob/LoaderInputField';
import { getBlurbs, changeBlurbName } from '../../redux/newJob/blurb/loader/actions';
import { defaultFieldsForSelectedMarketplaceSelector } from '../../redux/marketplace/selectors';

export class BlurbLoaderInputField extends Component {
  render() {
    return (
      <LoaderInputField
        {...this.props}
        name="blurbName"
        label="Blurb"
        placeholder="bp_simple_test"
        buttonLabel="Load"
        helpBlock={<a
          href={this.props.defaultFieldsForSelectedMarketplace.searchBlurbLink}
          target="_blank"
          rel="noopener noreferrer"
        >Search Blurb DB</a>}
        required
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.newJob.blurb.loader,
    value: state.newJob.blurb.loader.value,
    defaultFieldsForSelectedMarketplace: defaultFieldsForSelectedMarketplaceSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onSubmit: getBlurbs,
    onChange: changeBlurbName,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlurbLoaderInputField);
