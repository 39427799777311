import _ from 'lodash';
import {
  GET_APPROVALS_PENDING, GET_APPROVALS_FULFILLED,
} from '../actions';

export const initialState = {
  isApprovalListLoading: false,
  loadedApprovals: [],
};

const mapApprovalsResponseToListOfApprovals = (responseJson) => {
  const listOfJobs = _.map(
      responseJson,
      jobProperties => ({
        jobID: jobProperties.jobId,
        approvalID: jobProperties.approvalID,
        jobSubmitter: jobProperties.createdBy,
        startTime: jobProperties.jobSubmissionTime,
        approvers: jobProperties.approvers,
        approvalStatus: jobProperties.approvalStatus,
      }),
  );

  return listOfJobs;
};

export default function approvalLoader(state = initialState, action) {
  let mappedResponse;
  switch (action.type) {
    case GET_APPROVALS_PENDING:
      return {
        ...state,
        isApprovalListLoading: true,
      };
    case GET_APPROVALS_FULFILLED:
      mappedResponse = mapApprovalsResponseToListOfApprovals(action.payload.data);
      return {
        ...state,
        isApprovalListLoading: false,
        loadedApprovals: mappedResponse,
      };
    default:
      return state;
  }
}
