import _ from 'lodash';
import {
  GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_PENDING,
  GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_FULFILLED,
} from '../actions';
import { workflowStatusType } from '../types';
import { WorkflowStatusAggregationBucketsJarvisXpath } from '../jarvisConstants';

export const initialState = {
  isLoading: false,
  inProgressRecordsCount: 0,
};

const mapJarvisResponseToInProgressRecordsCount = (responseJson) => {
  const filteredBuckets = _.filter(
    _.get(responseJson, WorkflowStatusAggregationBucketsJarvisXpath),
    bucket => bucket.key === workflowStatusType.IN_PROGRESS);

  if (_.isEmpty(filteredBuckets)) {
    return 0;
  }
  return filteredBuckets[0].doc_count;
};

export default function records(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_RECORD_PROCESSING_WORKFLOWS_STATUS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        inProgressRecordsCount: mapJarvisResponseToInProgressRecordsCount(action.payload.data),
      };
    default:
      return state;
  }
}
