import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose, defaultProps } from 'recompose';
import { Form, PanelGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PlanRadioField from '../../components/PlanRadioField';
import NextButton from '../../components/NextButton';
import PreviousButton from '../../components/PreviousButton';
import { resetRequirementsForm } from '../../redux/newJob/actions';
import {concession2_0RecipeIDS, planNameForSkipValidation} from '../../helpers/constants';

export class PlanForm extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      previousStep,
      selectedPlanId,
    } = this.props;

    const filteredPlans = _.filter(this.props.plans, { authorized: true });

    return (
      <Form>
        <h2>Plans</h2>
        <PanelGroup>
          {filteredPlans.map((plan) => {
            const shouldSuppressPlan = concession2_0RecipeIDS.some((recipeId) => recipeId === plan.id);
            if (shouldSuppressPlan) return null;

            let planName = plan.name;
            if (planNameForSkipValidation[plan.id]) {
                planName = planNameForSkipValidation[plan.id];
            }
            return (
              <Field
                key={plan.id}
                name="planId"
                label={planName}
                value={plan.id}
                description={plan.description}
                component={PlanRadioField}
                type="radio"
                actions={plan.actions}
                checked={selectedPlanId === plan.id}
                onChange={this.props.resetRequirementsForm}
              />
            );
          })}
        </PanelGroup>
        <div>
          <PreviousButton onClick={previousStep} />
          <NextButton disabled={pristine || submitting} onClick={handleSubmit} />
        </div>
      </Form>
    );
  }
}

PlanForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  previousStep: PropTypes.func.isRequired,
};

PlanForm.defaultProps = {
  plans: [],
};

const formName = 'newJobPlan';
const selector = formValueSelector(formName);

function mapStateToProps(state) {
  return {
    selectedPlanId: selector(state, 'planId'),
    plans: state.newJob.plans,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetRequirementsForm }, dispatch);
}

// Using compose and defaultProps to make react-hot-loader 1.3.0 work with redux-form.
// And adding displayName for unit testing.
const connectedComponent = compose(
  defaultProps({}),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(PlanForm);

connectedComponent.displayName = 'Connect(Form(PlanForm))';

export default connectedComponent;
