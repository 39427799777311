import {
    SUBMIT_APPROVAL_FULFILLED,
    SUBMIT_JOB_FULFILLED,
    SUBMIT_JOB_PENDING,
    SUBMIT_JOB_REJECTED
} from "../../newJob/actions";

export const initialState = {
    isLoadingSubmitApi: false,
    exception: null,
    jobID: null,
};

export default function submitJobDetails(state = initialState, action) {
    switch (action.type) {
        case SUBMIT_JOB_PENDING:
            return {
                ...state,
                isLoadingSubmitApi: true,
            };
        case SUBMIT_JOB_FULFILLED:
            return {
                ...state,
                exception: null,
                isLoadingSubmitApi: false,
                jobID: action.payload.data.jobId.val
            };
        case SUBMIT_JOB_REJECTED:
            return {
                ...state,
                isLoadingSubmitApi: false,
                exception: action.payload.response.data,
            };
        case SUBMIT_APPROVAL_FULFILLED:
            return {
                ...state,
                isLoadingSubmitApi: false,
                exception: null,
                jobID: null,
            }
        default:
            return state;
    }
}